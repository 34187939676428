import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import useBookingLastEvents from 'views/booking/hooks/use_booking_last_events'

import { fetchBookingLastEvents } from 'views/booking/slices/last_events_slice'
import Placeholder from 'components/placeholder'
import BookingLastEventsSkeleton from 'views/booking/components/last_events/skeleton'
import ErrorNotification from 'core/components/notifications/error_notification'
import BookingLastEvent from 'components/booking_last_event'

const BookingLastEvents = () => {
  const { id: token } = useParams()
  const dispatch = useDispatch()
  const [lastEvents, status] = useBookingLastEvents()

  useEffect(() => {
    dispatch(fetchBookingLastEvents(token))
  }, [dispatch, token])

  return (
    <div>
      {status.fulfilled &&
        lastEvents.map((event) => (
          <BookingLastEvent key={`last-event-item-${event.id}`} event={event} />
        ))}
      {!status.ready && (
        <Placeholder ready={status.ready} customPlaceholder={<BookingLastEventsSkeleton />} />
      )}
      {status.rejected && <ErrorNotification />}
    </div>
  )
}

export default BookingLastEvents
