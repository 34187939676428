import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Widgets from 'features/custom_kpis/components/widgets'
import Widget from 'features/custom_kpis/components/widget'
import WidgetShipment from 'features/custom_kpis/components/widget_shipment'
import WidgetCarbonFootprint from 'features/custom_kpis/components/widget_carbonfootprint'
import WidgetListSkeleton from 'features/custom_kpis/components/custom_kpi_list/skeleton'
import Placeholder from 'components/placeholder'

import {
  selectCustomKpis,
  selectCustomKpisStatus,
  setSelectedCustomKpiId,
} from 'features/custom_kpis/store/slice'
import useKpi from 'features/custom_kpis/components/custom_kpi_list/hooks/use_kpi'
import { addNotification } from 'views/notifications/slice'
import ErrorNotification from 'core/components/notifications/error_notification'

import useOnce from 'services/hooks/use_once'
import { toCamelCase } from 'services/helpers/values'
import useModal from 'components/modal/hooks/use_modal'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useStatus from 'services/api/hooks/use_status'
import { deleteCustomKpi, fetchCustomKpis } from 'features/custom_kpis/services/custom_kpi_service'
import { CustomKpiType } from 'features/custom_kpis/types/types'

const CustomKpis = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setOpen } = useModal('widget')
  const customKpis = useSelector(selectCustomKpis)
  const status = useStatus(useSelector(selectCustomKpisStatus))
  const { translate, goToShipments } = useKpi()

  useOnce(() =>
    dispatch(fetchCustomKpis())
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  )

  const onDelete = ({ id, type }: { id: number; type: CustomKpiType }) =>
    dispatch(deleteCustomKpi({ id }))
      .unwrap()
      .then(() =>
        dispatch(
          addNotification({
            type: 'success',
            title: t('customKpis.actions.deleteWidget'),
            text: t('customKpis.actions.widgetDeleted', {
              type: t(`customKpis.form.kpiType.${toCamelCase(type)}`),
            }),
          })
        )
      )
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

  const onEdit = ({ id }: { id: number }) => {
    dispatch(setSelectedCustomKpiId({ id }))
    setOpen(true)
  }

  return (
    <Widgets>
      {status.fulfilled &&
        customKpis.length > 0 &&
        customKpis.map((kpi) => {
          const { id, kpiType, total, icon, transport, date, place, isShipment, arrivalForecast } =
            translate(kpi)
          return (
            <Widget
              key={id}
              onEdit={() => onEdit({ id })}
              onDelete={() => onDelete({ id, type: kpiType })}
              onClick={() => {
                if (isShipment) {
                  goToShipments({ kpi })
                }
              }}
            >
              {isShipment ? (
                <WidgetShipment
                  total={total}
                  icon={icon}
                  transport={transport}
                  date={date}
                  place={place}
                  arrivalForecast={arrivalForecast}
                />
              ) : (
                <WidgetCarbonFootprint total={total} date={date} />
              )}
            </Widget>
          )
        })}
      {!status.ready && (
        <Placeholder ready={status.ready} customPlaceholder={<WidgetListSkeleton />} />
      )}
      {status.rejected && <ErrorNotification />}
    </Widgets>
  )
}

export default CustomKpis
