import React, { useState, FC, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { FieldErrors, FormProvider, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/button'
import Page from 'components/page'
import useModal from 'components/modal/hooks/use_modal'

import {
  StyledBookingCreate,
  StyledPageBookingCreateHeader,
  StyledPageBookingCreateButtonBack,
} from 'views/booking/components/create/style'
import BookingForm from 'views/booking/components/form'
import ValidateCreateModal from 'views/booking/components/create/validate_create_modal'
import SaveAsTemplateModal from 'views/booking/components/templates/save_as_template_modal'
import { StyledFooterButton } from 'views/booking/components/show/style'
import BookingNewContext from 'views/booking/contexts/booking_new_context'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { BookingFromTrustedRoutes, CreateBookingData } from 'views/booking/slices/types'

import {
  StyledFormFooter,
  StyledPageBookingDetailBoxShadow,
  StyledPageBookingDetailButtonBack,
  StyledPageBookingDetailInner,
} from 'views/booking/style'

import S from 'components/page/style'

import {
  TEST_ID_BOOKING_SEND_REQUEST_BUTTON,
  TEST_ID_BOOKING_TEMPLATE_SAVE,
} from 'tests/e2e/test_ids'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'
import {
  BookingFormContext,
  BookingFormData,
  BookingFormInput,
  bookingFormSchema,
} from 'views/booking/components/form/types'
import useDisabledField from 'views/booking/components/form/hooks/use_disabled_field'
import formatBookingToFormInput from 'views/booking/components/form/helpers/booking_to_form'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import { isPresent } from 'services/helpers/values'
import { generateCreatePayload } from 'views/booking/components/form/helpers/form_to_booking_payload'
import BookingTemplates from 'views/booking/components/templates/templates'
import useCurrentCompany from 'views/iam/hooks/use_current_company'

const BookingCreate: FC = () => {
  const location = useLocation()
  const state = location.state as BookingFromTrustedRoutes | undefined

  const [uploadedFiles, setUploadedFiles] = useState([])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { s } = useStaticLocales()
  const { organization, profile } = useCurrentUser()
  const { features } = useOrganizationCan()
  const contextValue = useMemo(() => ({ uploadedFiles, setUploadedFiles }), [uploadedFiles])
  const dispatch = useAppDispatch()
  const { setOpen } = useModal('bookingValidateCreate')
  const { setOpen: setOpenSaveAsTemplate, opened: saveAsTemplateModalOpened } =
    useModal('bookingSaveAsTemplate')
  const withBookingOldWorkflow = features(WITH_BOOKING_OLD_WORKFLOW)
  const [createBookingData, setCreateBookingData] = useState<CreateBookingData | undefined>()
  const { company: currentCompany, ownsCapacity } = useCurrentCompany()

  const context: BookingFormContext = {
    defaultForwarder:
      isPresent(organization.forwarderId) && isPresent(organization.forwarderName)
        ? {
            value: organization.forwarderId,
            label: organization.forwarderName,
          }
        : undefined,
    defaultShipper: ownsCapacity('shipper')
      ? {
          label: currentCompany.name,
          value: currentCompany.id,
        }
      : undefined,
    isForwarder: false,
    defaultKeyContacts: [profile.email],
    isStatusPastProposalExchange: false,
    withBookingOldWorkflow,
    containerTypes: Object.entries(s('containerTypes')).map(([key, value]) => ({
      value: key,
      label: value,
    })),
    packageTypes: Object.entries(s('packageTypes')).map(([key, value]) => ({
      value: key,
      label: value,
    })),
    hazardousClasses: Object.entries(s('hazardousGoods')).map(([key, value]) => ({
      value: key,
      label: value,
    })),
    packingGroups: Object.entries(s('packingGroups')).map(([key, value]) => ({
      value: key,
      label: value,
    })),
  }

  const formInput = formatBookingToFormInput(context, state?.booking)

  const { isFieldDisabled } = useDisabledField({
    booking: state?.booking,
    isEdit: false,
    routingWithoutOnCarriage: !state?.booking?.transportPlan?.withPreCarriage,
    routingWithoutPreCarriage: !state?.booking?.transportPlan?.withOnCarriage,
    rateConfirmation: state?.booking?.rateConfirmation || false,
    defaultForwarderId: context.defaultForwarder?.value as number,
  })

  const methods = useForm<BookingFormInput, undefined, BookingFormData>({
    defaultValues: formInput,
    resolver: yupResolver(bookingFormSchema),
  })

  const { handleSubmit, formState } = methods

  const onSubmit = (data: BookingFormData) => {
    const payload = generateCreatePayload(data)
    setCreateBookingData(payload)
    setOpen(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onError = (_formErrors: FieldErrors<BookingFormInput>) => {
    dispatch(
      addNotification({
        type: 'info',
        title: t('errors.notification.title'),
        text: t('errors.validation.invalidFormData'),
      })
    )
  }

  return (
    <FormProvider {...methods}>
      <Page sidebar={<BookingTemplates />}>
        {createBookingData && (
          <ValidateCreateModal
            createBookingData={createBookingData}
            uploadedFiles={uploadedFiles}
            onClose={() => setCreateBookingData(undefined)}
          />
        )}
        {saveAsTemplateModalOpened && (
          <SaveAsTemplateModal onClose={() => setOpenSaveAsTemplate(false)} />
        )}

        <BookingNewContext.Provider value={contextValue}>
          <StyledBookingCreate>
            <StyledPageBookingCreateHeader>
              <StyledPageBookingCreateButtonBack
                as={StyledPageBookingDetailButtonBack}
                text={t('actions.backToList')}
                variant='transparent'
                icon='arrow_left'
                onClick={() => navigate('/bookings')}
              />
              <S.PageTitle>{t('bookings.newBooking')}</S.PageTitle>
            </StyledPageBookingCreateHeader>

            <StyledPageBookingDetailBoxShadow>
              <StyledPageBookingDetailInner>
                <BookingForm
                  isCreate
                  routingWarning={state?.routingWarning}
                  isFieldDisabled={isFieldDisabled}
                />
              </StyledPageBookingDetailInner>
              <StyledFormFooter>
                {formState.isDirty && (
                  <StyledFooterButton
                    as={Button}
                    text={t('bookings.templates.save')}
                    onClick={() => setOpenSaveAsTemplate(true)}
                    rounded
                    icon='save_outline'
                    variant='outline'
                    testId={TEST_ID_BOOKING_TEMPLATE_SAVE}
                  />
                )}
                <StyledFooterButton
                  as={Button}
                  text={t('bookings.actions.sendRequest')}
                  onClick={handleSubmit(
                    (data) => onSubmit(data),
                    (formErrors) => onError(formErrors)
                  )}
                  rounded
                  icon='send'
                  variant='highlight'
                  testId={TEST_ID_BOOKING_SEND_REQUEST_BUTTON}
                />
              </StyledFormFooter>
            </StyledPageBookingDetailBoxShadow>
          </StyledBookingCreate>
        </BookingNewContext.Provider>
      </Page>
    </FormProvider>
  )
}

export default BookingCreate
