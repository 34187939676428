import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import { selectTimeline, selectTimelineStatus } from 'features/shipments/store/shipment_slice'

import type { UseStatus } from 'services/api/hooks/use_status'
import type { NullTimeline, Timeline } from 'features/shipments/types/legacy_shipment'
import type { RootState } from 'services/store/store'

const useTimeline = ({ id }: { id: string }): [Timeline | NullTimeline, UseStatus] => {
  const timeline = useSelector(selectTimeline({ id }))
  const status = useStatus(useSelector((state: RootState) => selectTimelineStatus(state, id)))
  return [timeline, status]
}

export default useTimeline
