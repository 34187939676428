import React, { FC, useContext } from 'react'

import Icon from 'components/icon'

import iconsMapping from 'services/helpers/icons_mapping'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_context'
import { StyledShipmentListItemIcon } from 'features/shipments/components/shipment_list_item/style'

const TransportMode: FC = () => {
  const { shipment } = useContext(ShipmentTokenContext)
  const transportMode = shipment.summary.mainTransportMode
  return transportMode ? (
    <StyledShipmentListItemIcon as={Icon} name={iconsMapping(transportMode, 'transportation')} />
  ) : null
}

export default TransportMode
