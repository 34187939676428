import { useContext } from 'react'

import useCurrentUser from 'views/iam/hooks/use_current_user'

import { ETA_DIFF_POD, STEP_TYPE_DELIVERY, STEP_TYPE_POD } from 'constants/shipments'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_context'

const useArrivalTimes = () => {
  const { shipment } = useContext(ShipmentTokenContext)
  const { summary } = shipment
  const user = useCurrentUser()
  const etaDiff = user.profile.settings.etaDifference
  const etaDiffLeg = etaDiff === ETA_DIFF_POD ? summary[STEP_TYPE_POD] : summary[STEP_TYPE_DELIVERY]
  const plannedTime = etaDiffLeg?.plannedTime
  const estimatedTime = etaDiffLeg?.estimatedTime
  const actualTime = etaDiffLeg?.actualTime

  return {
    plannedTime,
    estimatedTime,
    actualTime,
  }
}

export default useArrivalTimes
