import {
  BOOKING_BOOKING_CONFIRMED,
  BOOKING_TRANSPORT_ORDER_NOT_SENT,
  BOOKING_TRANSPORT_ORDER_SENT,
  BookingConfirmationStatuses,
} from 'constants/booking_confirmation'
import { STEP_STATUS_CURRENT, STEP_STATUS_FUTURE, STEP_STATUS_PAST } from 'constants/shipments'

import useShipment from 'features/shipments/hooks/use_shipment'

const BOOKING_CONFIRMATION_STATUS_TO_STEP_STATUS: Record<
  BookingConfirmationStatuses,
  typeof STEP_STATUS_FUTURE | typeof STEP_STATUS_CURRENT | typeof STEP_STATUS_PAST
> = {
  [BOOKING_TRANSPORT_ORDER_NOT_SENT]: STEP_STATUS_FUTURE,
  [BOOKING_TRANSPORT_ORDER_SENT]: STEP_STATUS_CURRENT,
  [BOOKING_BOOKING_CONFIRMED]: STEP_STATUS_PAST,
}

export type UseBookingConfirmationResponse = [
  {
    tet?: string | null
    tat?: string | null
    bet?: string | null
    bat?: string | null
    timelineStatus?:
      | typeof STEP_STATUS_FUTURE
      | typeof STEP_STATUS_CURRENT
      | typeof STEP_STATUS_PAST
    isTransportOrderSent?: boolean
    isBookingConfirmed?: boolean
  },
  boolean
]

const useBookingConfirmation = ({ id }: { id: string }): UseBookingConfirmationResponse => {
  const [shipment, shipmentStatus] = useShipment({ id })

  // TODO: REFACTOR: Back-end should not have different bookingConfirmation response format for get /shipment and get /booking_confirmation
  if (!shipment?.bookingConfirmation?.status) return [{}, false]

  const { tet, tat, bet, bat, status } = shipment.bookingConfirmation
  const timelineStatus = BOOKING_CONFIRMATION_STATUS_TO_STEP_STATUS[status]
  const isTransportOrderSent = [BOOKING_TRANSPORT_ORDER_SENT, BOOKING_BOOKING_CONFIRMED].includes(
    status
  )
  const isBookingConfirmed = status === BOOKING_BOOKING_CONFIRMED
  return [
    { tet, tat, bet, bat, timelineStatus, isTransportOrderSent, isBookingConfirmed },
    shipmentStatus.ready,
  ]
}

export default useBookingConfirmation
