import React, { ReactNode, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components'

import { DefaultTheme, computeTheme, defaultTheme } from 'styles/theme'
import { UserTheme } from 'views/iam/types'
import { isEmptyObject } from 'services/helpers/values'

import { getImagesUrl, getTheme } from 'features/white_label/store/slice'
import {
  applyDefaultResources,
  applyWakeoResources,
  applyWhiteLabelResources,
} from 'features/white_label/helpers/white_label_resources'
import { selectCurrentUser } from 'views/iam/slices/iamSlice'

interface ThemeProviderProps {
  children: ReactNode
}

const translateUserTheme = (userTheme: UserTheme): Omit<DefaultTheme, 'tip' | 'success'> => ({
  primary: userTheme.primary,
  secondary: userTheme.secondary,
  warn: userTheme.warn,
  error: userTheme.alert,
})

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const imagesUrls = useSelector(getImagesUrl)
  const { organization } = useSelector(selectCurrentUser)
  const { features } = organization

  const organizationHasLoaded = useMemo(() => !!organization.name, [organization])
  const loadedTheme = useSelector(getTheme) || organization?.theme

  const theme = useMemo(() => {
    let t = defaultTheme
    if (loadedTheme && !isEmptyObject(loadedTheme)) {
      t = { ...defaultTheme, ...translateUserTheme(loadedTheme) }
    }

    return t
  }, [loadedTheme])

  useEffect(() => {
    if (!organizationHasLoaded) {
      applyDefaultResources()
    } else if (features?.withWhiteLabel && imagesUrls) {
      applyWhiteLabelResources({ imagesUrl: imagesUrls })
    } else {
      applyWakeoResources()
    }
    // there is many unstable references here and we do not need to re-run this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationHasLoaded])

  return (
    <StyledComponentThemeProvider
      theme={computeTheme({
        theme,
      })}
    >
      {children}
    </StyledComponentThemeProvider>
  )
}

export default React.memo(ThemeProvider)
