import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import { selectNetworkCompaniesStatus } from 'views/network/companies/slice'

import { NetworkListItemProps } from 'components/network_list_item'
import StyledNetworkList from 'components/network_list/style'
import NetworkListSkeleton from 'components/network_list/skeleton'
import Placeholder from 'components/placeholder'
import ErrorNotification from 'core/components/notifications/error_notification'

type NetworkListItemType =
  | ReactElement<PropsWithChildren<NetworkListItemProps>>
  | undefined
  | boolean
  | null

interface NetworkListProps {
  children: NetworkListItemType | NetworkListItemType[]
  testId?: string
}

const NetworkList: FC<NetworkListProps> = ({ children, testId }) => {
  const status = useStatus(useSelector(selectNetworkCompaniesStatus))
  return (
    <>
      <StyledNetworkList data-testid={testId}>{children}</StyledNetworkList>
      <Placeholder ready={status.ready} customPlaceholder={<NetworkListSkeleton />}>
        {status.rejected && <ErrorNotification />}
      </Placeholder>
    </>
  )
}

export default NetworkList
