import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import QuickInfo from 'components/quick_info'
import QuickInfoItem from 'components/quick_info/quick_info_item'
import QuickInfoSkeleton from 'components/quick_info/skeleton'
import Placeholder from 'components/placeholder'

import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import useTimeline from 'features/shipments/hooks/use_timeline'
import useCurrentUser from 'views/iam/hooks/use_current_user'

import useMilestoneTimes from 'services/hooks/use_milestone_times'
import { isNull } from 'services/helpers/values'

import { ETA_DIFF_POD, TRANSPORT_TYPE_PARCEL } from 'constants/shipments'
import useShipment from 'features/shipments/hooks/use_shipment'
import StyledQuickInfoLabel from 'features/shipments/components/shipment_view/components/quick_info/style'

const ShipmentQuickInfo: FC = () => {
  const { id } = useContext(ShipmentTokenContext)
  const [timeline, status] = useTimeline({ id: id! })
  const [shipment] = useShipment({ id: id! })
  const user = useCurrentUser()
  const etaDiff = user.profile.settings.etaDifference
  const {
    summary: { pod, delivery },
  } = timeline
  const { t } = useTranslation()
  const isDeliveryMissing = isNull(delivery)
  const isPod = etaDiff === ETA_DIFF_POD || isDeliveryMissing
  const step = isPod ? pod : delivery

  const { time, diffTime, type } = useMilestoneTimes({
    planned: step?.plannedTime,
    estimated: step?.estimatedTime,
    actual: step?.actualTime,
  })

  // AGILE Hack: For first version of parcel shipments, the delivery is the arrival step
  const parcelLabel =
    shipment?.mainTransportMode === TRANSPORT_TYPE_PARCEL ? t('shipments.routing.delivery') : null

  const globalLabel = isPod ? t('shipments.routing.pod') : t('shipments.routing.delivery')

  const label = parcelLabel || globalLabel

  return (
    <Placeholder ready={status.ready} customPlaceholder={<QuickInfoSkeleton />}>
      <QuickInfo>
        <StyledQuickInfoLabel>{label}</StyledQuickInfoLabel>
        {step && (
          <QuickInfoItem location={step.location} type={type} time={time} diffTime={diffTime} />
        )}
      </QuickInfo>
    </Placeholder>
  )
}

export default ShipmentQuickInfo
