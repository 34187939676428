import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { ClipLoader } from 'react-spinners'
import { useTheme } from 'styled-components'

import { getSsoState } from 'views/iam/slices/ssoSlice'
import { SSOSignInContainer } from 'views/iam/style'

const { REACT_APP_APPLICATION_ENDPOINT } = process.env

const LOGIN_URL_QUERY_PARAM = 'loginUrl'

const formatUrl = (
  logoutUrl: string,
  loginUrl: string,
  clientId: string,
  logoutHint?: string,
  customDomain?: string,
  bypassLogoutPage?: boolean
) => {
  const url = new URL(logoutUrl)
  const params = new URLSearchParams(url.search)
  params.set(
    'post_logout_redirect_uri',
    bypassLogoutPage
      ? `${loginUrl}?prompt=login`
      : `${
          customDomain || REACT_APP_APPLICATION_ENDPOINT
        }/sso/logout?${LOGIN_URL_QUERY_PARAM}=${loginUrl}`
  )
  if (logoutHint) {
    params.set('logout_hint', logoutHint)
  }

  params.set('client_id', clientId)
  url.search = params.toString()

  return url.toString()
}

const SSORedirect: React.FC = () => {
  const {
    logoutRedirectUrl,
    loginUrl,
    isSSOUser,
    logoutHint,
    clientId,
    customDomain,
    logoutInitiatedByUser,
    bypassLogoutPage,
  } = useSelector(getSsoState)

  const theme = useTheme()

  useEffect(() => {
    // If the user is disconnected by a back channel request from client idp,
    // we do not want to trigger the oidc logout flow as he is already logged out from idp
    if (isSSOUser && !logoutInitiatedByUser) {
      window.location.replace(loginUrl!)
    } else if (logoutRedirectUrl && isSSOUser) {
      window.location.replace(
        formatUrl(
          logoutRedirectUrl,
          loginUrl!,
          clientId!,
          logoutHint!,
          customDomain,
          bypassLogoutPage
        )
      )
    }
  }, [
    logoutRedirectUrl,
    isSSOUser,
    loginUrl,
    logoutHint,
    clientId,
    customDomain,
    bypassLogoutPage,
    logoutInitiatedByUser,
  ])

  return (
    <SSOSignInContainer>
      <ClipLoader color={theme.primary} />
    </SSOSignInContainer>
  )
}

export default SSORedirect
