import React, { useContext } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ORDER_USERS_READ, ORDER_ADMIN, AUDIT_TRAIL_READ } from 'constants/permissions'

import useTracker from 'services/analytics/hooks/use_tracker'
import DateHelper from 'services/helpers/date_helper'

import useUserCan from 'views/iam/hooks/use_user_can'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import useShipment from 'features/shipments/hooks/use_shipment'
import { selectUsers } from 'features/shipments/store/shipment_slice'
import ShipmentUsersList from 'features/shipments/components/shipment_users_list'
import ShipmentEmissions from 'features/shipments/components/shipment_emissions'
import ErrorNotification from 'core/components/notifications/error_notification'

import Button from 'components/button'
import ModalShare from 'components/modal_share'
import Favorite from 'components/favorite'
import ShipmentActionMenu from 'features/shipments/components/shipment_action_menu'

import { TEST_ID_SHIPMENT_SHOW_REFERNECE, TEST_ID_AUDIT_TRAIL } from 'tests/e2e/test_ids'
import { routeShipmentAuditTrail } from 'services/helpers/routes'

import ModalDeleteShipment from 'components/modal_delete_shipment'
import useUserIs from 'views/iam/hooks/use_user_is'
import { ADMIN, OUTSOURCING, SUPER_ADMIN } from 'constants/roles'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import useBreakpoints from 'services/hooks/use_breakpoints'
import { WITH_SHAREABLE_LINK } from 'constants/organization_features'
import useModal from 'components/modal/hooks/use_modal'
import ModalReportShipment from 'components/modal_report_shipment'
import ModalPath from 'components/modal_path'
import { isAnyArray } from 'services/helpers/values'
import useToggleFavorite from 'features/shipments/components/shipment_list_item/hooks/use_toggle_favorite'
import {
  StyledShipmentContentHead,
  StyledShipmentContentHeadTitle,
} from 'features/shipments/components/style'
import {
  StyledShipmentContentCreationDate,
  StyledShipmentContentHeadLeft,
  StyledShipmentContentHeadLeftWrap,
  StyledShipmentContentHeadRight,
  StyledShipmentFavorite,
} from 'features/shipments/components/shipment_header/style'

const ShipmentHeader = React.memo(() => {
  const userCan = useUserCan()
  const userIs = useUserIs()
  const { t } = useTranslation()
  const { id } = useContext(ShipmentTokenContext)
  const { isLowerThanDesktop } = useBreakpoints()
  const organizationCan = useOrganizationCan()
  const users = useSelector(selectUsers({ id }))
  const [shipment, status] = useShipment({ id })
  const { favorite: isActive } = shipment || {}
  const { track } = useTracker()
  const { setOpen: setShareModaleOpen } = useModal('share')
  const toggleFavorite = useToggleFavorite({ id, isActive: !!isActive })
  const { opened: shareModalOpened } = useModal('share')

  const title =
    status.fulfilled && shipment
      ? shipment.reference || t('shipments.shipmentTitle')
      : `${t('states.loading')}...`

  if (status.rejected) {
    return <ErrorNotification />
  }

  return (
    <StyledShipmentContentHead>
      {shareModalOpened && <ModalShare />}
      <ModalDeleteShipment />
      <ModalReportShipment />
      <ModalPath />
      <StyledShipmentContentHeadLeft>
        <StyledShipmentContentHeadLeftWrap>
          <StyledShipmentFavorite>
            <Favorite active={isActive} onClick={toggleFavorite} big />
          </StyledShipmentFavorite>
          <StyledShipmentContentHeadTitle data-testid={TEST_ID_SHIPMENT_SHOW_REFERNECE}>
            {title}
          </StyledShipmentContentHeadTitle>
        </StyledShipmentContentHeadLeftWrap>
        <ShipmentEmissions />
      </StyledShipmentContentHeadLeft>
      <StyledShipmentContentHeadRight>
        {shipment?.createdAt && userCan(ORDER_ADMIN) && (
          <StyledShipmentContentCreationDate>{`${new DateHelper(shipment.createdAt).toLocale({
            hours: true,
          })}`}</StyledShipmentContentCreationDate>
        )}
        {isAnyArray(users) && userCan(ORDER_USERS_READ) && <ShipmentUsersList users={users} />}

        {userIs(ADMIN) || userIs(SUPER_ADMIN) || userIs(OUTSOURCING) ? (
          <ShipmentActionMenu />
        ) : (
          organizationCan.features(WITH_SHAREABLE_LINK) && (
            <Button
              icon='share_outline'
              rounded={isLowerThanDesktop}
              text={isLowerThanDesktop ? null : t('actions.share')}
              onClick={() => {
                track('Shipment / Share', { action: 'pre-share' })
                setShareModaleOpen(true)
              }}
            />
          )
        )}
        {userCan(AUDIT_TRAIL_READ) && (
          <Button
            testId={TEST_ID_AUDIT_TRAIL}
            icon='time_clock'
            rounded
            isLink
            to={routeShipmentAuditTrail(id)}
          />
        )}
      </StyledShipmentContentHeadRight>
    </StyledShipmentContentHead>
  )
})

export default ShipmentHeader
