import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import DateHelper from 'services/helpers/date_helper'

import iconsMapping from 'services/helpers/icons_mapping'
import Icon from 'components/icon'

import {
  // Main
  StyledBookingListItem,
  StyledBookingListItemInfoWrapper,
  StyledBookingListItemContentWrapper,
  StyledBookingListItemDetailsWrapper,
  // Info
  StyledBookingListItemInfo,
  StyledBookingListItemInfoReferences,
  StyledBookingListItemReference,
  StyledBookingListItemClientBookingNumber,
  StyledBookingListItemForwarderLogo,
  // Content
  StyledBookingListItemContent,
  StyledBookingListItemContentTravel,
  StyledBookingListItemTransportTypeLogo,
  StyledBookingListItemDepartureAdress,
  StyledBookingListItemArrow,
  StyledBookingListItemDate,
  StyledBookingListItemContentDetails,
  StyledBookingListItemCalendarLogo,
  // StyledBookingListItemSeparator,
  // StyledBookingListItemMerchandiseIcon,
  // StyledBookingListItemMerchandiseValue,
  // Details
  StyledBookingListItemState,
  StyledBookingListItemStateIcon,
  StyledBookingListItemDetails,
  StyledBookingListItemStatusIcon,
  StyledBookingListItemStatusText,
  StyledBookingListItemStatus,
  StyledBookingListItemDetailsRight,
  StyledImage,
  StyledBookingListItemStatusButton,
} from 'components/booking_list_item/style'
import useUserIs from 'views/iam/hooks/use_user_is'
import { SHIPPER } from 'constants/roles'
import { isPresent, toCamelCase } from 'services/helpers/values'
import Button from 'components/button'
import useBookingGoToShipments from 'views/booking/hooks/use_booking_go_to_shipments'
import useBookingStatus from 'views/booking/hooks/use_booking_status'
import { routeShipment } from 'services/helpers/routes'
import {
  TEST_ID_BOOKING_LIST_ITEM_DESTINATION_ADDRESS,
  TEST_ID_BOOKING_LIST_ITEM_CLIENT_BOOKING_NUMBER,
  TEST_ID_BOOKING_LIST_ITEM_CLIENT_REFERENCE,
  TEST_ID_BOOKING_LIST_ITEM_DATE,
  TEST_ID_BOOKING_LIST_ITEM_ORIGIN_ADDRESS,
  TEST_ID_BOOKING_LIST_ITEM_LAST_UPDATED,
  TEST_ID_BOOKING_LIST_ITEM_STATUS_ICON,
  TEST_ID_BOOKING_LIST_ITEM_STATUS_TEXT,
  TEST_ID_BOOKING_LIST_ITEM_TRANSPORTATION_ICON,
} from 'tests/e2e/test_ids'

const FORWARDER_LOGO_PLACEHOLDER = 'earth_locate'

const BookingListItem = ({
  className,
  token,
  clientReference,
  clientBookingNumber,
  forwarderLogo,
  transportType,
  originName,
  originCountryCode,
  destinationName,
  destinationCountryCode,
  ptd,
  pta,
  merchandiseHasAnyHazardousGoods,
  merchandiseHasAnyControlledTemperatures,
  status,
  lastUpdated,
  hasNewDocuments,
  hasActions,
  orderTokens,
  testId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userIs = useUserIs()

  const {
    isStatusCompleted,
    isStatusCancelled,
    isStatusAReviewStatus,
    isStatusASuccessStatus,
    isStatusDeclined,
  } = useBookingStatus(status)
  const Info = () => (
    <StyledBookingListItemInfo>
      {userIs(SHIPPER) && !forwarderLogo && (
        <StyledBookingListItemForwarderLogo as={Icon} name={FORWARDER_LOGO_PLACEHOLDER} />
      )}

      {userIs(SHIPPER) && forwarderLogo && <StyledImage src={forwarderLogo} alt='forwarder logo' />}

      <StyledBookingListItemInfoReferences>
        <StyledBookingListItemReference data-testid={TEST_ID_BOOKING_LIST_ITEM_CLIENT_REFERENCE}>
          {clientReference}
        </StyledBookingListItemReference>

        <StyledBookingListItemClientBookingNumber
          data-testid={TEST_ID_BOOKING_LIST_ITEM_CLIENT_BOOKING_NUMBER}
        >
          {clientBookingNumber}
        </StyledBookingListItemClientBookingNumber>
      </StyledBookingListItemInfoReferences>
    </StyledBookingListItemInfo>
  )

  // const merchandiseIcon = {
  //   total: 'weight',
  //   container: 'container',
  //   package: 'shipment_box_plain',
  // }[merchandiseType]

  // const sumMerchandiseContentQuantity = () =>
  //   merchandiseContent.reduce((acc, curr) => acc + curr.quantity, 0)

  // const merchandiseValue = {
  //   total: () => `${merchandiseContent.weight} Kg`,
  //   container: sumMerchandiseContentQuantity,
  //   package: sumMerchandiseContentQuantity,
  // }[merchandiseType]()

  const Content = () => (
    <StyledBookingListItemContent>
      <StyledBookingListItemContentTravel>
        <StyledBookingListItemTransportTypeLogo
          testId={TEST_ID_BOOKING_LIST_ITEM_TRANSPORTATION_ICON}
          as={Icon}
          name={iconsMapping(transportType, 'transportation')}
        />

        <StyledBookingListItemDepartureAdress
          data-testid={TEST_ID_BOOKING_LIST_ITEM_ORIGIN_ADDRESS}
        >
          {originName}, {originCountryCode}
        </StyledBookingListItemDepartureAdress>

        <StyledBookingListItemArrow as={Icon} name='arrow_right_outline' />

        <StyledBookingListItemDepartureAdress
          data-testid={TEST_ID_BOOKING_LIST_ITEM_DESTINATION_ADDRESS}
        >
          {destinationName}, {destinationCountryCode}
        </StyledBookingListItemDepartureAdress>
      </StyledBookingListItemContentTravel>

      <StyledBookingListItemContentDetails>
        <StyledBookingListItemCalendarLogo as={Icon} name='calendar' />

        <StyledBookingListItemDate data-testid={TEST_ID_BOOKING_LIST_ITEM_DATE}>
          {isPresent(ptd) ? new DateHelper(ptd).toLocale() : t('bookings.noDate')} to{' '}
          {isPresent(pta) ? new DateHelper(pta).toLocale() : t('bookings.noDate')}
        </StyledBookingListItemDate>

        {/* <StyledBookingListItemSeparator /> */}

        {/* <StyledBookingListItemMerchandiseIcon as={Icon} name={merchandiseIcon} /> */}

        {/* <StyledBookingListItemMerchandiseValue>
          {merchandiseValue}
        </StyledBookingListItemMerchandiseValue> */}
      </StyledBookingListItemContentDetails>
    </StyledBookingListItemContent>
  )

  const statusIconName = useMemo(() => {
    if (isStatusCompleted) {
      return 'arrow_right_circle'
    }

    if (isStatusAReviewStatus) {
      return 'time_clock'
    }

    if (isStatusASuccessStatus) {
      return 'check_outline'
    }
    return ''
  }, [isStatusCompleted, isStatusAReviewStatus, isStatusASuccessStatus])

  const { goToShipments } = useBookingGoToShipments()

  const onClickViewShipment = (e) => {
    e.preventDefault()
    if (orderTokens.length > 1) {
      goToShipments(token, clientReference)
      return
    }
    navigate(routeShipment(orderTokens[0]))
  }

  const Details = () => (
    <StyledBookingListItemDetails>
      <StyledBookingListItemState>
        {hasNewDocuments && <StyledBookingListItemStateIcon as={Icon} name='file' />}
        {hasActions && <StyledBookingListItemStateIcon as={Icon} name='info' />}
      </StyledBookingListItemState>

      <StyledBookingListItemDetailsRight>
        <StyledBookingListItemStatus
          $isStatusCompleted={isStatusCompleted}
          $isStatusAReviewStatus={isStatusAReviewStatus}
          $isStatusASuccessStatus={isStatusASuccessStatus}
          $isStatusCancelled={isStatusCancelled}
          $isStatusDeclined={isStatusDeclined}
        >
          <StyledBookingListItemStatusIcon
            testId={TEST_ID_BOOKING_LIST_ITEM_STATUS_ICON}
            as={Icon}
            name={statusIconName}
          />

          <StyledBookingListItemStatusText data-testid={TEST_ID_BOOKING_LIST_ITEM_STATUS_TEXT}>
            {t(`bookings.status.${toCamelCase(status)}`)}
          </StyledBookingListItemStatusText>

          {isStatusCompleted && (
            <StyledBookingListItemStatusButton
              as={Button}
              text='View'
              variant='outline'
              onClick={onClickViewShipment}
            />
          )}
        </StyledBookingListItemStatus>

        <StyledBookingListItemDate data-testid={TEST_ID_BOOKING_LIST_ITEM_LAST_UPDATED}>
          {new DateHelper(lastUpdated).toLocale()}
        </StyledBookingListItemDate>
      </StyledBookingListItemDetailsRight>
    </StyledBookingListItemDetails>
  )

  return (
    <StyledBookingListItem
      as={Link}
      to={`/booking/${token}`}
      className={className}
      data-testid={testId}
      data-test-has-hazardous-goods={merchandiseHasAnyHazardousGoods}
      data-test-has-controlled-temperatures={merchandiseHasAnyControlledTemperatures}
    >
      <StyledBookingListItemInfoWrapper>
        <Info />
      </StyledBookingListItemInfoWrapper>
      <StyledBookingListItemContentWrapper>
        <Content />
      </StyledBookingListItemContentWrapper>
      <StyledBookingListItemDetailsWrapper>
        <Details />
      </StyledBookingListItemDetailsWrapper>
    </StyledBookingListItem>
  )
}

BookingListItem.propTypes = {
  /** className */
  className: PropTypes.string,
  /** token */
  token: PropTypes.string,
  /** clientReference */
  clientReference: PropTypes.string,
  /** clientBookingNumber */
  clientBookingNumber: PropTypes.string,
  /** forwarderLogo */
  forwarderLogo: PropTypes.string,
  /** transportType */
  transportType: PropTypes.string,
  /** originName */
  originName: PropTypes.string,
  /** originCountryCode */
  originCountryCode: PropTypes.string,
  /** destinationName */
  destinationName: PropTypes.string,
  /** destinationCountryCode */
  destinationCountryCode: PropTypes.string,
  /** ptd */
  ptd: PropTypes.string,
  /** pta */
  pta: PropTypes.string,
  /** merchandiseHasAnyHazardousGoods */
  merchandiseHasAnyHazardousGoods: PropTypes.bool,
  /** merchandiseHasAnyControlledTemperatures */
  merchandiseHasAnyControlledTemperatures: PropTypes.bool,
  /** status */
  status: PropTypes.string,
  /** lastUpdated */
  lastUpdated: PropTypes.string,
  /** hasNewDocuments */
  hasNewDocuments: PropTypes.bool,
  /** hasActions */
  hasActions: PropTypes.bool,
  /** order tokens */
  orderTokens: PropTypes.arrayOf(PropTypes.string),
  testId: PropTypes.string,
}

BookingListItem.defaultProps = {
  className: undefined,
  token: undefined,
  clientReference: undefined,
  clientBookingNumber: undefined,
  forwarderLogo: undefined,
  transportType: undefined,
  originName: undefined,
  originCountryCode: undefined,
  destinationName: undefined,
  destinationCountryCode: undefined,
  ptd: undefined,
  pta: undefined,
  merchandiseHasAnyHazardousGoods: undefined,
  merchandiseHasAnyControlledTemperatures: undefined,
  status: undefined,
  lastUpdated: undefined,
  hasNewDocuments: undefined,
  hasActions: undefined,
  orderTokens: [],
  testId: undefined,
}

export default BookingListItem
