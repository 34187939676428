import styled, { keyframes } from 'styled-components'

import { TrustedRoutesMaxWidth, TrustedRoutesPagePadding } from 'views/trusted_routes/style'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { desktop } from 'styles/utils/breakpoints'

const Container = styled.div`
  max-width: ${TrustedRoutesMaxWidth}px;
  background-color: ${({ theme }) => theme.white};
  padding: 20px 20px 20px 22px;
  ${borderRadiusSmall};
  margin-right: ${TrustedRoutesPagePadding}px;
  border: 1px solid ${({ theme }) => theme.primary};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-direction: row;

  @media ${desktop.down} {
    flex-direction: column;
  }
`

const Content = styled.div`
  border-top: 2px solid ${({ theme }) => theme.lightGray};
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px 20px;
  font-size: small;
  color: gray;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-size: 22px;
`

const LoadingAnimation = keyframes`
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
`

const Loader = styled.div`
  display: flex;
  flex-grow: 1;
  background: linear-gradient(to right, white, ${({ theme }) => theme.primary} 200%);
  background-size: 200% 200%;
  height: 8px;
  animation: ${LoadingAnimation} 2s ease infinite;
`

const GoToAlternativeLink = styled.b`
  text-decoration-line: underline;
  white-space: nowrap;
  padding-left: 5px;
  color: ${({ theme }) => theme.primary};
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`

const NearByDescription = styled.p`
  color: grey;
`

const CriterionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
`

const CriterionTitle = styled.span`
  white-space: nowrap;
`

const S = {
  Container,
  Header,
  Title,
  NearByDescription,
  Content,
  Loader,
  GoToAlternativeLink,
  CriterionContainer,
  CriterionTitle,
}

export default S
