import { useSelector } from 'react-redux'

import useStatus, { UseStatus } from 'services/api/hooks/use_status'
import { RootState } from 'services/store/store'
import { selectEmissions, selectEmissionsStatus } from 'features/shipments/store/shipment_slice'

const useEmissions = ({ id }: { id: string }): [{ co2E: string | null }, UseStatus] => {
  const emissions = useSelector(selectEmissions({ id }))
  const status = useStatus(useSelector((state: RootState) => selectEmissionsStatus(state, id)))
  return [emissions, status]
}

export default useEmissions
