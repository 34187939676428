import axios from 'axios'

import toCamelCaseResponseMiddleware from 'services/api/middlewares/to_camel_case_response_middleware'
import toSnakeCaseRequestMiddleware from 'services/api/middlewares/to_snake_case_request_middleware'
import { CONTENT_TYPE_JSON } from 'services/api/constants'
import {
  removeTokens,
  saveAccessToken,
  saveRefreshToken,
  getRefreshToken,
  createHeadersForAPIAuthentication,
  isOAUTH2AuthenticationType,
} from 'utils/authentication'

const REFRESH_TOKEN_URL = '/users/refresh_token'
const CLEAN_SESSION_URL = '/users/clean_session'

// axios documentation:
// https://axios-http.com/docs/instance

let isTokenRefreshing = false
let failedRequests = []

const createInstance = ({ baseUrl }) => {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      'Content-Type': CONTENT_TYPE_JSON,
      Accept: CONTENT_TYPE_JSON,
    },
  })

  client.interceptors.request.use((config) => ({
    ...config,
    headers: createHeadersForAPIAuthentication(config.headers),
  }))

  client.interceptors.response.use(
    (response) => toCamelCaseResponseMiddleware(response),
    (error) => {
      // eslint-disable-next-line no-param-reassign
      error.response = toCamelCaseResponseMiddleware(error.response)
      throw error
    }
  )
  // Middleware snakeCase -> camelCase
  client.interceptors.request.use(toSnakeCaseRequestMiddleware)

  // Refresh token management
  client.interceptors.response.use(
    (response) => response, // return successful response
    async (error) => {
      const originalRequest = error.config
      if (error.response?.status === 498) {
        if (
          !originalRequest.retry &&
          // we filter the refresh url to prevent infinite loop
          originalRequest.url !== REFRESH_TOKEN_URL &&
          isOAUTH2AuthenticationType() &&
          // we check that this is a wakeo api request
          originalRequest.baseURL.includes(process.env.REACT_APP_API_ENDPOINT)
        ) {
          originalRequest.retry = true // Mark the request as retried to avoid infinite loops.

          if (isTokenRefreshing) {
            return new Promise((resolve, reject) => {
              failedRequests.push({
                resolve,
                reject,
                config: originalRequest,
                error,
              })
            })
          }
          try {
            isTokenRefreshing = true
            const refreshToken = getRefreshToken() // Retrieve the stored refresh token.

            const response = await client.post(REFRESH_TOKEN_URL, {
              refreshToken,
            })
            const { accessToken, refreshToken: newRefreshToken } = response.data
            // Store the new access and refresh tokens.
            saveAccessToken(accessToken)
            saveRefreshToken(newRefreshToken)
            // Update the client's headers with the new access token.
            client.defaults.headers.common = createHeadersForAPIAuthentication(
              client.defaults.headers.common
            )
            // we process the failed requests with the new accessToken
            failedRequests.forEach(({ resolve, reject, config }) => {
              client(config)
                .then((res) => resolve(res))
                .catch((e) => reject(e))
            })
            return client(originalRequest) // Retry the original request with the new access token.
          } catch (refreshError) {
            removeTokens()
            return Promise.reject(refreshError)
          } finally {
            failedRequests = []
            isTokenRefreshing = false
          }
        } else {
          // HACKEO : Due to a Rails 7.0 bug, we need to clean an extra session cookie
          // https://github.com/wakeoTeam/wakeo_platform/blob/dev/docs/hacks/008-logout-extra-cookie.md
          removeTokens()
          await client.get(CLEAN_SESSION_URL)
        }
      }
      return Promise.reject(error) // For all other errors, return the error as is.
    }
  )

  return client
}

export const InternalClient = createInstance({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/api/v1`,
})
export const SharedClient = createInstance({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/api/shared/v1`,
})
export const TrustedRouteClient = createInstance({
  baseUrl: `${process.env.REACT_APP_TRUSTED_ROUTES_API_ENDPOINT}/internal/v1`,
})
export const AtlasClient = createInstance({
  baseUrl: `${process.env.REACT_APP_ATLAS_API_ENDPOINT}/internal/v1`,
})
export const ExternalClient = axios.create()
