import React from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { Link, useLocation } from 'react-router-dom'

import {
  selectAlternatives,
  selectShowAlternatives,
  SORT_BY_SUSTAINABILITY,
  SORT_BY_TRANSIT_TIME,
} from 'views/trusted_routes/slice'

import S from 'views/trusted_routes/components/alternative_routes/style'

import useSearchAlternativeTrustedRoutes from 'views/trusted_routes/hooks/use_search_alternatives'
import AccordionItem from 'components/accordion_item'
import {
  AlternativeNumericalValue,
  AlternativeType,
  CO2,
  DISRUPTIONS,
  ESTIMATED_TT,
} from 'views/trusted_routes/components/alternative_routes/types'
import useTracker from 'services/analytics/hooks/use_tracker'
import useQueryParams from 'services/hooks/use_query_params'
import { SearchBarQueryParams } from 'views/trusted_routes/components/search_bar/types'
import Logo from 'components/logo'
import AlternativesSkeleton from 'views/trusted_routes/components/alternative_routes/skeleton'

const getQueryParamsForNumericalAlternative = (
  numericalValueAlternative: AlternativeNumericalValue
) =>
  Object.entries({
    departure: numericalValueAlternative.departureName,
    arrival: numericalValueAlternative.arrivalName,
    departureLocode: numericalValueAlternative.departure,
    arrivalLocode: numericalValueAlternative.arrival,
  })
    .map(([k, v]) => `${k}=${v}`)
    .join('&')

const AlternativeRoutes: React.FC = () => {
  const showAlternatives = useSelector(selectShowAlternatives)
  const alternatives = useSelector(selectAlternatives)
  useSearchAlternativeTrustedRoutes()
  const { t } = useTranslation()
  const { track } = useTracker()
  const location = useLocation()
  const urlQueryParams = useQueryParams<SearchBarQueryParams>()

  const trackAlternativeClicked = (altType: AlternativeType, value: AlternativeNumericalValue) =>
    track('TrustedRoutes / SelectedAlternative', {
      alternativeCriterion: altType,
      polSource: urlQueryParams.departureLocode,
      podSource: urlQueryParams.arrivalLocode,
      polAlternative: value.departure,
      podAlternative: value.arrival,
      criterionValue: value.value,
    })

  const displayLoader = alternatives === null
  const displayLoaderOrAlternatives =
    alternatives === null ||
    alternatives.bestHistoricalCo2Alternatives ||
    alternatives.bestHistoricalEstimatedTimeAlternatives ||
    alternatives.bestHistoricalDisruptionAlertsAlternatives

  return (
    <>
      {showAlternatives && displayLoaderOrAlternatives ? (
        <S.Container>
          <AccordionItem
            defaultOpened
            indicator='arrow'
            heading={
              <S.Header>
                <S.Title>
                  <Logo width={20} height={20} name='tip' />
                  <b>{t('trustedRoutes.alternatives.title')}</b>
                </S.Title>
                <S.NearByDescription>
                  {t('trustedRoutes.alternatives.description')}
                </S.NearByDescription>
              </S.Header>
            }
          >
            <S.Content>
              {displayLoader ? (
                <AlternativesSkeleton />
              ) : (
                <>
                  {alternatives?.bestHistoricalEstimatedTimeAlternatives ? (
                    <S.CriterionContainer>
                      <S.CriterionTitle>
                        {t('trustedRoutes.alternatives.betterEstimatedTime')}:
                      </S.CriterionTitle>

                      {alternatives?.bestHistoricalEstimatedTimeAlternatives?.map((alternative) => (
                        <Link
                          to={`${location.pathname}?${getQueryParamsForNumericalAlternative(
                            alternative
                          )}&fromDate=${
                            urlQueryParams.fromDate
                          }&fromAlternatives=true&sortBy=${SORT_BY_TRANSIT_TIME}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          onClick={() => trackAlternativeClicked(ESTIMATED_TT, alternative)}
                        >
                          <S.GoToAlternativeLink>
                            {alternative.departureName}→{alternative.arrivalName} (
                            {alternative.value} {t('trustedRoutes.criteria.day')})
                          </S.GoToAlternativeLink>
                        </Link>
                      ))}
                    </S.CriterionContainer>
                  ) : null}

                  {alternatives?.bestHistoricalCo2Alternatives ? (
                    <S.CriterionContainer>
                      <S.CriterionTitle>
                        {t('trustedRoutes.alternatives.lowerEmission')}:
                      </S.CriterionTitle>

                      {alternatives?.bestHistoricalCo2Alternatives?.map((alternative) => (
                        <Link
                          to={`${location.pathname}?${getQueryParamsForNumericalAlternative(
                            alternative
                          )}&fromDate=${
                            urlQueryParams.fromDate
                          }&fromAlternatives=true&sortBy=${SORT_BY_SUSTAINABILITY}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          onClick={() => trackAlternativeClicked(CO2, alternative)}
                        >
                          <S.GoToAlternativeLink>
                            {alternative.departureName}→{alternative.arrivalName} (
                            {Math.round((alternative.value + Number.EPSILON) * 100) / 100}{' '}
                            {t('trustedRoutes.criteria.co2')})
                          </S.GoToAlternativeLink>
                        </Link>
                      ))}
                    </S.CriterionContainer>
                  ) : null}

                  {alternatives?.bestHistoricalDisruptionAlertsAlternatives ? (
                    <S.CriterionContainer>
                      <S.CriterionTitle>
                        {t('trustedRoutes.alternatives.fewerDisruptions')}:
                      </S.CriterionTitle>

                      {alternatives?.bestHistoricalDisruptionAlertsAlternatives?.map(
                        (alternative) => (
                          <Link
                            to={`${location.pathname}?${getQueryParamsForNumericalAlternative(
                              alternative
                            )}&fromDate=${
                              urlQueryParams.fromDate
                            }&fromAlternatives=true&sortBy=${SORT_BY_SUSTAINABILITY}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={() => trackAlternativeClicked(DISRUPTIONS, alternative)}
                          >
                            <S.GoToAlternativeLink>
                              {alternative.departureName}→{alternative.arrivalName}
                            </S.GoToAlternativeLink>
                          </Link>
                        )
                      )}
                    </S.CriterionContainer>
                  ) : null}
                </>
              )}
            </S.Content>
          </AccordionItem>
        </S.Container>
      ) : null}
    </>
  )
}

export default AlternativeRoutes
