import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import IconTooltip from 'components/icon_tooltip'
import { StyledMilestoneDetail } from 'core/components/timeline/style'

import DateHelper from 'services/helpers/date_helper'

import { isPresent } from 'services/helpers/values'

interface TimelineMilestoneDateProps {
  time?: string
  editedByAdmin: boolean
  newTime?: string
  canEditMilestone: boolean
  proposalDateTestId?: string
  dateTestId?: string
}

const TimelineMilestoneTime: FC<TimelineMilestoneDateProps> = ({
  time,
  editedByAdmin,
  newTime,
  canEditMilestone,
  proposalDateTestId,
  dateTestId,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {isPresent(time) && (
        <StyledMilestoneDetail $withNewTime={isPresent(newTime)} data-testid={dateTestId}>
          {new DateHelper(time).toLocale({ hours: true })}
          {editedByAdmin && canEditMilestone && (
            <IconTooltip
              content={t('shipments.dateDeclarationModal.adminDate')}
              placement='right'
              variant='admin_icon'
              size='nowrap'
            />
          )}
        </StyledMilestoneDetail>
      )}
      {!isPresent(time) && editedByAdmin && canEditMilestone && (
        <StyledMilestoneDetail>
          <IconTooltip
            content={t('shipments.dateDeclarationModal.adminRemovedDate')}
            placement='right'
            variant='admin_icon'
            size='nowrap'
          />
        </StyledMilestoneDetail>
      )}
      {newTime && (
        <StyledMilestoneDetail $primary data-testid={proposalDateTestId}>
          {new DateHelper(newTime).toLocale({ hours: true })}
        </StyledMilestoneDetail>
      )}
    </>
  )
}

export default TimelineMilestoneTime
