import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FilterTabContext } from 'contexts/filters'

import Select from 'components/select'
import Tag from 'components/tag'
import Input from 'components/input'
import InputCheckbox from 'components/input_checkbox'
import CheckboxPicto from 'components/checkbox_picto'
import InputDatepicker from 'components/input_datepicker'
import FiltersSidebar from 'components/filters_sidebar'
import useCategories from 'components/booking_filters_custom/hooks/use_categories'
import useFilters from 'components/booking_filters_custom/hooks/use_filters'
import useSelect from 'components/booking_filters_custom/hooks/use_select'

import {
  StyledTagCheckboxContainer,
  StyledBookingFiltersTag,
  StyledBookingFiltersCheckPictos,
  StyledBookingTagsSection,
} from 'components/booking_filters_custom/style'

import { TRANSPORT_MODES } from 'constants/bookings'

import iconsMapping from 'services/helpers/icons_mapping'
import useForm from 'services/hooks/use_form'
import { isInputEmpty } from 'services/helpers/values'

import {
  fetchAddresses,
  fetchConsignees,
  fetchConsignors,
  fetchForwarders,
  fetchShippers,
  fetchIncoterms,
  selectBookingsPreloadFilters,
  resetBookingsPreloadFilters,
} from 'views/bookings/slice'
import FiltersContext from 'views/bookings/contexts/filters_context'
import {
  getTestIdForBookingFiltersTagApplied,
  TEST_ID_BOOKING_FILTERS_ACTION_REQUIRED,
  TEST_ID_BOOKING_FILTERS_REFERENCE,
  TEST_ID_BOOKING_FILTERS_STATUS_ACCEPTED,
  TEST_ID_BOOKING_FILTERS_STATUS_CANCELLED,
  TEST_ID_BOOKING_FILTERS_STATUS_CONFIRMED,
  TEST_ID_BOOKING_FILTERS_STATUS_IN_REVIEW,
  TEST_ID_BOOKING_FILTERS_STATUS_COMPLETED,
  TEST_ID_BOOKING_FILTERS_TRANSPORT_TYPE_AIR,
  TEST_ID_BOOKING_FILTERS_TRANSPORT_TYPE_SEA,
  TEST_ID_BOOKING_SIDENAV_CUSTOM_FILTERS_TAB,
  TEST_ID_FILTERS_TEMPLATE_INPUT,
  TEST_ID_BOOKING_TEMPLATE_SAVE,
  TEST_ID_BOOKING_FILTERS_NOTIFICATION_SECTION,
  TEST_ID_BOOKING_FILTERS_STATUS_SECTION,
  TEST_ID_BOOKING_FILTERS_REFERENCE_SECTION,
  TEST_ID_BOOKING_FILTERS_ROUTING_SECTION,
  TEST_ID_BOOKING_FILTERS_ROUTING_ORIGIN,
  TEST_ID_BOOKING_FILTERS_EXPECTED_TIME_SECTION,
  TEST_ID_BOOKING_FILTERS_EXPECTED_ORIGIN_TIME,
  TEST_ID_BOOKING_FILTERS_STATUS_DECLINED,
  TEST_ID_BOOKING_FILTERS_TRANSPORT_MODE_SECTION,
  TEST_ID_BOOKING_FILTERS_SHIPPING_MODE,
  TEST_ID_BOOKING_FILTERS_SHIPPING_MODE_LCL,
  TEST_ID_BOOKING_FILTERS_SHIPPING_MODE_FCL,
  TEST_ID_BOOKING_FILTERS_SPECIFICITIES_SECTION,
  TEST_ID_BOOKING_FILTERS_CONTROLLED_TEMPERATURE,
  TEST_ID_BOOKING_FILTERS_HAZARDOUS_GOODS,
} from 'tests/e2e/test_ids'
import {
  StyledShipmentFilters,
  StyledShipmentFiltersTabs,
  StyledShipmentFilterTemplateInput,
} from 'features/shipments/components/shipment_filters/style'
import Tabs, { Tab } from 'components/tabs'
import StyledCustomFilters from 'components/shipment_filters_custom/style'
import useTemplates from 'components/booking_filters_custom/hooks/use_templates'
import useTags from 'views/booking/hooks/use_tags'
import useActiveFilters from 'views/bookings/hooks/use_active_filters'
import useActiveValues from 'views/bookings/hooks/use_active_values'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

const BookingFiltersCustom = () => {
  const { features } = useOrganizationCan()
  const orgaWithLegacyWorkflow = features(WITH_BOOKING_OLD_WORKFLOW)
  const { hasFiltersActive } = useContext(FiltersContext)
  const preloadFilters = useSelector(selectBookingsPreloadFilters)
  const dispatch = useDispatch()

  const filters = useFilters()

  const {
    transportModeSeaFilter,
    transportModeAirFilter,
    notificationActionRequiredFilter,
    notificationMyBookingsFilter,
    referenceFilter,
    partiesShipperFilter,
    partiesForwarderFilter,
    partiesConsigneeFilter,
    partiesConsignorFilter,
    shippingModeFCLFilter,
    shippingModeLCLFilter,
    specifitiyControlledTemperatureFilter,
    specifityHazardousGoodsFilter,
    statusAcceptedFilter,
    statusCancelledFilter,
    statusDeclinedFilter,
    statusConfirmedFilter,
    statusInReviewFilter,
    statusCompletedFilter,
    originDateFilter,
    destinationDateFilter,
    incotermsFilter,
    routingOriginFilter,
    routingDestinationFilter,
  } = filters

  const { arrayOfCategories } = useCategories({ filters })

  const { save: saveActiveFilters } = useActiveFilters({ categories: arrayOfCategories })

  const { setActiveValues, activeValuesComputed, activeValuesStored } = useActiveValues({
    categories: arrayOfCategories,
  })

  const { apply, reset } = useForm({
    categories: arrayOfCategories,
    onApply: () => {
      saveActiveFilters()
      dispatch(resetBookingsPreloadFilters())
      setActiveTags()
    },
  })

  const [shouldApplyTemplate, setShouldApplyTemplate] = useState(false)

  useEffect(() => {
    if (shouldApplyTemplate) {
      setShouldApplyTemplate(false)
      apply()
    }
  }, [apply, shouldApplyTemplate])

  const {
    name: templateName,
    setName: setTemplateName,
    create: createTemplate,
  } = useTemplates({
    categories: arrayOfCategories,
    onApply: () => {
      setShouldApplyTemplate(true)
    },
  })

  // We come back on the page Apply values
  useEffect(() => {
    if (activeValuesComputed !== activeValuesStored) {
      setActiveValues()
      setActiveTags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeValuesStored])

  const { activeTags, setActiveTags } = useTags({ categories: arrayOfCategories })

  const { currentTab, switchToTemplatesTab } = useContext(FilterTabContext)

  const { t } = useTranslation()

  useEffect(() => {
    if (Object.entries(preloadFilters).length > 0) {
      Object.entries(preloadFilters).forEach(([name, value]) => {
        Object.values(filters)
          .find(({ name: filterName }) => filterName === name)
          ?.setValue(value)
      })
      apply()
    }
  }, [dispatch, apply, preloadFilters, filters])

  const { toIndexedSelectValue, fetchOptions, fetchedOptionsFormat } = useSelect()

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      apply()
    }
  }

  return (
    <FiltersSidebar
      onClear={() => {
        reset()
        if (hasFiltersActive) {
          apply()
        }
      }}
      onApply={() => apply()}
      header={
        <StyledShipmentFilters>
          <StyledShipmentFiltersTabs
            as={Tabs}
            defaultTabId={currentTab ?? undefined}
            onTabChange={({ id }) => {
              if (id === 'templates-tab') {
                switchToTemplatesTab()
              }
            }}
          >
            <Tab
              id='custom-tab'
              label={t('shipments.filters.custom')}
              key='shipment-filters-custom'
              testId={TEST_ID_BOOKING_SIDENAV_CUSTOM_FILTERS_TAB}
            >
              <StyledCustomFilters>
                <StyledBookingTagsSection>
                  {activeTags.map(({ value, onDelete }, index) => (
                    <StyledBookingFiltersTag
                      testId={getTestIdForBookingFiltersTagApplied(index)}
                      key={`booking-filter-tag-${value}`}
                      as={Tag}
                      text={value}
                      deletable
                      onDelete={() => {
                        onDelete()
                        apply()
                      }}
                    />
                  ))}
                </StyledBookingTagsSection>
                <StyledShipmentFilterTemplateInput>
                  <Input
                    type='text'
                    placeholder={t('shipments.filterTemplates.inputPlaceholder')}
                    name='filter_template_name'
                    value={templateName}
                    onChange={({ target: { value } }) => setTemplateName(value)}
                    button={{
                      testId: TEST_ID_BOOKING_TEMPLATE_SAVE,
                      text: t('actions.save'),
                      icon: 'save_outline',
                      onClick: () => createTemplate().then(() => switchToTemplatesTab()),
                      disabled: isInputEmpty(templateName) || activeTags.length <= 0,
                    }}
                    testId={TEST_ID_FILTERS_TEMPLATE_INPUT}
                  />
                </StyledShipmentFilterTemplateInput>
              </StyledCustomFilters>
            </Tab>
            <Tab
              id='templates-tab'
              label={t('shipments.filters.saved')}
              key='shipment-filters-templates'
            />
          </StyledShipmentFiltersTabs>
        </StyledShipmentFilters>
      }
    >
      <FiltersSidebar.Section
        title='Notification'
        testId={TEST_ID_BOOKING_FILTERS_NOTIFICATION_SECTION}
      >
        <InputCheckbox
          id={notificationMyBookingsFilter.name}
          name={notificationMyBookingsFilter.name}
          text={t('bookings.tags.myBookings')}
          checked={notificationMyBookingsFilter.value}
          onChange={notificationMyBookingsFilter.onChange}
        />
        {orgaWithLegacyWorkflow && (
          <InputCheckbox
            id={notificationActionRequiredFilter.name}
            name={notificationActionRequiredFilter.name}
            text={t('bookings.tags.actionRequired')}
            checked={notificationActionRequiredFilter.value}
            onChange={notificationActionRequiredFilter.onChange}
            testId={TEST_ID_BOOKING_FILTERS_ACTION_REQUIRED}
          />
        )}
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('bookings.status.status')}
        testId={TEST_ID_BOOKING_FILTERS_STATUS_SECTION}
      >
        <StyledTagCheckboxContainer>
          <InputCheckbox
            id={statusInReviewFilter.name}
            name={statusInReviewFilter.name}
            text={t('bookings.status.inReview')}
            checked={statusInReviewFilter.value}
            onChange={statusInReviewFilter.onChange}
            color='warn'
            asTag
            testId={TEST_ID_BOOKING_FILTERS_STATUS_IN_REVIEW}
          />
          <InputCheckbox
            id={statusAcceptedFilter.name}
            name={statusAcceptedFilter.name}
            text={t('bookings.status.accepted')}
            checked={statusAcceptedFilter.value}
            onChange={statusAcceptedFilter.onChange}
            color='secondary'
            asTag
            testId={TEST_ID_BOOKING_FILTERS_STATUS_ACCEPTED}
          />
          <InputCheckbox
            id={statusConfirmedFilter.name}
            name={statusConfirmedFilter.name}
            text={t('bookings.status.confirmed')}
            checked={statusConfirmedFilter.value}
            onChange={statusConfirmedFilter.onChange}
            color='secondary'
            asTag
            testId={TEST_ID_BOOKING_FILTERS_STATUS_CONFIRMED}
          />

          <InputCheckbox
            id={statusCompletedFilter.name}
            name={statusCompletedFilter.name}
            text={t('bookings.status.completed')}
            checked={statusCompletedFilter.value}
            onChange={statusCompletedFilter.onChange}
            color='primary'
            asTag
            testId={TEST_ID_BOOKING_FILTERS_STATUS_COMPLETED}
          />
          <InputCheckbox
            id={statusCancelledFilter.name}
            name={statusCancelledFilter.name}
            text={t('bookings.status.cancelled')}
            checked={statusCancelledFilter.value}
            onChange={statusCancelledFilter.onChange}
            color='error'
            asTag
            testId={TEST_ID_BOOKING_FILTERS_STATUS_CANCELLED}
          />
          {!orgaWithLegacyWorkflow && (
            <InputCheckbox
              id={statusDeclinedFilter.name}
              name={statusDeclinedFilter.name}
              text={t('bookings.status.declined')}
              checked={statusDeclinedFilter.value}
              onChange={statusDeclinedFilter.onChange}
              color='error'
              asTag
              testId={TEST_ID_BOOKING_FILTERS_STATUS_DECLINED}
            />
          )}
        </StyledTagCheckboxContainer>
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('bookings.reference')}
        testId={TEST_ID_BOOKING_FILTERS_REFERENCE_SECTION}
      >
        <Input
          type='text'
          placeholder='ie. SOCRTTY7789'
          name={referenceFilter.name}
          value={referenceFilter.value}
          onChange={referenceFilter.onChange}
          onKeyUp={onEnter}
          testId={TEST_ID_BOOKING_FILTERS_REFERENCE}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title={t('bookings.parties.title')}>
        <Select
          label={t('bookings.parties.shipper')}
          name={partiesShipperFilter.name}
          onChange={partiesShipperFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions(fetchShippers)}
          fetchOnFocus={fetchOptions(fetchShippers)}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesShipperFilter)}
        />
        <Select
          label={t('bookings.parties.forwarder')}
          name={partiesForwarderFilter.name}
          onChange={partiesForwarderFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions(fetchForwarders)}
          fetchOnFocus={fetchOptions(fetchForwarders)}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesForwarderFilter)}
        />
        <Select
          label={t('bookings.parties.consignor')}
          name={partiesConsignorFilter.name}
          onChange={partiesConsignorFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions(fetchConsignors)}
          fetchOnFocus={fetchOptions(fetchConsignors)}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesConsignorFilter)}
        />

        <Select
          label={t('bookings.parties.consignee')}
          name={partiesConsigneeFilter.name}
          onChange={partiesConsigneeFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions(fetchConsignees)}
          fetchOnFocus={fetchOptions(fetchConsignees)}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesConsigneeFilter)}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title={t('bookings.incoterms.title')}>
        <Select
          label=''
          name={incotermsFilter.name}
          onChange={incotermsFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions(fetchIncoterms)}
          fetchOnFocus={fetchOptions(fetchIncoterms)}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(incotermsFilter)}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('bookings.transportMode.title')}
        testId={TEST_ID_BOOKING_FILTERS_TRANSPORT_MODE_SECTION}
      >
        <StyledBookingFiltersCheckPictos>
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_MODES.AIR, 'transportation')}
            id={transportModeAirFilter.name}
            name={transportModeAirFilter.name}
            text={t('bookings.transportMode.air')}
            checked={transportModeAirFilter.value}
            onChange={transportModeAirFilter.onChange}
            testId={TEST_ID_BOOKING_FILTERS_TRANSPORT_TYPE_AIR}
          />
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_MODES.SEA, 'transportation')}
            id={transportModeSeaFilter.name}
            name={transportModeSeaFilter.name}
            text={t('bookings.transportMode.sea')}
            checked={transportModeSeaFilter.value}
            onChange={transportModeSeaFilter.onChange}
            testId={TEST_ID_BOOKING_FILTERS_TRANSPORT_TYPE_SEA}
          />
        </StyledBookingFiltersCheckPictos>
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('bookings.routing.title')}
        testId={TEST_ID_BOOKING_FILTERS_ROUTING_SECTION}
      >
        <Select
          testId={TEST_ID_BOOKING_FILTERS_ROUTING_ORIGIN}
          label={t('bookings.routing.origin')}
          name={routingOriginFilter.name}
          onChange={routingOriginFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions(fetchAddresses('departure'))}
          fetchOnFocus={fetchOptions(fetchAddresses('departure'))}
          value={routingOriginFilter.value}
        />

        <Select
          label={t('bookings.routing.destination')}
          name={routingDestinationFilter.name}
          onChange={routingDestinationFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions(fetchAddresses('arrival'))}
          fetchOnFocus={fetchOptions(fetchAddresses('arrival'))}
          value={routingDestinationFilter.value}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('bookings.routing.expectedTime')}
        testId={TEST_ID_BOOKING_FILTERS_EXPECTED_TIME_SECTION}
      >
        <InputDatepicker
          withPortal
          label={t('bookings.routing.origin')}
          name={originDateFilter.name}
          range
          onChange={originDateFilter.onChange}
          startDate={originDateFilter.value.start}
          endDate={originDateFilter.value.end}
          onToggleOpen={(opened) => {
            if (!opened && !originDateFilter.isPresent) {
              originDateFilter.reset()
            }
          }}
          testId={TEST_ID_BOOKING_FILTERS_EXPECTED_ORIGIN_TIME}
        />
        <InputDatepicker
          withPortal
          label={t('bookings.routing.destination')}
          name={destinationDateFilter.name}
          range
          onChange={destinationDateFilter.onChange}
          startDate={destinationDateFilter.value.start}
          endDate={destinationDateFilter.value.end}
          onToggleOpen={(opened) => {
            if (!opened && !destinationDateFilter.isPresent) {
              destinationDateFilter.reset()
            }
          }}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('bookings.shippingMode')}
        testId={TEST_ID_BOOKING_FILTERS_SHIPPING_MODE}
      >
        <InputCheckbox
          id={shippingModeFCLFilter.name}
          name={shippingModeFCLFilter.name}
          text='FCL'
          checked={shippingModeFCLFilter.value}
          onChange={shippingModeFCLFilter.onChange}
          testId={TEST_ID_BOOKING_FILTERS_SHIPPING_MODE_FCL}
        />
        <InputCheckbox
          id={shippingModeLCLFilter.name}
          name={shippingModeLCLFilter.name}
          text='LCL'
          checked={shippingModeLCLFilter.value}
          onChange={shippingModeLCLFilter.onChange}
          testId={TEST_ID_BOOKING_FILTERS_SHIPPING_MODE_LCL}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('bookings.specificities.title')}
        testId={TEST_ID_BOOKING_FILTERS_SPECIFICITIES_SECTION}
      >
        <InputCheckbox
          id={specifitiyControlledTemperatureFilter.name}
          name={specifitiyControlledTemperatureFilter.name}
          text={t('bookings.specificities.controlledTemperatures')}
          checked={specifitiyControlledTemperatureFilter.value}
          onChange={specifitiyControlledTemperatureFilter.onChange}
          testId={TEST_ID_BOOKING_FILTERS_CONTROLLED_TEMPERATURE}
        />
        <InputCheckbox
          id={specifityHazardousGoodsFilter.name}
          name={specifityHazardousGoodsFilter.name}
          text={t('bookings.specificities.hazardousGoods')}
          checked={specifityHazardousGoodsFilter.value}
          onChange={specifityHazardousGoodsFilter.onChange}
          testId={TEST_ID_BOOKING_FILTERS_HAZARDOUS_GOODS}
        />
      </FiltersSidebar.Section>
    </FiltersSidebar>
  )
}
BookingFiltersCustom.propTypes = {}

BookingFiltersCustom.defaultProps = {}

export default BookingFiltersCustom
