import styled from 'styled-components'

export const StyledShipmentReportedInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 10px 0;
  justify-content: flex-end;
`

export const StyledText = styled.p``

export const StyledInfoIcon = styled.div`
  padding: 3px 10px 0 10px;
`
