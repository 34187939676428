import styled from 'styled-components'

import { borderRadiusMedium } from 'styles/utils/border_radius'

export const StyledSignIn = styled.div`
  background: ${({ theme }) => theme.primary};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSignInForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  padding: 40px 60px 60px;
  background: ${({ theme }) => theme.white};
  ${borderRadiusMedium};
`

export const StyledSignInLogo = styled.div`
  padding-bottom: 30px;
`

export const SSOSignInContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`

export const SSOSignOutContainer = styled.div`
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StyledLink = styled.span`
  color: #007bff;
`
export const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`
