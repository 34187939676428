import { InternalClient } from 'services/api/clients'
import { GetWhiteLabelResourcesResponse, ImagesUrl } from 'features/white_label/types/theme'

export const fetchWhiteLabelResources = async (customDomain: string) => {
  try {
    const response = await InternalClient.get<GetWhiteLabelResourcesResponse>(
      `/white_label?custom_domain=${customDomain}`
    )

    return response.data
  } catch (error) {
    return {}
  }
}

export const applyWakeoResources = () => {
  changeFavicon('/wakeo/favicon.ico')
  changeFavicon32('/wakeo/favicon-32x32.png')
  changeFavicon16('/wakeo/favicon-16x16.png')
  changeAppleTouchIcon('/wakeo/apple-touch-icon.png')
  changeManifest('/wakeo/manifest.json')
  setUpMSApplicationConfig('/wakeo/mstile150x150.png')
  changePageTitle('Wakeo')
}

export const applyDefaultResources = () => {
  changeFavicon('/default/favicon.ico')
  changeFavicon32('/default/favicon-32x32.png')
  changeFavicon16('/default/favicon-16x16.png')
  changeAppleTouchIcon('/default/apple-touch-icon.png')
  changeManifest('/default/manifest.json')
  setUpMSApplicationConfig('/default/mstile150x150.png')
  changePageTitle('Supply Chain Management')
}

export const clearAllResources = () => {
  changeFavicon('')
  changeFavicon32('')
  changeFavicon16('')
  changeAppleTouchIcon('')
  changeManifest('')
  setUpMSApplicationConfig('')
  changePageTitle('')
}

export const changeFavicon = (href: string) => {
  let favicon = document.getElementById('rel-favicon-id') as HTMLLinkElement
  if (!favicon) {
    favicon = document.createElement('link')
    favicon.id = 'rel-favicon-id'
    favicon.rel = 'icon'
    document.head.appendChild(favicon)
  }

  if (favicon.href !== href) favicon.href = href
}

export const changeFavicon32 = (href: string) => {
  let favicon32 = document.getElementById('rel-favicon-32-id') as HTMLLinkElement

  if (!favicon32) {
    favicon32 = document.createElement('link')
    favicon32.id = 'rel-favicon-32-id'
    favicon32.rel = 'icon'
    favicon32.type = 'image/png'
    favicon32.setAttribute('sizes', '32x32')
    document.head.appendChild(favicon32)
  }

  if (favicon32.href !== href) favicon32.href = href
}

export const changeFavicon16 = (href: string) => {
  let favicon16 = document.getElementById('rel-favicon-16-id') as HTMLLinkElement
  if (!favicon16) {
    favicon16 = document.createElement('link')
    favicon16.id = 'rel-favicon-16-id'
    favicon16.rel = 'icon'
    favicon16.type = 'image/png'
    favicon16.setAttribute('sizes', '16x16')
    document.head.appendChild(favicon16)
  }
  if (favicon16.href !== href) favicon16.href = href
}

export const changeAppleTouchIcon = (href: string) => {
  let appleTouchIcon = document.getElementById('rel-apple-touch-icon-id') as HTMLLinkElement
  if (!appleTouchIcon) {
    appleTouchIcon = document.createElement('link')
    appleTouchIcon.id = 'rel-apple-touch-icon-id'
    appleTouchIcon.rel = 'apple-touch-icon'
    document.head.appendChild(appleTouchIcon)
  }

  if (appleTouchIcon.href !== href) appleTouchIcon.href = href
}

export const changeManifest = (href: string) => {
  let manifest = document.getElementById('rel-manifest-id') as HTMLLinkElement
  if (!manifest) {
    manifest = document.createElement('link')
    manifest.id = 'rel-manifest-id'
    manifest.rel = 'manifest'
    document.head.appendChild(manifest)
  }

  if (manifest.href !== href) manifest.href = href
}

export const changePageTitle = (title: string) => {
  let titleElement = document.getElementById('wakeo-page-title-id') as HTMLTitleElement
  if (!titleElement) {
    titleElement = document.createElement('title')
    titleElement.id = 'wakeo-page-title-id'
    document.head.appendChild(titleElement)
  }

  titleElement.textContent = title
}

const setUpMSApplicationConfig = (href: string) => {
  let msApplicationConfig = document.getElementById(
    'meta-msapplication-config-id'
  ) as HTMLMetaElement
  if (!msApplicationConfig) {
    msApplicationConfig = document.createElement('meta')
    msApplicationConfig.id = 'meta-msapplication-config-id'
    document.head.appendChild(msApplicationConfig)
  }

  if (msApplicationConfig.content !== href) msApplicationConfig.content = href
}

export const applyWhiteLabelResources = ({
  imagesUrl,
  pageTitle,
}: {
  imagesUrl?: ImagesUrl
  pageTitle?: string
}) => {
  if (imagesUrl) changeFavicon(imagesUrl.faviconUrl)
  if (imagesUrl) changeFavicon32(imagesUrl.favicon32x32Url)
  if (imagesUrl) changeFavicon16(imagesUrl.favicon16x16Url)
  if (imagesUrl) changeAppleTouchIcon(imagesUrl.appleTouchIconUrl)
  if (imagesUrl) changeManifest(imagesUrl.manifestUrl)
  if (imagesUrl) setUpMSApplicationConfig(imagesUrl.mstile150x150Url)
  if (pageTitle) changePageTitle(pageTitle)
}
