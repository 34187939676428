import React, { FC } from 'react'

import { useFieldArray, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { TransportType } from 'types/shipments'

import AccordionItem from 'components/accordion_item'
import {
  StyledBlockTransportationItemHeading,
  StyledBlockTransportationItemHeadingIcon,
  StyledBlockTransportationItemHeadingPlaces,
  StyledBlockTransportationItemHeadingPlace,
  StyledBlockTransportationItemHeadingArrow,
} from 'components/block_transportation/style'
import Icon, { IconType } from 'components/icon'
import Table from 'components/table'
import iconsMapping from 'services/helpers/icons_mapping'
import StepHeader from 'features/shipments/components/modal_timeline/components/step_header'
import { TimelineFormProps } from 'features/shipments/types/segment_timelines'
import StepDisplay from 'features/shipments/components/modal_timeline/components/step_display'
import { DATA_SOURCE_FRONT, TRANSPORT_TYPE_SEA, TRANSPORT_TYPE_PARCEL } from 'constants/shipments'
import {
  StyledEditedByAdminTextInfo,
  StyledModalTimelineStepType,
  StyledModalTimelineStepTypeWrapper,
  StyledModalTimelineStepWrapper,
} from 'features/shipments/components/modal_timeline/style'
import Button from 'components/button'
import { isPresent, toCamelCase } from 'services/helpers/values'
import { STOPOVER_STEP_BY_TRANSPORT_TYPE } from 'features/shipments/components/modal_timeline/helpers/milestones'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import {
  addNewAlert,
  getAlertTranslationKey,
} from 'features/shipments/components/modal_timeline/helpers/alerts_manager'
import { stepHasBeenEditedByAdmin } from 'features/shipments/components/modal_timeline/helpers/steps'
import StyledAddStopoverWrapper from 'features/shipments/components/modal_timeline/components/segment/style'

const Segment: FC<{
  type: IconType
  from: string
  to: string
  defaultOpened?: boolean
  index: number
  transportType: TransportType
}> = ({ type, from, to, index, transportType, defaultOpened = false }) => {
  const { control, getValues, setValue } = useFormContext<TimelineFormProps>()
  const { t } = useTranslation()
  const { s } = useStaticLocales()

  const stopoverType = STOPOVER_STEP_BY_TRANSPORT_TYPE[transportType]
  const canSegmentContainStopovers = isPresent(stopoverType)

  const { fields: preStopoversSteps, update: editPreStopoversStep } = useFieldArray({
    control,
    name: `segments.${index}.preStopoversSteps`,
  })

  const {
    fields: stopovers,
    update: editStopover,
    insert: insertStopover,
    remove: removeStopover,
  } = useFieldArray({
    control,
    name: `segments.${index}.stopovers.values`,
  })

  const { fields: postStopoversSteps, update: editPostStopoversStep } = useFieldArray({
    control,
    name: `segments.${index}.postStopoversSteps`,
  })

  const allSteps = preStopoversSteps.concat(stopovers).concat(postStopoversSteps)

  const addNewStopover = () => {
    insertStopover(stopovers.length, {
      active: true,
      isNew: true,
      type: stopoverType!,
      source: DATA_SOURCE_FRONT,
      metadata: {
        index: stopovers.length + 1,
      },
    })

    setValue(`segments.${index}.stopovers.touched`, true)

    addNewAlert({
      alert: {
        name: 'stopovers',
        value: t(getAlertTranslationKey(transportType)),
      },
      getValues,
      setValue,
    })
  }

  const { fields: milestones } = useFieldArray({
    control,
    name: `segments.${index}.milestones`,
  })

  const activeStopovers = stopovers.filter((so) => so.active)
  const firstActiveStopoverIndex = stopovers.findIndex((so) => so.active)
  const anyStopoverMissingLocation = activeStopovers.some((so) => !isPresent(so.location?.id))
  const anyStopoverEditedByAdmin = stopovers.some((so) =>
    stepHasBeenEditedByAdmin(
      so,
      milestones.filter((m) => m.location.id === so.location?.id)
    )
  )

  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <AccordionItem
              defaultOpened={defaultOpened}
              heading={
                <StyledBlockTransportationItemHeading>
                  <StyledBlockTransportationItemHeadingIcon
                    as={Icon}
                    name={iconsMapping(type, 'transportation')}
                  />

                  <StyledBlockTransportationItemHeadingPlaces>
                    <StyledBlockTransportationItemHeadingPlace $isFrom>
                      {from}
                    </StyledBlockTransportationItemHeadingPlace>

                    <StyledBlockTransportationItemHeadingArrow
                      as={Icon}
                      name='arrow_right_outline'
                      width={10}
                      height={10}
                    />

                    <StyledBlockTransportationItemHeadingPlace>
                      {to}
                    </StyledBlockTransportationItemHeadingPlace>
                  </StyledBlockTransportationItemHeadingPlaces>
                </StyledBlockTransportationItemHeading>
              }
            >
              <>
                <StepHeader typeHeader='Type' locationHeader='Location' />
                {preStopoversSteps.map((step, stepIndex) => (
                  <StepDisplay
                    key={step.id}
                    step={step}
                    transportType={transportType}
                    segmentIndex={index}
                    steps={allSteps}
                    stepIndex={stepIndex}
                    decomposedStep='preStopover'
                    editStep={editPreStopoversStep}
                    typeDescription={s(`stepTypes.${toCamelCase(step.type)}`).toString()}
                  />
                ))}
                {canSegmentContainStopovers && activeStopovers.length === 0 && (
                  <StyledModalTimelineStepWrapper>
                    <StyledModalTimelineStepTypeWrapper>
                      <StyledModalTimelineStepType>
                        {t(`steps.${toCamelCase(stopoverType)}`, { context: 'plural' })}
                        {anyStopoverEditedByAdmin && (
                          <StyledEditedByAdminTextInfo>
                            - {t('shipments.editTimelineModal.editedByAdmin').toLowerCase()}
                          </StyledEditedByAdminTextInfo>
                        )}
                      </StyledModalTimelineStepType>
                    </StyledModalTimelineStepTypeWrapper>
                  </StyledModalTimelineStepWrapper>
                )}
                {stopovers.map((step, stepIndex) => (
                  <StepDisplay
                    key={step.id}
                    step={step}
                    transportType={transportType}
                    segmentIndex={index}
                    steps={allSteps}
                    stepIndex={stepIndex}
                    editStep={editStopover}
                    removeStep={removeStopover}
                    decomposedStep='stopover'
                    typeDescription={
                      stepIndex === firstActiveStopoverIndex
                        ? `${t(`steps.${toCamelCase(stopoverType)}`, { context: 'plural' })}`
                        : null
                    }
                    adminEditionTextInfo={
                      anyStopoverEditedByAdmin && stepIndex === firstActiveStopoverIndex
                        ? `- ${t('shipments.editTimelineModal.editedByAdmin').toLowerCase()}`
                        : null
                    }
                  />
                ))}
                {postStopoversSteps.map((step, stepIndex) => (
                  <StepDisplay
                    key={step.id}
                    step={step}
                    transportType={transportType}
                    segmentIndex={index}
                    steps={allSteps}
                    stepIndex={stepIndex}
                    editStep={editPostStopoversStep}
                    typeDescription={s(`stepTypes.${toCamelCase(step.type)}`).toString()}
                    decomposedStep='postStopover'
                  />
                ))}
              </>
              {canSegmentContainStopovers && (
                <StyledAddStopoverWrapper>
                  <Button
                    text={`${t('actions.addNew', {
                      context: [TRANSPORT_TYPE_SEA, TRANSPORT_TYPE_PARCEL].includes(transportType)
                        ? 'male'
                        : 'female',
                    })} ${t(`steps.${toCamelCase(stopoverType)}`)}`}
                    variant='outline'
                    onClick={addNewStopover}
                    disabled={anyStopoverMissingLocation}
                  />
                </StyledAddStopoverWrapper>
              )}
            </AccordionItem>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default Segment
