import React, { FC, useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledShipment,
  StyledShipmentContent,
  StyledShipmentContentTabs,
  StyledShipmentInformationContent,
  StyledShipmentLeftCol,
  StyledShipmentMain,
  StyledShipmentStateSwitchButton,
  StyledShipmentWrap,
  ViewState,
} from 'features/shipments/components/style'
import ViewSwitcher from 'components/view_switcher'
import ShipmentTimeline from 'features/shipments/components/shipment_timeline'
import ShipmentAlertList from 'features/shipments/components/shipment_alert_list'
import ShipmentMap from 'features/shipments/components/shipment_map'
import SharedHeader from 'features/shipments/components/shared_header'
import { Tab } from 'components/tabs'
import SharedReferences from 'features/shipments/components/shared_shipment_view/components/shared_references'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTracker from 'services/analytics/hooks/use_tracker'

import useAlerts from 'features/shipments/hooks/use_alerts'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import useOnce from 'services/hooks/use_once'
import { fetchSharedShipment } from 'features/shipments/store/shipment_slice'
import { addNotification } from 'views/notifications/slice'
import { fetchSharedUser } from 'views/iam/slices/iamSlice'
import { Alert } from 'features/shipments/types/legacy_shipment'
import Button from 'components/button'
import ShipmentQuickInfo from 'features/shipments/components/shipment_view/components/quick_info'

const VIEW_TIMELINE = 'timeline'
const VIEW_ALERTS = 'alerts'

type ViewType = typeof VIEW_TIMELINE | typeof VIEW_ALERTS

const SharedShipmentView: FC = () => {
  const [viewState, setViewState] = useState<ViewState>('content')
  const [currentView, setCurrentView] = useState<ViewType>(VIEW_TIMELINE)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { track } = useTracker()
  const { id: token } = useContext(ShipmentTokenContext)

  const [alerts, alertStatus] = useAlerts({ id: token })

  useOnce(() => {
    dispatch(fetchSharedShipment({ token }))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

    dispatch(fetchSharedUser({ token }))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  })

  useEffect(() => {
    if (alerts.some((alert: Alert) => alert.isOngoing)) {
      setCurrentView(VIEW_ALERTS)
    }
  }, [alertStatus.ready, alerts])

  return (
    <StyledShipment $viewState={viewState}>
      <StyledShipmentStateSwitchButton
        as={Button}
        onClick={() => setViewState(viewState === 'timeline' ? 'content' : 'timeline')}
        icon={viewState === 'timeline' ? 'filesheet' : 'time_clock'}
        rounded
      />
      {/* Left col */}
      <StyledShipmentLeftCol>
        <ShipmentQuickInfo />
        <ViewSwitcher
          variant={(alerts || []).some((alert: Alert) => alert.isOngoing) ? 'warning' : 'default'}
          label={`${t('actions.view')} ${
            currentView === VIEW_TIMELINE ? t('shipments.timeline') : t('alerts.list')
          }`}
          onSwitch={() => {
            const targetedView = currentView === VIEW_TIMELINE ? VIEW_ALERTS : VIEW_TIMELINE
            setCurrentView(targetedView)
            track(`Shipment / ${targetedView}`)
          }}
        />
        {currentView === VIEW_TIMELINE && <ShipmentTimeline />}
        {currentView === VIEW_ALERTS && <ShipmentAlertList alerts={alerts} status={alertStatus} />}
      </StyledShipmentLeftCol>
      {/* Main */}
      <StyledShipmentMain>
        <ShipmentMap />

        <StyledShipmentWrap>
          {/* Content */}
          <StyledShipmentContent>
            <SharedHeader />
            <StyledShipmentContentTabs>
              <Tab id='information' label={t('shipments.information')} key='tab-information'>
                <StyledShipmentInformationContent>
                  <SharedReferences />
                </StyledShipmentInformationContent>
              </Tab>
            </StyledShipmentContentTabs>
          </StyledShipmentContent>
        </StyledShipmentWrap>
      </StyledShipmentMain>
    </StyledShipment>
  )
}

export default SharedShipmentView
