import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ORDER_DOCUMENT_READ } from 'constants/permissions'
import { WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX } from 'constants/organization_features'

import Button from 'components/button'

import useTracker from 'services/analytics/hooks/use_tracker'
import { isAnyArray } from 'services/helpers/values'
import { routeBooking, routeBookings, routeShipments } from 'services/helpers/routes'

import useUserCan from 'views/iam/hooks/use_user_can'
import ShipmentTimeline from 'features/shipments/components/shipment_timeline'
import ShipmentAlertList from 'features/shipments/components/shipment_alert_list'
import ShipmentDocumentList from 'features/shipments/components/document_list'
import ShipmentTransportation from 'features/shipments/components/shipment_transportation'
import ShipmentHeader from 'features/shipments/components/shipment_header'
import ShipmentQuickInfo from 'features/shipments/components/shipment_view/components/quick_info'
import ShipmentMap from 'features/shipments/components/shipment_map'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import {
  StyledShipmentNavigation,
  StyledShipmentNavigationButton,
  StyledShipmentNavigationButtonText,
} from 'features/shipments/components/shipment_view/style'
import { fetchAlerts, fetchShipment } from 'features/shipments/store/shipment_slice'
import useShipment from 'features/shipments/hooks/use_shipment'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { addBookingsPreloadFilters } from 'views/bookings/slice'

import { TEST_ID_SHIPMENT_SHOW_GO_TO_BOOKING } from 'tests/e2e/test_ids'

import AppContext from 'app/contexts/app_context'
import ViewSwitcher from 'components/view_switcher'
import Tabs, { Tab } from 'components/tabs'
import useAlerts from 'features/shipments/hooks/use_alerts'
import {
  StyledShipment,
  StyledShipmentContent,
  StyledShipmentContentTabs,
  StyledShipmentInformationContent,
  StyledShipmentLeftCol,
  StyledShipmentMain,
  StyledShipmentStateSwitchButton,
  StyledShipmentWrap,
  ViewState,
} from 'features/shipments/components/style'
import ShipmentReferences from 'features/shipments/components/shipment_references'

const VIEW_TIMELINE = 'timeline'
const VIEW_ALERTS = 'alerts'

const ShipmentView: FC = React.memo(() => {
  const [viewState, setViewState] = useState<ViewState>('content')
  const [currentView, setCurrentView] = useState(VIEW_TIMELINE)
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const userCan = useUserCan()
  const { features } = useOrganizationCan()
  const { t } = useTranslation()
  const [shipment, status] = useShipment({ id: id! })
  const { track } = useTracker()
  const { isEmbedded } = useContext(AppContext)
  const [alerts, alertStatus] = useAlerts({ id: id! })

  const canSeeBackToListButton =
    !isEmbedded || (isEmbedded && features(WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX))
  useEffect(() => {
    if (!status.ready) {
      dispatch(fetchShipment({ id: id! }))
    }
  }, [status.ready, id, dispatch])

  const contextValue = useMemo(() => ({ id: id! }), [id])

  useEffect(() => {
    if (!alertStatus.ready) {
      dispatch(fetchAlerts({ id: id! }))
    }
  }, [dispatch, id, alertStatus.ready])

  useEffect(() => {
    if (alerts.some((alert) => alert.isOngoing)) {
      setCurrentView(VIEW_ALERTS)
    }
  }, [alertStatus.ready, alerts])

  return (
    <ShipmentTokenContext.Provider value={contextValue}>
      <StyledShipment $viewState={viewState}>
        <StyledShipmentStateSwitchButton
          as={Button}
          onClick={() => setViewState(viewState === 'timeline' ? 'content' : 'timeline')}
          icon={viewState === 'timeline' ? 'filesheet' : 'time_clock'}
          rounded
        />

        {/* Left col */}
        <StyledShipmentLeftCol>
          <ShipmentQuickInfo />
          <ViewSwitcher
            variant={(alerts || []).some((alert) => alert.isOngoing) ? 'warning' : 'default'}
            label={`${t('actions.view')} ${
              currentView === VIEW_TIMELINE ? t('alerts.list') : t('shipments.timeline')
            }`}
            onSwitch={() => {
              const targettedView = currentView === VIEW_TIMELINE ? VIEW_ALERTS : VIEW_TIMELINE
              setCurrentView(targettedView)
              track(`Shipment / ${targettedView}`)
            }}
          />
          {currentView === VIEW_TIMELINE && <ShipmentTimeline />}
          {currentView === VIEW_ALERTS && (
            <ShipmentAlertList alerts={alerts} status={alertStatus} />
          )}
        </StyledShipmentLeftCol>

        {/* Main */}
        <StyledShipmentMain>
          <ShipmentMap />

          <StyledShipmentWrap>
            <StyledShipmentNavigation $withBackToList={canSeeBackToListButton}>
              {/* Back button */}
              {canSeeBackToListButton && (
                <StyledShipmentNavigationButton>
                  <Link to={routeShipments()} onClick={() => track('Shipment / back')}>
                    <Button icon='arrow_left' rounded />
                  </Link>
                  <StyledShipmentNavigationButtonText>
                    {t('actions.backToList')}
                  </StyledShipmentNavigationButtonText>
                </StyledShipmentNavigationButton>
              )}

              {/* Go to booking button */}
              {shipment && isAnyArray(shipment?.bookingTokens) && (
                <StyledShipmentNavigationButton>
                  {shipment.bookingTokens.length === 1 && (
                    <>
                      <StyledShipmentNavigationButtonText>
                        {t('actions.goToBooking')}
                      </StyledShipmentNavigationButtonText>
                      <Link
                        to={routeBooking(shipment.bookingTokens[0])}
                        onClick={() => track('Shipment / go to booking')}
                      >
                        <Button
                          icon='arrow_right'
                          rounded
                          testId={TEST_ID_SHIPMENT_SHOW_GO_TO_BOOKING}
                        />
                      </Link>
                    </>
                  )}
                  {shipment.bookingTokens.length > 1 && (
                    <>
                      <StyledShipmentNavigationButtonText>
                        {t('actions.goToBookings')}
                      </StyledShipmentNavigationButtonText>
                      <Link
                        to={routeBookings()}
                        onClick={() => {
                          dispatch(addBookingsPreloadFilters({ shipmentReference: shipment.ref }))
                          track('Shipment / go to bookings')
                        }}
                      >
                        <Button icon='arrow_right' rounded />
                      </Link>
                    </>
                  )}
                </StyledShipmentNavigationButton>
              )}
            </StyledShipmentNavigation>

            {/* Content */}
            <StyledShipmentContent>
              <ShipmentHeader />
              <StyledShipmentContentTabs
                as={Tabs}
                onTabChange={({ id: tabId }) => {
                  if (tabId === 'transportDocument') {
                    track('Shipment / transport documents')
                  } else if (tabId === 'information') {
                    track('Shipment / information')
                  }
                }}
              >
                <Tab id='information' label={t('shipments.information')} key='tab-information'>
                  <StyledShipmentInformationContent>
                    <ShipmentTransportation />
                    <ShipmentReferences />
                  </StyledShipmentInformationContent>
                </Tab>
                {userCan(ORDER_DOCUMENT_READ) && (
                  <Tab
                    id='transportDocument'
                    label={t('shipments.transportDocuments')}
                    key='tab-transport-document'
                  >
                    <ShipmentDocumentList />
                  </Tab>
                )}
              </StyledShipmentContentTabs>
            </StyledShipmentContent>
          </StyledShipmentWrap>
        </StyledShipmentMain>
      </StyledShipment>
    </ShipmentTokenContext.Provider>
  )
})

export default ShipmentView
