import React, { FC, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Input from 'components/input'
import FiltersSidebar from 'components/filters_sidebar'
import SearchSelect from 'components/search_select'

import useFilter from 'services/hooks/use_filter'

import { fetchOrganizationOptions } from 'views/select_options/slice'
import useUserCan from 'views/iam/hooks/use_user_can'

import { USER_READ } from 'constants/permissions'

interface SidebarProps {
  setSearch: React.Dispatch<React.SetStateAction<string>>
  setOrganizationIdFilter: React.Dispatch<React.SetStateAction<number | undefined>>
}

// TODO: REFACTOR
// This component uses shipment filter styles, therefore, it does not respect SRP.
// The filter styles should be isolated in a new component, and consumed by bookings, shipments, and users index.
const Index: FC<SidebarProps> = ({ setSearch, setOrganizationIdFilter }) => {
  const { t } = useTranslation()
  const userCan = useUserCan()

  const searchFilter = useFilter({ name: 'search', type: 'text' })
  const organizationIdFilter = useFilter({ name: 'organizationId', type: 'select' })

  const onClear = () => {
    searchFilter.reset()
    organizationIdFilter.reset()
    setSearch('')
    setOrganizationIdFilter(undefined)
  }

  const onApply = () => {
    setSearch(`${searchFilter.value}`)
    setOrganizationIdFilter(organizationIdFilter.value?.value as number)
  }

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onApply()
    }
  }

  return (
    <FiltersSidebar onApply={onApply} onClear={onClear}>
      <FiltersSidebar.Section title={t('users.sidebar.categories.identity')} defaultOpened>
        <Input
          type='text'
          placeholder={t('users.sidebar.search.label')}
          name={searchFilter.name}
          value={searchFilter.value}
          onChange={searchFilter.onChange}
          onKeyUp={onEnter}
        />
      </FiltersSidebar.Section>
      {userCan(USER_READ) && (
        <FiltersSidebar.Section title={t('users.sidebar.categories.organization')} defaultOpened>
          <SearchSelect
            action={fetchOrganizationOptions}
            placeholder={t('users.form.organization')}
            name={organizationIdFilter.name}
            value={organizationIdFilter.value}
            onChange={organizationIdFilter.onChange}
          />
        </FiltersSidebar.Section>
      )}
    </FiltersSidebar>
  )
}

export default Index
