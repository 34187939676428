import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ErrorNotification from 'core/components/notifications/error_notification'

import useBookingDocuments from 'views/booking/hooks/use_booking_documents'
import {
  deleteDocument as deleteDocumentRequest,
  fetchBookingDocuments,
  resetDocumentUploadProgresses,
  uploadDocument,
  selectDocumentUploadProgresses,
} from 'views/booking/slices/documents_slice'
import BlockDocuments from 'components/block_documents'
import BlockDocumentsItem from 'components/block_documents/block_documents_item'

import { addNotification } from 'views/notifications/slice'
import { toCamelCase } from 'services/helpers/values'
import { StyledBlockDocuments } from 'views/booking/components/show/style'
import Placeholder from 'components/placeholder'
import BlockDocumentsSkeleton from 'components/block_documents/skeleton'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import ModalUploadDocument from 'components/modal_upload_document'
import BookingContext from 'views/booking/contexts/booking_context'
import DocumentContext from 'views/documents/contexts/document_context'
import { getTestIdForBookingDocument } from 'tests/e2e/test_ids'

const BookingDocuments = () => {
  const dispatch = useDispatch()
  const { token } = useContext(BookingContext)
  const { t } = useTranslation()

  const uploadProgresses = useShallowSelector(selectDocumentUploadProgresses)

  const [documents, status] = useBookingDocuments()

  useEffect(() => {
    dispatch(fetchBookingDocuments(token)).unwrap()
  }, [dispatch, token])

  const deleteDocument =
    ({ id, fileName: name }) =>
    (e) => {
      e.preventDefault()
      dispatch(deleteDocumentRequest({ token, id }))
        .unwrap()
        .then(() => {
          dispatch(
            addNotification({
              type: 'success',
              title: t('documents.deletion.title'),
              text: t('documents.deletion.successMessage', { name }),
            })
          )
        })
        .catch(() => {
          dispatch(
            addNotification({
              type: 'alert',
              title: t('documents.deletion.title'),
              text: t('documents.deletion.errorMessage', { name }),
            })
          )
          dispatch(fetchBookingDocuments(token))
        })
    }

  return (
    <DocumentContext.Provider value={{ canUpload: true }}>
      <ModalUploadDocument
        onClear={() => dispatch(resetDocumentUploadProgresses())}
        onUpload={(data) => uploadDocument({ token, ...data, withEmailNotification: true })}
        uploadProgresses={uploadProgresses}
        asyncUpload
      />
      {status.fulfilled && (
        <StyledBlockDocuments as={BlockDocuments}>
          {documents.map((document, index) => {
            const { id: did, canDelete, fileName, documentType, url, createdAt } = document

            return (
              <BlockDocumentsItem
                key={`document-${did}`}
                name={fileName}
                type={t(`static.documentTypes.${toCamelCase(documentType)}`)}
                createdAt={createdAt}
                canDelete={canDelete}
                onDelete={deleteDocument(document)}
                link={url}
                testId={getTestIdForBookingDocument(index)}
                deleteButtonTestId={`document-${index}-delete-button-id`}
                downloadLinkTestId={`document-${index}-download-link-id`}
              />
            )
          })}
        </StyledBlockDocuments>
      )}
      {!status.ready && (
        <Placeholder ready={status.ready} customPlaceholder={<BlockDocumentsSkeleton />} />
      )}
      {status.rejected && <ErrorNotification />}
    </DocumentContext.Provider>
  )
}

export default BookingDocuments
