import styled, { css } from 'styled-components'

import { borderRadiusXSmall } from 'styles/utils/border_radius'
import { tagMedium } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'

import {
  Position,
  TooltipColorType,
  TooltipPlacementType,
  TooltipSizeType,
} from 'components/tooltip/types'

const ArrowSpacing = 8
const StartEndShift = 20

export const StyledArrow = styled.svg`
  position: absolute;
  stroke: none;
  width: 18px;
  height: 12px;
`

export const StyledContent = styled.div<{
  $placement: TooltipPlacementType
  $zIndex: number
  $visible: boolean
  $delayed: boolean
  $hasArrow: boolean
  $size: TooltipSizeType
  $color: TooltipColorType
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.05));
  padding: 15px;
  ${tagMedium}
  ${borderRadiusXSmall};
  z-index: ${(props) => props.$zIndex};

  ${(props) => {
    const orientation = props.$placement === 'right' || props.$placement === 'bottom' ? -1 : 1
    return (
      !props.$visible &&
      css`
        opacity: 0;
        pointer-events: none;
        transform: translate3d(
          ${props.$placement === 'left' || props.$placement === 'right' ? orientation * 20 : 0}px,
          ${['top', 'bottom', 'top-left'].includes(props.$placement) ? orientation * 20 : 0}px,
          0
        );
        transition: opacity 0.3s ${easeQuartOut}, transform 0s linear 0.3s;
      `
    )
  }}

  ${(props) =>
    props.$visible &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0, 0, 0) scale(1);
      transition: opacity 0.5s ${easeQuartOut}, transform 0.5s ${easeQuartOut};
    `}

  ${({ $delayed, $visible }) =>
    $visible &&
    $delayed &&
    css`
      transition-delay: 0.5s;
    `}

  ${(props) =>
    props.$hasArrow &&
    css`
      margin-bottom: ${['top', 'top-left'].includes(props.$placement)
        ? `${ArrowSpacing + 6}px`
        : 0};
      margin-top: ${props.$placement === 'bottom' ? `${ArrowSpacing + 6}px` : 0};
      margin-left: ${props.$placement === 'right' ? `${ArrowSpacing + 6}px` : 0};
      margin-right: ${props.$placement === 'left' ? `${ArrowSpacing + 6}px` : 0};

      ${StyledArrow} {
        display: block;
      }
    `}}

  ${(props) =>
    !props.$hasArrow &&
    css`
      margin-bottom: ${['top', 'top-left'].includes(props.$placement) ? '8px' : 0};
      margin-top: ${props.$placement === 'bottom' ? '8px' : 0};
      margin-left: ${props.$placement === 'right' ? '8px' : 0};
      margin-right: ${props.$placement === 'left' ? '8px' : 0};

      ${StyledArrow} {
        display: none;
      }
    `}

  ${(props) => {
    let arrowRotation = 0
    if (['top', 'top-left'].includes(props.$placement)) {
      arrowRotation = 0
    } else if (props.$placement === 'bottom') {
      arrowRotation = 180
    } else if (props.$placement === 'left') {
      arrowRotation = -90
    } else if (props.$placement === 'right') {
      arrowRotation = 90
    }

    return css`
      background-color: ${props.theme[props.$color] || props.$color};
      color: ${props.$color === 'white' ? props.theme.textDark : props.theme.white};

      ${StyledArrow} {
        ${() => {
          let top = 'auto'
          let bottom = 'auto'
          let left = 'auto'
          let right = 'auto'
          if (props.$placement === 'top') bottom = `${ArrowSpacing * -1 - 3}px`
          if (props.$placement === 'bottom') top = `${ArrowSpacing * -1 - 3}px`
          if (props.$placement === 'left') right = `${ArrowSpacing * -1 - 6}px`
          if (props.$placement === 'right') left = `${ArrowSpacing * -1 - 6}px`
          if (props.$placement === 'top-left') {
            bottom = `${ArrowSpacing * -1 - 3}px`
            right = `${ArrowSpacing * -1 + StartEndShift}px`
          }
          return css`
            top: ${top};
            bottom: ${bottom};
            left: ${left};
            right: ${right};
          `
        }}
        fill: ${props.theme[props.$color]};
        transform: rotate(${arrowRotation}deg);
      }
    `
  }}

  ${(props) =>
    props.$size === 'nowrap' &&
    css`
      white-space: nowrap;
    `}

  ${(props) =>
    props.$size === 'fit' &&
    css`
      white-space: initial;
    `}

  ${(props) =>
    props.$size === 'small' &&
    css`
      white-space: initial;
      max-width: 80px;
    `}

  ${(props) =>
    props.$size === 'medium' &&
    css`
      white-space: initial;
      max-width: 150px;
    `}

  ${(props) =>
    props.$size === 'large' &&
    css`
      white-space: initial;
      max-width: 260px;
    `}
`

export const StyledTooltip = styled.div<{
  $placement: TooltipPlacementType
  $position: Position
  $zIndex: number
}>`
  position: absolute;
  z-index: ${(props) => props.$zIndex};
  pointer-events: none;

  ${(props) => {
    let transform = ''

    if (props.$placement === 'top') {
      transform = 'translate(-50%, -100%)'
    }
    if (props.$placement === 'bottom') {
      transform = 'translate(-50%, 0px)'
    }
    if (props.$placement === 'left') {
      transform = 'translate(-100%, -50%)'
    }
    if (props.$placement === 'right') {
      transform = 'translate(0px, -50%)'
    }
    if (props.$placement === 'top-left') {
      transform = `translate(calc(-100% + ${StartEndShift}px), -100%)`
    }
    return css`
      left: ${props.$position.x}px;
      top: ${props.$position.y}px;
      transform: ${transform};
    `
  }}
`
export const StyledTooltipText = styled.div`
  max-width: 600px;
  text-overflow: ellipsis;
  overflow: hidden;
`
