import React, { FC } from 'react'

import BoxShadow from 'components/box_shadow'
import { StyledBlockTransportation } from 'components/block_transportation/style'

interface BlockTransportationProps {
  className?: string
  title: string
}

const BlockTransportation: FC<BlockTransportationProps> = ({ className, title, children }) => (
  <StyledBlockTransportation className={className} as={BoxShadow} title={title}>
    {children}
  </StyledBlockTransportation>
)

export default BlockTransportation
