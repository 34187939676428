import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import UsersPage from 'pages/users'

import useUserCan from 'views/iam/hooks/use_user_can'
import ShipmentPage from 'pages/shipment'
import { signInWithSsoJwt, updateUser } from 'views/iam/slices/iamSlice'
import { addNotification } from 'views/notifications/slice'
import BookingsPage from 'views/bookings'
import BookingDetailsPage from 'views/booking'
import BookingCreate from 'views/booking/components/create'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import BookingEdit from 'views/booking/components/edit'

import { CLASQUIN_ORGANISATION } from 'constants/global'
import { USER_ORGANIZATION_READ } from 'constants/permissions'

import { gradientLightOpacityReverse } from 'styles/utils/gradients'

import {
  routeShipments,
  routeShipment,
  routeBookings,
  routeBooking,
  routeBookingCreate,
  routeBookingEdit,
} from 'services/helpers/routes'
import RedirectWithParams from 'app/redirect_with_params'
import ShipmentsPage from 'pages/shipments'

const EXTERNAL_MESSAGE_ALLOWED_TYPES = {
  clasquinEmbedded: 'clasquin_embedded',
}

const isFromClasquin = (message) => {
  const {
    data: { type },
    origin,
  } = message
  const clasquinAllowedOrigins = process.env.REACT_APP_CLASQUIN_ALLOWED_ORIGINS || ''
  return (
    type === EXTERNAL_MESSAGE_ALLOWED_TYPES.clasquinEmbedded &&
    clasquinAllowedOrigins.split(',').some((allowedOrigin) => allowedOrigin === origin)
  )
}

const StyledLoader = styled.div`
  ${gradientLightOpacityReverse};
  height: 100vh;
`

const ClasquinApp = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const [applicationReady, setApplicationReady] = useState(false)
  const { hasAnyBookingRole, isShipper } = useBookingRole()
  const userCan = useUserCan()

  const signInForClasquin = useCallback(
    ({ token, lang, codes: teams }) => {
      dispatch(signInWithSsoJwt({ jwt: token, organization: CLASQUIN_ORGANISATION }))
        .unwrap()
        .then(({ id }) => {
          dispatch(updateUser({ id, changes: { teams } }))
        })
        .then(i18n.changeLanguage(lang))
        .then(() => setApplicationReady(true))
        .catch(() => {
          dispatch(
            addNotification({
              type: 'alert',
              title: i18n.t('errors.notification.title'),
              text: i18n.t('errors.notification.content'),
            })
          )
        })
    },
    // eslint-disable-next-line
    [dispatch, i18n, i18n.t]
  )

  const processMessage = useCallback(
    (message) => {
      if (isFromClasquin(message)) {
        signInForClasquin(message.data)
      }
    },
    [signInForClasquin]
  )

  const onMessage = useCallback(
    (message) => {
      const { type: messageType } = message.data
      if (Object.values(EXTERNAL_MESSAGE_ALLOWED_TYPES).includes(messageType)) {
        processMessage(message)
      }
    },
    [processMessage]
  )

  useEffect(() => {
    // eslint-disable-next-line
    window.addEventListener('message', onMessage)
  }, [onMessage])

  return (
    <>
      {!applicationReady && <StyledLoader />}
      {applicationReady && (
        <Routes>
          <Route
            path='/order/:id'
            element={<RedirectWithParams replace to={routeShipment(':id')} />}
          />

          <Route path='/orders' element={<RedirectWithParams replace to={routeShipments()} />} />

          <Route path={routeShipments()} element={<ShipmentsPage />} />

          <Route path={routeShipment(':id')} element={<ShipmentPage />} />

          {hasAnyBookingRole && <Route exact path={routeBookings()} element={<BookingsPage />} />}
          {/*
            Order is important here: /booking/create needs to be resolved _before_ /booking/:id
            If not, /create is interpreted as /:id
          */}
          {isShipper && <Route exact path={routeBookingCreate()} element={<BookingCreate />} />}
          {hasAnyBookingRole && (
            <Route exact path={routeBookingEdit(':id')} element={<BookingEdit />} />
          )}
          {hasAnyBookingRole && (
            <Route exact path={routeBooking(':id')} element={<BookingDetailsPage />} />
          )}
          {userCan(USER_ORGANIZATION_READ) && <Route exact path='/users' element={<UsersPage />} />}
          {/* WARN: default route should be the last one, because react-router reads routes from top to bottom. */}
          <Route element={<RedirectWithParams replace to='/shipments' />} />
        </Routes>
      )}
    </>
  )
}

export default ClasquinApp
