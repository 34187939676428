import styled from 'styled-components'

export const StyleModalTimelineLegsWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`

export const StyledModalFooterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StyledModalFooterButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
