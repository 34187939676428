import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import BookingList, { BookingListSkeleton } from 'components/booking_list'
import BookingListItem from 'components/booking_list_item'
import NotifMessage from 'components/notif_message'
import Placeholder from 'components/placeholder'

import useBookings from 'views/bookings/hooks/use_bookings'
import ErrorNotification from 'core/components/notifications/error_notification'
import FiltersContext from 'views/bookings/contexts/filters_context'

import { isEmptyArray } from 'services/helpers/values'

import { getTestIdForBookingItem } from 'tests/e2e/test_ids'
import {
  merchandiseHasAnyHazardousGoods,
  merchandiseHasAnyControlledTemperatures,
} from 'features/bookings/helpers/merchandises'

const BookingsList = () => {
  const [bookings, fetchingStatus] = useBookings()
  const { t } = useTranslation()
  const { page } = useContext(FiltersContext)

  const bookingsFetched = fetchingStatus.ready
  const hasBookings = bookings.length > 0
  const isLoadingMoreBookings = page !== 1 && !bookingsFetched

  const shouldDisplayBookings = (bookingsFetched && hasBookings) || isLoadingMoreBookings
  const shouldDisplayFullLoader = page === 1 && !bookingsFetched

  return (
    <>
      {shouldDisplayBookings && (
        <BookingList>
          {bookings.map(
            (
              {
                token,
                clientReference,
                clientBookingNumber,
                forwarderLogo,
                transportType,
                originPlannedTime,
                destinationPlannedTime,
                originAddress,
                destinationAddress,
                merchandise,
                status,
                lastUpdated,
                orderTokens,
              },
              index
            ) => (
              <BookingListItem
                key={`booking-list-item-${token}`}
                clientReference={clientReference}
                clientBookingNumber={clientBookingNumber}
                forwarderLogo={forwarderLogo}
                transportType={transportType}
                originName={originAddress.name}
                originCountryCode={originAddress.countryCode}
                destinationName={destinationAddress.name}
                destinationCountryCode={destinationAddress.countryCode}
                ptd={originPlannedTime}
                pta={destinationPlannedTime}
                merchandiseHasAnyHazardousGoods={merchandiseHasAnyHazardousGoods(merchandise)}
                merchandiseHasAnyControlledTemperatures={merchandiseHasAnyControlledTemperatures(
                  merchandise
                )}
                status={status}
                lastUpdated={lastUpdated}
                hasNewDocuments={false}
                hasActions={false}
                token={token}
                orderTokens={orderTokens}
                testId={`${getTestIdForBookingItem(index)}`}
              />
            )
          )}
          <Placeholder
            ready={bookingsFetched}
            customPlaceholder={<BookingListSkeleton count={3} isEmbedded />}
          />
        </BookingList>
      )}

      <>
        {shouldDisplayFullLoader && (
          <Placeholder
            ready={bookingsFetched}
            customPlaceholder={<BookingListSkeleton count={3} />}
          />
        )}
        {fetchingStatus.rejected && <ErrorNotification />}
        {fetchingStatus.fulfilled && isEmptyArray(bookings) && (
          <NotifMessage
            padded
            type='info'
            title={t(`bookings.alerts.noBookingResult.title`)}
            text=''
          />
        )}
      </>
    </>
  )
}

export default BookingsList
