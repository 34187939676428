import React, { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

// import useBreakpoints from 'services/hooks/use_breakpoints'

// import OrganizationLogo from 'components/organization_logo'
import Navigation from 'components/navigation'
import NavigationItem from 'components/navigation_item'
import Icon from 'components/icon'
import Avatar from 'components/avatar'
import Menu, { MenuItem } from 'components/menu'

// Styles come from header component. SharedHeader is a minimalist version of Header.
import {
  StyledHeader,
  StyledWrapper,
  StyledRightBlock,
  StyledButtonProfile,
  StyledLeftBlock,
  StyledNavigation,
  StyledButtonBurger,
  StyledButtonBurgerOpen,
  StyledButtonBurgerClose,
} from 'components/header/style'
import { routeSignIn } from 'services/helpers/routes'
import { HeaderVariant } from 'components/header/types'

interface SharedHeaderProps {
  variant?: HeaderVariant
}

const SharedHeader: FC<SharedHeaderProps> = React.memo(({ variant = 'light' }) => {
  // const { isMobile } = useBreakpoints()

  const leftBlockRef = useRef(null)
  const rightBlockRef = useRef(null)
  const wrapperRef = useRef(null)
  const [navOpen, setNavOpen] = useState(false)

  const variantReverse = useMemo(() => (variant === 'light' ? 'dark' : 'light'), [variant])

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <StyledHeader $variant={variant}>
      <StyledWrapper ref={wrapperRef}>
        <StyledLeftBlock as={Link} to='/' ref={leftBlockRef}>
          {/* <OrganizationLogo condensed={isMobile} /> */}
          <StyledButtonBurger $navOpen={navOpen} onClick={() => setNavOpen(!navOpen)}>
            <StyledButtonBurgerOpen as={Icon} name='menu' />
            <StyledButtonBurgerClose as={Icon} name='close' />
          </StyledButtonBurger>
        </StyledLeftBlock>
        <StyledNavigation as={Navigation} isOpenMobile={navOpen}>
          <NavigationItem
            active
            text={t('shipments.title')}
            to={routeSignIn()}
            variant={variantReverse}
          />
        </StyledNavigation>
        <StyledRightBlock ref={rightBlockRef}>
          <StyledButtonProfile>
            <Menu control={<Avatar firstName='w' lastName='a' />}>
              <MenuItem
                text={t('iam.signIn.title')}
                onClick={() => {
                  navigate(routeSignIn())
                }}
              />
            </Menu>
          </StyledButtonProfile>
        </StyledRightBlock>
      </StyledWrapper>
    </StyledHeader>
  )
})

export default SharedHeader
