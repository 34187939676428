import {
  STEP_TYPE_DELIVERY,
  STEP_TYPE_PICKUP,
  STEP_TYPE_POD,
  STEP_TYPE_POL,
} from 'constants/shipments'
import { Shipment } from 'features/shipments/types/shipment'

const nextStepMapping = {
  [STEP_TYPE_PICKUP]: STEP_TYPE_POL,
  [STEP_TYPE_POL]: STEP_TYPE_POD,
  [STEP_TYPE_POD]: STEP_TYPE_DELIVERY,
  [STEP_TYPE_DELIVERY]: null,
} as const

const findNextStepLocationName = (shipment: Shipment): string | undefined => {
  const nextStepType = nextStepMapping[shipment.summary.current.stepType]
  return nextStepType ? shipment.summary[nextStepType]?.location?.name : undefined
}

export default findNextStepLocationName
