import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Controller, useForm } from 'react-hook-form'

import TextList from 'components/text_list'
import TextListItem from 'components/text_list/text_list_item'
import { BookingReferences } from 'features/shipments/types/legacy_shipment'
import { Reference } from 'features/shipments/types/shipment'
import { capitalize, disableSnakeKeyForObject } from 'services/helpers/values'
import useUserCan from 'views/iam/hooks/use_user_can'
import {
  ORDER_UPDATE_BOOKING_NUMBER,
  ORDER_UPDATE_REF_FORWARDER,
  ORDER_UPDATE_BOOKING_REFERENCES,
  ORDER_UPDATE_CLIENT_REFERENCE,
  ORDER_UPDATE_SHIPMENT_REFERENCE,
} from 'constants/permissions'
import { References } from 'features/shipments/helpers/models'

import useShipment from 'features/shipments/hooks/use_shipment'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { updateReferences } from 'features/shipments/store/shipment_slice'
import Button from 'components/button'
import {
  TEST_ID_SHIPMENT_REFERENCES_BOOKING_NUMBER,
  TEST_ID_SHIPMENT_REFERENCES_CLIENT,
  TEST_ID_SHIPMENT_REFERENCES_REF_FORWARDER,
  TEST_ID_SHIPMENT_REFERENCES_SAVE,
  TEST_ID_SHIPMENT_REFERENCES_SHIPMENT,
} from 'tests/e2e/test_ids'
import { BookingCustomReference } from 'views/booking/slices/types'
import { addNotification } from 'views/notifications/slice'
import { StyledButtonsWrapper } from 'features/shipments/components/shipment_references/style'

export type ReferencesType = {
  client: Reference
  shipment: Reference
  forwarder: Reference
  clientBookingNumber: Reference
  bookingReferences: BookingReferences
}

const transformReferences = (bookingReferences: BookingCustomReference[]) =>
  bookingReferences.map((ref) => ({
    [`${ref.key}`]: ref.value,
  }))

const ReferenceBlock = ({
  isEditing,
  setIsEditing,
}: {
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const userCan = useUserCan()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useContext(ShipmentTokenContext)

  const [shipment] = useShipment({ id: id! })

  const references: ReferencesType = useMemo(() => new References(shipment).references, [shipment])
  const userCanEditClientBookingNumber = userCan(ORDER_UPDATE_BOOKING_NUMBER)
  const userCanEditRefForwarder = userCan(ORDER_UPDATE_REF_FORWARDER)
  const userCanEditBookingReferences = userCan(ORDER_UPDATE_BOOKING_REFERENCES)
  const userCanEditShipmentReference = userCan(ORDER_UPDATE_SHIPMENT_REFERENCE)
  const userCanEditClientReference = userCan(ORDER_UPDATE_CLIENT_REFERENCE)

  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      clientBookingNumber: references.clientBookingNumber,
      shipmentReference: references.shipment,
      refForwarder: references.forwarder,
      clientReference: references.client,
      bookingReferences: {
        shipperReferences: transformReferences(
          references?.bookingReferences?.shipperReferences || []
        ),
        forwarderReferences: transformReferences(
          references?.bookingReferences?.forwarderReferences || []
        ),
      },
    },
  })

  const saveNewReferences = () => {
    const values = getValues()

    const { shipperReferences, forwarderReferences } = values.bookingReferences

    const payload = {
      id: id!,
      ...(dirtyFields.clientBookingNumber && { clientBookingNumber: values.clientBookingNumber }),
      ...(dirtyFields.refForwarder && { refForwarder: values.refForwarder }),
      ...(dirtyFields.clientReference && { clientReference: values.clientReference }),
      ...(dirtyFields.shipmentReference && { shipmentReference: values.shipmentReference }),
      ...((dirtyFields.bookingReferences?.forwarderReferences ||
        dirtyFields.bookingReferences?.shipperReferences) && {
        bookingReferences: {
          shipperReferences: shipperReferences.map((ref) => disableSnakeKeyForObject(ref)),
          forwarderReferences: forwarderReferences.map((ref) => disableSnakeKeyForObject(ref)),
        },
      }),
    }

    dispatch(updateReferences(payload))
      .unwrap()
      .then(() =>
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.notifications.shipmentUpdated.title'),
            text: t('shipments.notifications.shipmentUpdated.content'),
          })
        )
      )
      .catch(() =>
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      )
  }

  return (
    <>
      <TextList>
        <Controller
          control={control}
          name='clientReference'
          render={({ field: { onChange, value } }) => (
            <TextListItem
              title={t('shipments.references.client')}
              text={references.client}
              key='text-list-item-client-reference'
              isEditing={userCanEditClientReference && isEditing}
              onChange={(e) => {
                onChange(e.target.value)
                setValue('clientReference', e.target.value)
              }}
              value={value}
              testId={TEST_ID_SHIPMENT_REFERENCES_CLIENT}
            />
          )}
        />

        <Controller
          control={control}
          name='shipmentReference'
          render={({ field: { onChange, value } }) => (
            <TextListItem
              title={t('shipments.references.shipment')}
              text={references.shipment}
              key='text-list-item-shipment-reference'
              value={value}
              isEditing={userCanEditShipmentReference && isEditing}
              onChange={(e) => {
                onChange(e.target.value)
                setValue('shipmentReference', e.target.value)
              }}
              testId={TEST_ID_SHIPMENT_REFERENCES_SHIPMENT}
            />
          )}
        />

        <Controller
          control={control}
          name='refForwarder'
          render={({ field: { onChange, value } }) => (
            <TextListItem
              title={t('shipments.references.forwarder')}
              text={references.forwarder}
              key='text-list-item-forwarder-reference'
              isEditing={userCanEditRefForwarder && isEditing}
              onChange={(e) => {
                onChange(e.target.value)
                setValue('refForwarder', e.target.value)
              }}
              value={value}
              testId={TEST_ID_SHIPMENT_REFERENCES_REF_FORWARDER}
            />
          )}
        />

        <Controller
          control={control}
          name='clientBookingNumber'
          render={({ field: { onChange, value } }) => (
            <TextListItem
              title={t('shipments.references.clientBookingNumber')}
              text={references.clientBookingNumber}
              key='text-list-item-client-booking-number'
              isEditing={userCanEditClientBookingNumber && isEditing}
              onChange={(e) => {
                onChange(e.target.value)
                setValue('clientBookingNumber', e.target.value)
              }}
              value={value}
              testId={TEST_ID_SHIPMENT_REFERENCES_BOOKING_NUMBER}
            />
          )}
        />

        {references.bookingReferences?.shipperReferences?.map((reference, index) => (
          <Controller
            control={control}
            name={`bookingReferences.shipperReferences.${index}.${reference.key}`}
            render={({ field: { onChange, value } }) => (
              <TextListItem
                title={capitalize(reference.key)}
                text={reference.value}
                testId={`test-id-shipment-booking-references-shipper-references-${index}`}
                isEditing={userCanEditBookingReferences && isEditing}
                key={`text-list-item-booking-shipper-reference-${reference.key}`}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                  setValue(
                    `bookingReferences.shipperReferences.${index}.${reference.key}`,
                    e.target.value
                  )
                }}
              />
            )}
          />
        ))}

        {references.bookingReferences?.forwarderReferences?.map((reference, index) => (
          <Controller
            control={control}
            name={`bookingReferences.forwarderReferences.${index}.${reference.key}`}
            render={({ field: { value, onChange } }) => (
              <TextListItem
                title={capitalize(reference.key)}
                text={reference.value}
                isEditing={userCanEditBookingReferences && isEditing}
                key={`text-list-item-booking-forwarder-reference-${reference.key}`}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                  setValue(
                    `bookingReferences.forwarderReferences.${index}.${reference.key}`,
                    e.target.value
                  )
                }}
              />
            )}
          />
        ))}
      </TextList>
      {isEditing && (
        <StyledButtonsWrapper>
          <Button type='button' text={t('actions.cancel')} onClick={() => setIsEditing(false)} />
          <Button
            testId={TEST_ID_SHIPMENT_REFERENCES_SAVE}
            type='button'
            text={t('actions.save')}
            onClick={() => {
              saveNewReferences()
              setIsEditing(false)
            }}
            variant='highlight'
          />
        </StyledButtonsWrapper>
      )}
    </>
  )
}

export default ReferenceBlock
