import React from 'react'

import { StyledSignIn, StyledSignInForm } from 'views/iam/style'

const IAm: React.FC = ({ children }) => (
  <StyledSignIn>
    <StyledSignInForm>{children}</StyledSignInForm>
  </StyledSignIn>
)

export default IAm
