import { useSelector } from 'react-redux'

import useStatus, { UseStatus } from 'services/api/hooks/use_status'
import { RootState } from 'services/store/store'

import { selectAlerts, selectAlertsStatus } from 'features/shipments/store/shipment_slice'
import { Alert } from 'features/shipments/types/legacy_shipment'

export type UseAlertsResponse = [Alert[], UseStatus]

const useAlerts = ({ id }: { id: string }): UseAlertsResponse => {
  const alerts = useSelector(selectAlerts({ id }))
  const status = useStatus(useSelector((state: RootState) => selectAlertsStatus(state, id)))
  return [alerts, status]
}

export default useAlerts
