import styled from 'styled-components'

import { StyledDatePickerLabel } from 'components/input_datepicker/style'

const StyledMilestoneDatepickerLabel = styled(StyledDatePickerLabel)`
  display: flex;
  span {
    color: ${({ theme }) => theme.primary};
  }
  gap: 5px;
  align-items: flex-end;
`

export default StyledMilestoneDatepickerLabel
