import styled, { css } from 'styled-components'

import StyledIcon from 'components/icon/style'

export const StyledSpan = styled.span`
  font-family: 'Sora';
  font-weight: 700;
  font-style: normal;
  color: ${({ theme }) => theme.darkBlue};
`

export const StyledWrapper = styled.div<{ $condensed: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.$condensed &&
    css`
      ${StyledSpan} {
        display: none;
      }
    `}

  ${StyledIcon} {
    width: 37px;
    height: 37px;
  }
  ${StyledSpan} {
    margin-left: 10px;
    font-size: 30px;
    line-height: 30px;
  }
`
export const StyledImage = styled.img<{ $condensed: boolean; $full: boolean }>`
  max-width: ${(props) => {
    if (props.$condensed) return '40px'
    if (props.$full) return '300px'
    return '150px'
  }};
  width: auto;
  max-height: ${(props) => (props.$condensed ? '40px' : '50px')};
  height: auto;
`
