import React, { FC, useContext, useMemo } from 'react'

import {
  SHIPMENT_VIEW_CONDENSED,
  SHIPMENT_VIEW_LARGE,
  SHIPMENT_VIEW_MODAL,
} from 'constants/shipments'
import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'

import {
  StyledCondensedData,
  StyledCondensedEta,
  StyledCondensedFavorite,
  StyledCondensedMeta,
  StyledCondensedRefs,
  StyledCondensedRightBlock,
  StyledCondensedTimeline,
  StyledOngoingAlertAndDelay,
  StyledShipmentListItemHead,
  StyledShipmentListItemLeft,
  StyledShipmentListItemLeftContent,
  StyledShipmentListItemMeta,
  StyledShipmentListItemRight,
  StyledShipmentListItemTimeline,
  StyledShipmentListItemTitle,
} from 'features/shipments/components/shipment_list_item/style'

import TransportMode from 'features/shipments/components/shipment_list_item/components/transport_mode'
import Co2e from 'features/shipments/components/shipment_list_item/components/co2e'
import OngoingAlert from 'features/shipments/components/shipment_list_item/components/ongoing_alert'
import Delay from 'features/shipments/components/shipment_list_item/components/delay'
import References from 'features/shipments/components/shipment_list_item/components/references'
import Parties from 'features/shipments/components/shipment_list_item/components/parties'
import Favorite from 'features/shipments/components/shipment_list_item/components/favorite'
import FromTo from 'features/shipments/components/shipment_list_item/components/from_to'
import Summary from 'features/shipments/components/shipment_list_item/components/summary/summary'
import Eta from 'features/shipments/components/shipment_list_item/components/eta'
import Card from 'features/shipments/components/shipment_list_item/components/card'
import ImportDate from 'features/shipments/components/shipment_list_item/components/import_date'
import Organization from 'features/shipments/components/shipment_list_item/components/organization'
import CondensedViewAttributes from 'features/shipments/components/shipment_list_item/components/condensed_view_attributes'

import ShipmentTokenContext from 'features/shipments/contexts/shipment_context'

import type { Shipment } from 'features/shipments/types/shipment'

interface ShipmentItemProps {
  testId?: string
}

const ModalItem: FC<ShipmentItemProps> = ({ testId }) => (
  <Card testId={testId}>
    <TransportMode />
    <StyledCondensedData>
      <StyledCondensedRefs>
        <References />
      </StyledCondensedRefs>
      <FromTo />
    </StyledCondensedData>
    <StyledCondensedRightBlock>
      <StyledCondensedTimeline>
        <Summary />
      </StyledCondensedTimeline>
      <StyledCondensedEta>
        <Eta />
      </StyledCondensedEta>
      <StyledCondensedMeta>
        <StyledOngoingAlertAndDelay>
          <OngoingAlert />
          <Delay />
        </StyledOngoingAlertAndDelay>
        <Co2e />
      </StyledCondensedMeta>
      <StyledCondensedFavorite>
        <Favorite />
      </StyledCondensedFavorite>
    </StyledCondensedRightBlock>
  </Card>
)

const CondensedItem: FC<ShipmentItemProps> = ({ testId }) => (
  <Card testId={testId}>
    <TransportMode />
    <StyledCondensedData>
      <StyledCondensedRefs>
        <CondensedViewAttributes />
      </StyledCondensedRefs>
      <StyledCondensedRefs>
        <Parties />
      </StyledCondensedRefs>
      <FromTo />
    </StyledCondensedData>
    <StyledCondensedRightBlock>
      <StyledCondensedTimeline>
        <Summary />
      </StyledCondensedTimeline>
      <StyledCondensedEta>
        <Eta />
      </StyledCondensedEta>
      <StyledCondensedMeta>
        <StyledOngoingAlertAndDelay>
          <OngoingAlert />
          <Delay />
        </StyledOngoingAlertAndDelay>
        <Co2e />
      </StyledCondensedMeta>
      <StyledCondensedFavorite>
        <Favorite />
      </StyledCondensedFavorite>
    </StyledCondensedRightBlock>
  </Card>
)

const DefaultItem: FC<ShipmentItemProps> = ({ testId }) => (
  <Card testId={testId}>
    <StyledShipmentListItemHead>
      <StyledShipmentListItemLeft>
        <TransportMode />
        <StyledShipmentListItemLeftContent>
          <StyledShipmentListItemTitle>
            <References />
            <Organization />
            <Parties />
          </StyledShipmentListItemTitle>
        </StyledShipmentListItemLeftContent>
      </StyledShipmentListItemLeft>
      <StyledShipmentListItemRight>
        <ImportDate />
        <Favorite />
      </StyledShipmentListItemRight>
    </StyledShipmentListItemHead>
    <StyledShipmentListItemTimeline>
      <Summary />
      <StyledShipmentListItemMeta>
        <StyledOngoingAlertAndDelay>
          <OngoingAlert />
          <Delay />
        </StyledOngoingAlertAndDelay>
        <br />
        <Co2e />
      </StyledShipmentListItemMeta>
    </StyledShipmentListItemTimeline>
  </Card>
)

export interface ShipmentListItemProps {
  shipment: Shipment
  testId?: string
}

const ShipmentListItem: FC<ShipmentListItemProps> = ({ shipment, testId }) => {
  const { view } = useContext(ShipmentViewContext)
  const shipmentContextValue = useMemo(() => ({ shipment }), [shipment])

  return (
    <ShipmentTokenContext.Provider value={shipmentContextValue}>
      {view === SHIPMENT_VIEW_LARGE && <DefaultItem testId={testId} />}
      {view === SHIPMENT_VIEW_CONDENSED && <CondensedItem testId={testId} />}
      {view === SHIPMENT_VIEW_MODAL && <ModalItem testId={testId} />}
    </ShipmentTokenContext.Provider>
  )
}

export default ShipmentListItem
