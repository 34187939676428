import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useTheme } from 'styled-components'

import {
  StyledFormEditGroupLabel,
  StyledFormEditGroupValue,
  StyledFormEditGroupItem,
  StyledMerchandiseItem,
  StyledMerchandiseProductDetails,
  StyleMerchandiseItemIcon,
  StyledMerchandiseTotalContentLabel,
  StyledFormEditGroup,
  StyledFormEditGroupItems,
  StyledFormEditGroupTitle,
  StyledMerchandisePackageDetails,
  StyledMerchandiseItemGroup,
  StyledMerchandiseContentInfoContainer,
  StyledMerchandiseContentIconsContainer,
} from 'views/booking/components/show/style'
import { AnyBookingMerchandise, BookingMerchandiseTotalPackage } from 'views/booking/slices/types'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import Table from 'components/table'
import useMerchandiseInformationDisplay from 'views/booking/hooks/use_merchandise_information_display'
import { getTestIdBookingShowMerchandiseContent } from 'tests/e2e/test_ids'
import Icon from 'components/icon'
import Button from 'components/button'
import {
  merchandiseHasAnyControlledTemperatures,
  merchandiseHasAnyHazardousGoods,
} from 'features/bookings/helpers/merchandises'

const InformationMerchandisePackageTotal = ({
  merchandise,
}: {
  merchandise: AnyBookingMerchandise
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { hazardousGoodsDisplay, temperatureDisplay, commercialValueDisplay, emptyDisplay } =
    useMerchandiseInformationDisplay()

  const {
    content: merchandiseContent,
    shippingMode,
    volume,
    weight,
    packageNumber,
  } = merchandise as BookingMerchandiseTotalPackage

  const [isMerchandiseContentExpanded, setMerchandiseContentExpansion] = useState<boolean>()

  return (
    <>
      <StyledFormEditGroup>
        <StyledFormEditGroupTitle>{t('bookings.merchandise.title')}</StyledFormEditGroupTitle>
        <StyledFormEditGroupItems>
          <StyledFormEditGroupItem>
            <StyledFormEditGroupLabel>
              {t('bookings.merchandise.shippingMode')}
            </StyledFormEditGroupLabel>
            <StyledFormEditGroupValue>
              <ValueWithFallback value={shippingMode} />
            </StyledFormEditGroupValue>
          </StyledFormEditGroupItem>
          <StyledFormEditGroupItem>
            <StyledFormEditGroupLabel>
              {t('bookings.merchandise.package(s)')}
            </StyledFormEditGroupLabel>
            <div>
              <StyledMerchandisePackageDetails>
                <StyledMerchandiseItem>
                  <StyleMerchandiseItemIcon
                    fill={theme.mediumGray}
                    width={24}
                    height={24}
                    name='package'
                  />
                  <StyledFormEditGroupValue
                    data-testid={getTestIdBookingShowMerchandiseContent(0, 'summary')}
                  >
                    {`${t('undefined')} x ${packageNumber ?? 1}`}
                  </StyledFormEditGroupValue>
                </StyledMerchandiseItem>
                <StyledMerchandiseItem>
                  <StyleMerchandiseItemIcon
                    fill={theme.mediumGray}
                    width={24}
                    height={24}
                    name='weight'
                  />
                  <StyledFormEditGroupValue
                    data-testid={getTestIdBookingShowMerchandiseContent(1, 'summary')}
                  >
                    {`${weight.value} ${weight.unit} | ${volume.value} ${volume.unit}`}
                  </StyledFormEditGroupValue>
                </StyledMerchandiseItem>
              </StyledMerchandisePackageDetails>
            </div>
          </StyledFormEditGroupItem>
          <StyledFormEditGroupItem>
            <StyledMerchandiseTotalContentLabel>
              {t('bookings.merchandise.content')}
            </StyledMerchandiseTotalContentLabel>
            <StyledMerchandiseItemGroup>
              <StyledMerchandiseContentInfoContainer />
              <StyledMerchandiseContentIconsContainer>
                <Icon
                  testId={getTestIdBookingShowMerchandiseContent(0, 'controlledTemperaturesIcon')}
                  width={24}
                  height={18}
                  fill={
                    merchandiseHasAnyControlledTemperatures(merchandise)
                      ? theme.warn
                      : theme.lightGray
                  }
                  name='temperature'
                />
                <Icon
                  testId={getTestIdBookingShowMerchandiseContent(0, 'hazardousGoodsIcon')}
                  width={24}
                  height={24}
                  fill={merchandiseHasAnyHazardousGoods(merchandise) ? theme.warn : theme.lightGray}
                  name='fire'
                />
                <Button
                  variant='icon'
                  icon={isMerchandiseContentExpanded ? 'arrow_top' : 'arrow_bottom'}
                  onClick={() => setMerchandiseContentExpansion(!isMerchandiseContentExpanded)}
                />
              </StyledMerchandiseContentIconsContainer>
            </StyledMerchandiseItemGroup>
          </StyledFormEditGroupItem>
        </StyledFormEditGroupItems>
      </StyledFormEditGroup>
      {isMerchandiseContentExpanded && (
        <StyledMerchandiseProductDetails>
          <Table>
            <Table.Head>
              <Table.HeadCell>{t('bookings.merchandise.product.description')}</Table.HeadCell>
              <Table.HeadCell>{t('bookings.merchandise.product.value')}</Table.HeadCell>
              <Table.HeadCell>
                {t('bookings.merchandise.product.temperatures.short')}
              </Table.HeadCell>
              <Table.HeadCell>
                {t('bookings.merchandise.product.hazardousGoods.title')}
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {merchandiseContent.map((m) => (
                <Table.Row>
                  <Table.Cell>{m.productDescription ?? emptyDisplay}</Table.Cell>
                  <Table.Cell>{commercialValueDisplay(m.commercialValue)}</Table.Cell>
                  <Table.Cell>
                    <div>{temperatureDisplay(m.controlledTemperatures?.min, 'Min.')}</div>
                    <div>{temperatureDisplay(m.controlledTemperatures?.max, 'Max.')}</div>
                  </Table.Cell>
                  <Table.Cell>{hazardousGoodsDisplay(m.hazardousGoods)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </StyledMerchandiseProductDetails>
      )}
    </>
  )
}

export default InformationMerchandisePackageTotal
