import React, { useContext, ChangeEvent, useState, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useNormalizedCoordinates from 'components/map/hooks/use_normalized_coordinates'

import Modal from 'components/modal'

import useModal from 'components/modal/hooks/use_modal'
import Button from 'components/button'

import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'

import useAppDispatch from 'services/hooks/use_app_dispatch'

import {
  StyledBeautifyButton,
  StyledButtonContainer,
  StyledCodeBlock,
  StyledCodeBlockContainer,
  StyledErrorMessage,
  StyledMapContainer,
  StyledPathModalContainer,
  StyledTextArea,
} from 'components/modal_path/style'

import {
  editShipmentPath,
  selectIsShipmentActive,
  selectPath,
  selectShipmentColor,
  fetchShipment,
} from 'features/shipments/store/shipment_slice'
import { ShipmentPath } from 'features/shipments/types/legacy_shipment'
import checkPath, {
  PathValidationErrors,
  transformShipmentPathToEndpointParams,
} from 'components/modal_path/helpers'
import useMap from 'components/map/hooks/use_map'
import useCoordinates from 'components/map/hooks/use_coordinates'

import useShipmentColor from 'features/shipments/hooks/use_shipment_color'
import {
  TEST_ID_SHIPMENT_EDIT_PATH_ERROR,
  TEST_ID_SHIPMENT_EDIT_PATH_INPUT,
  TEST_ID_SHIPMENT_EDIT_PATH_SAVE_BUTTON,
} from 'tests/e2e/test_ids'
import { addNotification } from 'views/notifications/slice'

function JSONPathEditor<JsonType>({
  json,
  onError,
  validate,
}: {
  json: JsonType
  onError: (error: string) => void
  validate: (input: string) => void
}) {
  const [jsonString, setJsonString] = useState<string>(() => JSON.stringify(json, null, 2))
  const [valid, setValid] = useState(true)

  const { t } = useTranslation()

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const jsonInput = event.target.value
    setJsonString(jsonInput)
    try {
      JSON.parse(jsonInput)
      validate(jsonInput)
      onError('')
      setValid(true)
    } catch (error) {
      onError(String(error))
      setValid(false)
    }
  }

  return (
    <StyledCodeBlockContainer>
      <StyledCodeBlock>
        <StyledTextArea
          value={jsonString}
          onChange={onChange}
          data-testid={TEST_ID_SHIPMENT_EDIT_PATH_INPUT}
        />
      </StyledCodeBlock>
      <StyledButtonContainer>
        <StyledBeautifyButton
          disabled={!valid}
          text={t('actions.beautify')}
          variant='transparant'
          onClick={() => {
            setJsonString(JSON.stringify(json, null, 2))
            setValid(true)
          }}
        />
      </StyledButtonContainer>
    </StyledCodeBlockContainer>
  )
}

const EditPathMap = ({ id, path }: { id: string; path: ShipmentPath }) => {
  const {
    normalizedPathCoordinates,
    normalizedStepsCoordinates,
    pathAsPathModel,
    stepsAsStepsModel,
  } = useNormalizedCoordinates(id, path)
  const { bear } = useCoordinates()
  const { getPointerColorFromShipmentColor } = useShipmentColor()

  const shipmentColorIndex = useSelector(selectShipmentColor({ id: id! }))
  const shipmentColor = getPointerColorFromShipmentColor(shipmentColorIndex)

  const { Map, mapProps, addMarker, addPath, addPointer, loaded } = useMap({
    bounds: [...normalizedPathCoordinates, ...normalizedStepsCoordinates],
    onVehicleClick: () => undefined,
    onClusterClick: () => undefined,
    displayToggleFullscreen: false,
  })

  const isShipmentActive = useSelector(selectIsShipmentActive({ id }))

  useEffect(() => {
    addPath({ path: pathAsPathModel, coordinates: normalizedPathCoordinates })
    const lastCoordinate = pathAsPathModel[pathAsPathModel.length - 1]
    if (lastCoordinate && isShipmentActive) {
      addPointer({
        bearing: bear(normalizedPathCoordinates),
        // @ts-expect-error we should investigate this type error for clarity but it works fine
        coordinate: lastCoordinate.coordinate,
        color: shipmentColor,
        tooltip: lastCoordinate.timestamp,
        isPathExtremity: true,
      })
    }

    stepsAsStepsModel.forEach(({ type, coordinate }) => {
      addMarker({
        type,
        coordinate,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, path])

  return (
    <StyledMapContainer>
      <Map {...mapProps} />
    </StyledMapContainer>
  )
}

const ModalPath = ({ onClose }: { onClose?: () => void }) => {
  const [jsonError, setJsonError] = useState('')
  const [path, setPath] = useState<ShipmentPath>([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { setOpen } = useModal('editPath')
  const { id } = useContext(ShipmentTokenContext)

  // to prevent excessive re-rendering
  const pathFromReduxState = useSelector(useMemo(() => selectPath({ id: id! }), [id]))

  useEffect(() => {
    setPath(pathFromReduxState)
  }, [pathFromReduxState])

  const onAccept = () => {
    setIsLoading(true)
    const formattedPath = transformShipmentPathToEndpointParams(path)
    dispatch(editShipmentPath({ shipmentId: id!, shipmentPath: formattedPath }))
      .unwrap()
      .then(() => {
        addNotification({
          type: 'success',
          title: t('shipments.editPath.successTitle'),
          text: t('shipments.editPath.success'),
        })
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('shipments.editPath.errorTitle'),
            text: t('shipments.editPath.error'),
          })
        )
      })
      .finally(() => {
        setIsLoading(false)
        dispatch(fetchShipment({ id: id! }))
        setOpen(false)
      })
  }

  const onError = (error: string) => {
    setJsonError(error)
  }

  const validateJson = (input: string) => {
    const parsedAsJson = JSON.parse(input) as ShipmentPath
    switch (checkPath(parsedAsJson)) {
      case PathValidationErrors.INVALID_PATH_FORMAT:
        throw new Error(t('shipments.editPath.errorParsing'))
      case PathValidationErrors.INVALID_COORDINATE_VALUE:
        throw new Error(t('shipments.editPath.wrongCoordinateFormat'))
      default:
        setPath(parsedAsJson)
    }
  }

  return (
    <Modal
      modalName='editPath'
      onClose={onClose}
      shouldFocusAfterRender={false}
      height='full'
      width='full'
    >
      <Modal.Header> {t('shipments.editPath.title')}</Modal.Header>
      <Modal.Content>
        <StyledPathModalContainer>
          <EditPathMap id={id!} path={path} />
          <JSONPathEditor json={path} onError={onError} validate={validateJson} />
        </StyledPathModalContainer>
        <StyledErrorMessage data-testid={TEST_ID_SHIPMENT_EDIT_PATH_ERROR}>
          {jsonError}
        </StyledErrorMessage>
      </Modal.Content>
      <Modal.Footer>
        <Button onClick={() => setOpen(false)} variant='clear' text={t('actions.cancel')} />
        <Button
          variant='highlight'
          text={t('actions.save')}
          onClick={onAccept}
          disabled={!!jsonError}
          testId={TEST_ID_SHIPMENT_EDIT_PATH_SAVE_BUTTON}
          isLoading={isLoading}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalPath
