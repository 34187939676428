import styled from 'styled-components'

import { text14Regular } from 'styles/utils/texts'

const StyledQuickInfoLabel = styled.div`
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 10px;
`
export default StyledQuickInfoLabel
