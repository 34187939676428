import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'

import { addUser, signIn, updateUser } from 'views/iam/slices/iamSlice'

import { updateImagesUrl, updateTheme } from 'features/white_label/store/slice'

export const whiteLabelListener = createListenerMiddleware()

whiteLabelListener.startListening({
  matcher: isAnyOf(addUser, updateUser, signIn.fulfilled),
  effect: async (action, listenerApi) => {
    const user = action.payload
    const {
      organization: { theme, imagesUrl },
    } = user

    if (theme) {
      listenerApi.dispatch(updateTheme(theme))
    }

    if (imagesUrl) {
      listenerApi.dispatch(updateImagesUrl(imagesUrl))
    }
  },
})

export default whiteLabelListener.middleware
