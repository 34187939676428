import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useTheme } from 'styled-components'

import {
  StyledFormEditGroupLabel,
  StyledFormEditGroupValue,
  StyledFormEditGroupItem,
  StyledMerchandiseItem,
  StyledMerchandiseProductDetails,
  StyleMerchandiseItemIcon,
  StyledMerchandiseTotalContentLabel,
  StyledFormEditGroup,
  StyledFormEditGroupItems,
  StyledFormEditGroupTitle,
  StyledMerchandisePackageDetails,
  StyledMerchandiseItemGroup,
  StyledMerchandiseContentInfoContainer,
  StyledMerchandiseContentIconsContainer,
} from 'views/booking/components/show/style'
import {
  AnyBookingMerchandise,
  BookingMerchandisePackage,
  BookingMerchandisePackageContent,
} from 'views/booking/slices/types'

import {
  merchandiseHasAnyControlledTemperatures,
  merchandiseHasAnyHazardousGoods,
} from 'features/bookings/helpers/merchandises'

import useStaticLocales from 'views/locales/hooks/use_static_locales'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import Table from 'components/table'
import useMerchandiseInformationDisplay from 'views/booking/hooks/use_merchandise_information_display'

import {
  getTestIdBookingShowMerchandiseContent,
  TEST_ID_BOOKING_SHOW_SHIPPING_MODE,
} from 'tests/e2e/test_ids'
import Icon from 'components/icon'
import Button from 'components/button'

const InformationMerchandisePackageLegacy = ({
  merchandise,
}: {
  merchandise: AnyBookingMerchandise
}) => {
  const { s } = useStaticLocales()
  const theme = useTheme()
  const { t } = useTranslation()

  const { hazardousGoodsDisplay, temperatureDisplay, commercialValueDisplay, emptyDisplay } =
    useMerchandiseInformationDisplay()

  const { content: merchandiseContent, shippingMode } = merchandise as BookingMerchandisePackage

  const [isMerchandiseContentExpanded, setMerchandiseContentExpansion] = useState<boolean>()

  return (
    <>
      <StyledFormEditGroup>
        <StyledFormEditGroupTitle>{t('bookings.merchandise.title')}</StyledFormEditGroupTitle>
        <StyledFormEditGroupItems>
          <StyledFormEditGroupItem>
            <StyledFormEditGroupLabel>
              {t('bookings.merchandise.shippingMode')}
            </StyledFormEditGroupLabel>
            <StyledFormEditGroupValue data-testid={TEST_ID_BOOKING_SHOW_SHIPPING_MODE}>
              <ValueWithFallback value={shippingMode} />
            </StyledFormEditGroupValue>
          </StyledFormEditGroupItem>
          <StyledFormEditGroupItem>
            <StyledFormEditGroupLabel>
              {t('bookings.merchandise.package(s)')}
            </StyledFormEditGroupLabel>
            <div>
              {merchandiseContent?.map((p: BookingMerchandisePackageContent, index) => (
                <StyledMerchandisePackageDetails>
                  <StyledMerchandiseItem>
                    <StyleMerchandiseItemIcon
                      fill={theme.mediumGray}
                      width={24}
                      height={24}
                      name='package'
                    />
                    <StyledFormEditGroupValue>
                      {`${s('packageTypes')[p.packageType]} x ${p.quantity}`}
                    </StyledFormEditGroupValue>
                  </StyledMerchandiseItem>
                  <StyledMerchandiseItem>
                    <StyleMerchandiseItemIcon
                      fill={theme.mediumGray}
                      width={24}
                      height={24}
                      name='weight'
                    />
                    <StyledFormEditGroupValue
                      data-testid={getTestIdBookingShowMerchandiseContent(index, 'summary')}
                    >
                      {`${p.weight.value} ${p.weight.unit} | ${p.length.value} ${p.length.unit} | ${p.width.value} ${p.width.unit} | ${p.height.value} ${p.height.unit}`}
                    </StyledFormEditGroupValue>
                  </StyledMerchandiseItem>
                </StyledMerchandisePackageDetails>
              ))}
            </div>
          </StyledFormEditGroupItem>
          <StyledFormEditGroupItem>
            <StyledMerchandiseTotalContentLabel>
              {t('bookings.merchandise.content')}
            </StyledMerchandiseTotalContentLabel>
            <StyledMerchandiseItemGroup>
              <StyledMerchandiseContentInfoContainer />
              <StyledMerchandiseContentIconsContainer>
                <Icon
                  testId={getTestIdBookingShowMerchandiseContent(0, 'controlledTemperaturesIcon')}
                  width={24}
                  height={18}
                  fill={
                    merchandiseHasAnyControlledTemperatures(merchandise)
                      ? theme.warn
                      : theme.lightGray
                  }
                  name='temperature'
                />
                <Icon
                  testId={getTestIdBookingShowMerchandiseContent(0, 'hazardousGoodsIcon')}
                  width={24}
                  height={24}
                  fill={merchandiseHasAnyHazardousGoods(merchandise) ? theme.warn : theme.lightGray}
                  name='fire'
                />
                <Button
                  variant='icon'
                  icon={isMerchandiseContentExpanded ? 'arrow_top' : 'arrow_bottom'}
                  onClick={() => setMerchandiseContentExpansion(!isMerchandiseContentExpanded)}
                />
              </StyledMerchandiseContentIconsContainer>
            </StyledMerchandiseItemGroup>
          </StyledFormEditGroupItem>
        </StyledFormEditGroupItems>
      </StyledFormEditGroup>
      {isMerchandiseContentExpanded && (
        <StyledMerchandiseProductDetails>
          <Table>
            <Table.Head>
              <Table.HeadCell>{t('bookings.merchandise.product.description')}</Table.HeadCell>
              <Table.HeadCell>{t('bookings.merchandise.product.value')}</Table.HeadCell>
              <Table.HeadCell>
                {t('bookings.merchandise.product.temperatures.short')}
              </Table.HeadCell>
              <Table.HeadCell>
                {t('bookings.merchandise.product.hazardousGoods.title')}
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              <Table.Row key='p-1'>
                <Table.Cell>
                  {merchandiseContent?.[0].productDescription ?? emptyDisplay}
                </Table.Cell>
                <Table.Cell>
                  {commercialValueDisplay(merchandiseContent?.[0].commercialValue)}
                </Table.Cell>
                <Table.Cell>
                  <div>
                    {temperatureDisplay(
                      merchandiseContent?.[0].controlledTemperatures?.min,
                      'Min.'
                    )}
                  </div>
                  <div>
                    {temperatureDisplay(
                      merchandiseContent?.[0].controlledTemperatures?.max,
                      'Max.'
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {hazardousGoodsDisplay(merchandiseContent?.[0].hazardousGoods)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </StyledMerchandiseProductDetails>
      )}
    </>
  )
}

export default InformationMerchandisePackageLegacy
