import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'

import { TRANSPORT_TYPE_PARCEL, TRANSPORT_TYPE_SEA, TransportType } from 'constants/shipments'
import { TimelineFormAlert, TimelineFormProps } from 'features/shipments/types/segment_timelines'

export const getAlertTranslationKey = (transportType: TransportType) => {
  if (transportType === TRANSPORT_TYPE_SEA)
    return 'shipments.editTimelineModal.alerts.transshipmentsAlert'
  if (transportType === TRANSPORT_TYPE_PARCEL)
    return 'shipments.editTimelineModal.alerts.sortingCentersAlert'

  return 'shipments.editTimelineModal.alerts.stopoversAlert'
}

export const addNewAlert = ({
  alert,
  getValues,
  setValue,
}: {
  alert: TimelineFormAlert
  getValues: UseFormGetValues<TimelineFormProps>
  setValue: UseFormSetValue<TimelineFormProps>
}) => {
  const alerts = getValues('alerts')

  if (alerts.some((a) => a.name === alert.name)) return

  setValue('alerts', [...alerts, alert])
}
