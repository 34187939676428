import React, { useContext } from 'react'

import Modal from 'components/modal'

import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'

import useSegmentTimelines from 'features/shipments/components/modal_timeline/hooks/use_segment_timelines'

import useSegmentTimelinesMapper from 'features/shipments/components/modal_timeline/hooks/use_segment_timelines_mapper'

import { isPresent } from 'services/helpers/values'

import TimelineForm from 'features/shipments/components/modal_timeline/components/timeline_form'
import TimelineFormSkeleton from 'features/shipments/components/modal_timeline/skeleton'

const ModalTimeline = () => {
  const { id } = useContext(ShipmentTokenContext)
  const { segments: segmentTimelines, status } = useSegmentTimelines(id!)
  const segments = useSegmentTimelinesMapper(segmentTimelines)

  return (
    <Modal modalName='timelineModal' width='large'>
      <Modal.Header>Timeline</Modal.Header>
      {status.pending && <TimelineFormSkeleton />}
      {isPresent(id) && status.fulfilled && <TimelineForm id={id} segments={segments} />}
    </Modal>
  )
}

export default ModalTimeline
