import { configureStore } from '@reduxjs/toolkit'

import { isProd } from 'services/helpers/environment'

import rootReducer from 'services/store/root_reducer'
import localStoragePersistence, {
  PERSISTED_STATE_KEY,
} from 'services/store/middlewares/persistState'
import { whiteLabelListener } from 'features/white_label/store/middleware'

const preloadedState = () => {
  const serializedState = localStorage.getItem(PERSISTED_STATE_KEY)
  if (serializedState === null) return {}
  return JSON.parse(serializedState)
}

const middleswares = [localStoragePersistence, whiteLabelListener.middleware]

const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleswares),
  devTools: !isProd,
  preloadedState: preloadedState(),
})

export type StoreType = typeof store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
