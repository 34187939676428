import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { addNotification } from 'views/notifications/slice'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import { sharedOrderSendMail } from 'features/shipments/store/shipment_slice'

import Modal from 'components/modal'
import {
  StyledShareFormInput,
  StyledShareFormInputTags,
  StyledShareFormTextarea,
} from 'components/modal_share/style'
import useSharedOrderMailInfo from 'components/modal_share/hooks/use_shared_order_mail_info'

import { isPresent } from 'services/helpers/values'
import useTracker from 'services/analytics/hooks/use_tracker'
import Button from 'components/button'
import useModal from 'components/modal/hooks/use_modal'
import useShareableLink from 'components/modal_share/hooks/use_shareable_link'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { SharedShipmentData, sharedShipmentSchema } from 'components/modal_share/types'

const ModalShare = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useContext(ShipmentTokenContext)
  const { setOpen } = useModal('share')
  const { track } = useTracker()

  const { getShareableLink } = useShareableLink()
  const { fullName, ref, origin, destination, eta } = useSharedOrderMailInfo({ id })

  const defaultEmailAttributes: SharedShipmentData = useMemo(
    () => ({
      emails: [],
      subject: t('shipments.shareableLink.modal.subject', { name: fullName, ref }),
      message: `${t('shipments.shareableLink.modal.message', { name: fullName })}
      
    - ${t('shipments.shareableLink.modal.ref', { ref })}
    - ${t('shipments.shareableLink.modal.origin', { origin })}
    - ${t('shipments.shareableLink.modal.destination', { destination })}
    - ${t('shipments.shareableLink.modal.eta', { eta })}`,
    }),
    [t, fullName, ref, origin, destination, eta]
  )
  const defaultMessage = t('shipments.shareableLink.modal.defaultMessage', { name: fullName })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<SharedShipmentData>({
    resolver: yupResolver(sharedShipmentSchema),
    defaultValues: defaultEmailAttributes,
    mode: 'onSubmit',
  })

  const submit = (data: SharedShipmentData) => {
    track('Shipment / Share', { type: 'share', detail: data.emails })
    dispatch(
      sharedOrderSendMail({
        token: id,
        recipients: data.emails,
        subject: data.subject,
        content: isPresent(data.message.trim()) ? data.message : defaultMessage,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.shareableLink.title'),
            text: t('shipments.shareableLink.modal.successMessageEmailSent'),
          })
        )
        setOpen(false)
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  return (
    <Modal modalName='share'>
      <Modal.Header>{t('shipments.shareableLink.modal.title')}</Modal.Header>
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Content>
          <Controller
            name='emails'
            control={control}
            render={({ field }) => (
              <StyledShareFormInputTags
                label={t('shipments.shareableLink.modal.emailAddresses')}
                name={field.name}
                placeholder={t('shipments.shareableLink.modal.emailAddressesPlaceholder')}
                onChange={({ tags }) => field.onChange(tags)}
                value={field.value}
                pattern='email'
              />
            )}
          />
          <Controller
            name='subject'
            control={control}
            render={({ field }) => (
              <StyledShareFormInput
                label={t('shipments.shareableLink.modal.emailSubject')}
                type='text'
                id='subject'
                onChange={({ target: { value } }) =>
                  field.onChange(value || defaultEmailAttributes.subject)
                }
                name={field.name}
                value={field.value}
              />
            )}
          />

          <Controller
            name='message'
            control={control}
            render={({ field }) => (
              <StyledShareFormTextarea
                label={t('shipments.shareableLink.modal.emailContent')}
                name={field.name}
                onChange={(e) => field.onChange(e.target.value || defaultEmailAttributes.message)}
                value={field.value}
              />
            )}
          />
        </Modal.Content>
        <Modal.Footer>
          <Button
            icon='hyperlink'
            text={t('shipments.shareableLink.modal.cta')}
            onClick={() => getShareableLink(id)}
          />
          <Button
            text={t('shipments.shareableLink.modal.send')}
            variant='highlight'
            type='submit'
            disabled={!isValid}
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ModalShare
