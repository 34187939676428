import React from 'react'

import ContentLoader from 'react-content-loader'

const AlternativesSkeleton: React.FC = () => (
  <ContentLoader speed={2} viewBox='0 0 600 25' backgroundColor='#f3f3f3' foregroundColor='#ecebeb'>
    <rect x='0' y='0' rx='5' ry='5' width='600' height='5' />
    <rect x='0' y='10' rx='5' ry='5' width='600' height='5' />
    <rect x='0' y='20' rx='5' ry='5' width='600' height='5' />
  </ContentLoader>
)

export default AlternativesSkeleton
