import { BookingCustomReference } from 'views/booking/slices/types'
import { BookingReferences } from 'features/shipments/types/legacy_shipment'

type Reference = { [key: string]: string }

const formatSingleReference = (bookingReference: Reference) => {
  const [key, value] = Object.entries(bookingReference)[0]

  return { key, value } as BookingCustomReference
}

const formatReferences = (data?: {
  shipperReferences?: Reference[]
  forwarderReferences?: Reference[]
}) => {
  const formattedShipperReferences =
    data?.shipperReferences?.map((ref) => formatSingleReference(ref)) || []
  const formattedForwarderReferences =
    data?.forwarderReferences?.map((ref) => formatSingleReference(ref)) || []
  return {
    shipperReferences: formattedShipperReferences,
    forwarderReferences: formattedForwarderReferences,
  } as BookingReferences
}

export default formatReferences
