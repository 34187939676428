import { OrderFlag } from 'features/shipments/types/legacy_shipment'

const fromStaticOrdersFlagTradToSelectOptions = (
  flags: OrderFlag[],
  // The static translation object obtained with the s method of useStaticLocales
  staticFlagReasonsTrad: { [key: string]: string }
) =>
  flags.map((flag) => ({
    value: String(flag.flagReasonId),
    label: staticFlagReasonsTrad[flag.flagReasonId] as string,
  }))

export default fromStaticOrdersFlagTradToSelectOptions
