import React, { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { UserFilters } from 'features/users/components/user_form/hooks/use_user_form'

import S from 'features/users/components/user_form/style'

import {
  fetchCompanyOptions,
  fetchMainRoles,
  fetchOptionalRoles,
  fetchOrganizationOptions,
} from 'views/select_options/slice'

import {
  TEST_ID_USERS_FORM_COMPANY,
  TEST_ID_USERS_FORM_EMAIL,
  TEST_ID_USERS_FORM_FIRST_NAME,
  TEST_ID_USERS_FORM_LAST_NAME,
  TEST_ID_USERS_FORM_MAIN_ROLES,
  TEST_ID_USERS_FORM_OPTIONAL_ROLES,
  TEST_ID_USERS_FORM_ORGANIZATION,
  TEST_ID_USERS_FORM_IS_SSO,
} from 'tests/e2e/test_ids'

import { User } from 'features/users/types/user'

import { StyledInputLabel } from 'components/input/style'

import Grid from 'components/grid'
import Form from 'components/form'

import Input from 'components/input'
import SearchSelect from 'components/search_select'

import SearchMultiSelect from 'components/search_multi_select'
import InputTags from 'components/input_tags'

import SwitchInput from 'components/switch_input'

interface UserFormProps {
  filters: UserFilters
  user?: User
}

interface OrganizationSelectValue {
  label: string
  value: string
  withSso?: boolean
}

const UserForm: FC<UserFormProps> = ({ filters, user }) => {
  const { t } = useTranslation()
  const fetchedOptionsFormatsForOrganization = (
    organizations: OrganizationSelectValue[]
  ): OrganizationSelectValue[] => organizations

  const {
    firstNameFilter,
    companyFilter,
    emailFilter,
    lastNameFilter,
    mainRolesFilter,
    optionalRolesFilter,
    organizationFilter,
    organizationCodesFilter,
    isSsoFilter,
  } = filters
  const [organizationHasSso, setOrganizationHasSso] = useState<boolean>(user?.hasSso ?? false)

  return (
    <Form>
      <Form.Group>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Input
                label={t('users.form.firstName')}
                placeholder={t('users.form.firstName')}
                value={firstNameFilter.value}
                required={firstNameFilter.isRequired}
                onChange={firstNameFilter.onChange}
                disabled={firstNameFilter.isDisabled}
                type='text'
                testId={TEST_ID_USERS_FORM_FIRST_NAME}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                label={t('users.form.lastName')}
                placeholder={t('users.form.lastName')}
                value={lastNameFilter.value}
                required={lastNameFilter.isRequired}
                onChange={lastNameFilter.onChange}
                disabled={lastNameFilter.isDisabled}
                type='text'
                testId={TEST_ID_USERS_FORM_LAST_NAME}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Input
                label={t('users.form.email')}
                placeholder={t('users.form.emailAddress')}
                value={emailFilter.value}
                required={emailFilter.isRequired}
                onChange={emailFilter.onChange}
                disabled={emailFilter.isDisabled}
                type='text'
                testId={TEST_ID_USERS_FORM_EMAIL}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <SearchSelect
                action={fetchOrganizationOptions}
                label={t('users.form.organization')}
                required={organizationFilter.isRequired}
                placeholder={t('users.form.organization')}
                name={organizationFilter.name}
                value={organizationFilter.value}
                fetchedOptionsFormat={fetchedOptionsFormatsForOrganization}
                // Interface of select value does not allow additional properties so we need an any
                onChange={(selectOption: any) => {
                  organizationFilter.onChange(selectOption)
                  setOrganizationHasSso(selectOption.value?.withSso ?? false)
                  isSsoFilter.setValue(selectOption.value?.withSso ?? false)
                  companyFilter.reset()
                }}
                isDisabled={organizationFilter.isDisabled}
                testId={TEST_ID_USERS_FORM_ORGANIZATION}
              />
            </Grid.Column>
            <Grid.Column>
              <SearchSelect
                action={fetchCompanyOptions}
                label={t('users.form.company')}
                placeholder={t('users.form.company')}
                name={companyFilter.name}
                value={companyFilter.value}
                onChange={companyFilter.onChange}
                extraActionParams={
                  organizationFilter.isPresent
                    ? { organizationId: organizationFilter.value?.value }
                    : undefined
                }
                isDisabled={companyFilter.isDisabled || !organizationFilter.isPresent}
                testId={TEST_ID_USERS_FORM_COMPANY}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {organizationHasSso && (
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column>
                <S.SwitchInput>
                  <StyledInputLabel htmlFor={isSsoFilter.name || 'is_sso'}>
                    {t('users.form.isSso')}
                  </StyledInputLabel>
                  <SwitchInput
                    disabled={isSsoFilter.isDisabled}
                    leftLabel={t('actions.off').toUpperCase()}
                    rightLabel={t('actions.on').toUpperCase()}
                    name={isSsoFilter.name}
                    onClick={(selected) => isSsoFilter.onChange({ target: { checked: selected } })}
                    rightOptionChecked={isSsoFilter.value ?? true}
                    variant='outline'
                    testId={TEST_ID_USERS_FORM_IS_SSO}
                  />
                </S.SwitchInput>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <SearchSelect
                action={fetchMainRoles}
                label={t('users.form.roles')}
                placeholder={t('users.form.roles')}
                name={mainRolesFilter.name}
                required={mainRolesFilter.isRequired}
                value={mainRolesFilter.value}
                onChange={mainRolesFilter.onChange}
                isDisabled={mainRolesFilter.isDisabled}
                testId={TEST_ID_USERS_FORM_MAIN_ROLES}
              />
            </Grid.Column>
            <Grid.Column>
              <SearchMultiSelect
                action={fetchOptionalRoles}
                label={t('users.form.optionalRoles')}
                placeholder={t('users.form.optionalRoles')}
                name={optionalRolesFilter.name}
                value={optionalRolesFilter.value}
                onChange={optionalRolesFilter.onChange}
                isMulti
                isDisabled={optionalRolesFilter.isDisabled}
                testId={TEST_ID_USERS_FORM_OPTIONAL_ROLES}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <InputTags
                settings={{
                  delimiters: '\n',
                }}
                hidePlaceholderWithTag
                label={t('users.form.organizationCodes')}
                placeholder={t('users.form.organizationCodesPlaceholder')}
                name={organizationCodesFilter.name}
                onChange={organizationCodesFilter.onChange}
                value={organizationCodesFilter.value}
                disabled={organizationCodesFilter.isDisabled}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Group>
    </Form>
  )
}

export default UserForm
