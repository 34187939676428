import React, { FC, ReactNode } from 'react'

interface PlaceholderProps {
  ready: boolean
  customPlaceholder: ReactNode
}

const Placeholder: FC<PlaceholderProps> = ({ ready, customPlaceholder, children }) => (
  <>{ready ? children : customPlaceholder}</>
)

export default Placeholder
