import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/icon'
import {
  StyledStepHead,
  StyledStep,
  StyledStepName,
  StyledStepIcon,
  StyledStepMilestones,
  StyledStepProgress,
} from 'core/components/timeline/style'

import { STEP_STATUSES, STEP_STATUS_INACTIVE } from 'constants/shipments'

interface TimelineBookingConfirmationStepProps {
  status?: typeof STEP_STATUSES[number]
  children?: React.ReactNode
}

const TimelineBookingConfirmationStep: FC<TimelineBookingConfirmationStepProps> = ({
  status = STEP_STATUS_INACTIVE,
  children,
}) => {
  const { t } = useTranslation()
  return (
    <StyledStep $status={status} $inTransit={false}>
      <StyledStepHead>
        <StyledStepIcon>
          <Icon name='calendar' />
        </StyledStepIcon>
        <StyledStepName>{t('timeline.bookingConfirmation.title')}</StyledStepName>
      </StyledStepHead>
      <StyledStepMilestones>{children}</StyledStepMilestones>
      <StyledStepProgress />
    </StyledStep>
  )
}

export default TimelineBookingConfirmationStep
