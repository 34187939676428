import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useShipment from 'features/shipments/hooks/use_shipment'
import { LegFactory } from 'features/shipments/helpers/models'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'

import BlockTransportationPlaceholder from 'components/block_transportation/skeleton'
import BlockTransportation from 'components/block_transportation'
import BlockTransportationItem from 'components/block_transportation/block_transportation_item'
import Placeholder from 'components/placeholder'

import {
  SUBSCRIPTION_INTEGRATION_STATUSES,
  SUBSCRIPTION_STATUSES,
  TransportType,
} from 'constants/shipments'
import { getTestIdForShipmentSegment } from 'tests/e2e/test_ids'
import { isPresent } from 'services/helpers/values'
import { SegmentInfo } from 'features/shipments/types/legacy_shipment'

// TODO: REFACTOR: this leg implementation reflects the obsolete API interface with separated segments/segment_infos.
// It forces to merge both manually
// It should be centered around a new Leg concept
const ShipmentTransportation: FC = () => {
  const { t } = useTranslation()
  const { id } = useContext(ShipmentTokenContext)
  const [shipment, status] = useShipment({ id })

  return (
    <BlockTransportation title={t('shipments.transportation.title')}>
      <Placeholder ready={status.ready} customPlaceholder={<BlockTransportationPlaceholder />}>
        {shipment?.segments?.map((segment, index) => {
          const leg = new LegFactory(segment).make()
          const segmentInfo: SegmentInfo = leg.findSegmentInfo(shipment?.segmentInfos)
          const { origin, departure, arrival, destination, current, subscriptions } = segmentInfo

          const subscriptionOnHold = subscriptions.find(
            (s) => s.integrationStatus === SUBSCRIPTION_INTEGRATION_STATUSES.HOLD
          )
          const subscribedSubscription = subscriptions.find(
            ({ subscriptionStatus }) => subscriptionStatus === SUBSCRIPTION_STATUSES.SUBSCRIBED
          )
          const originLocation = origin || departure
          const destinationLocation = destination || arrival
          return (
            <BlockTransportationItem
              key={`block-transportation-item-${leg.id}`}
              type={leg.transportType as TransportType}
              from={{
                name: originLocation.name,
                country: {
                  name: originLocation.country,
                  code: originLocation.countryCode,
                },
              }}
              to={{
                name: destinationLocation.name,
                country: {
                  name: destinationLocation.country,
                  code: destinationLocation.countryCode,
                },
              }}
              data={leg.data}
              extraData={leg.extraData}
              linksData={leg.links}
              defaultOpened={current}
              isSubscriptionIntegrationOnHold={isPresent(subscriptionOnHold)}
              sanityCheckFailureReason={subscriptionOnHold?.lastFailureReason}
              subscriptionType={subscribedSubscription?.subscriptionType}
              testId={getTestIdForShipmentSegment(index)}
            />
          )
        })}
      </Placeholder>
    </BlockTransportation>
  )
}

export default ShipmentTransportation
