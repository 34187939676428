import React, { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import Modal from 'components/modal'
import Button from 'components/button'

import useModal from 'components/modal/hooks/use_modal'
import {
  TEST_ID_BOOKING_TEMPLATE_INPUT,
  TEST_ID_BOOKING_TEMPLATE_SAVE_CONFIRM,
} from 'tests/e2e/test_ids'
import Input from 'components/input'

import { BookingFormInput } from 'views/booking/components/form/types'

import generateCreateTemplatePayload from 'views/booking/components/form/helpers/form_to_template'
import { createBookingTemplate } from 'views/booking/slices/booking_templates_slice'

import { addNotification } from 'views/notifications/slice'

import useAppDispatch from 'services/hooks/use_app_dispatch'

export interface SaveAsTemplateModalProps {
  onClose: () => void
}

const SaveAsTemplateModal: FC<SaveAsTemplateModalProps> = ({
  onClose,
}: SaveAsTemplateModalProps) => {
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingSaveAsTemplate')
  const dispatch = useAppDispatch()

  const [templateName, setTemplateName] = useState<string>('')

  const { getValues, reset } = useFormContext<BookingFormInput>()
  const [isLoading, setIsLoading] = useState(false)

  const onTemplateSubmit = () => {
    const bookingTemplateData = generateCreateTemplatePayload(getValues(), templateName)
    setIsLoading(true)
    dispatch(createBookingTemplate(bookingTemplateData))
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('bookings.templates.notifications.title'),
            text: t('bookings.templates.notifications.success'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('bookings.templates.notifications.title'),
            text: t('errors.notification.content'),
          })
        )
      })
      .finally(() => {
        setTemplateName('')
        reset(getValues())
        setIsLoading(false)
      })
  }

  return (
    <Modal position='center' modalName='bookingSaveAsTemplate' onClose={onClose}>
      <Modal.Header>{t('bookings.templates.modal.title')}</Modal.Header>
      <Modal.Content>
        <Input
          label={t('bookings.templates.modal.label')}
          type='text'
          placeholder={t('bookings.templates.modal.placeholder')}
          value={templateName}
          onChange={({ target: { value } }) => setTemplateName(value)}
          disabled={false}
          testId={TEST_ID_BOOKING_TEMPLATE_INPUT}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} rounded variant='clear' onClick={() => setOpen(false)} />
        <Button
          text={t('bookings.templates.save')}
          rounded
          variant='highlight'
          disabled={!templateName}
          isLoading={isLoading}
          onClick={() => {
            onTemplateSubmit()
            setOpen(false)
          }}
          testId={TEST_ID_BOOKING_TEMPLATE_SAVE_CONFIRM}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(SaveAsTemplateModal)
