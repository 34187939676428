import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useCoordinates from 'components/map/hooks/use_coordinates'

import { selectLegs } from 'features/shipments/store/shipment_slice'
import { ShipmentPath } from 'features/shipments/types/legacy_shipment'
import { PathModel, StepModel } from 'components/map/models'
import DateHelper from 'services/helpers/date_helper'

const useNormalizedCoordinates = (id: string, inputPath: ShipmentPath) => {
  const { normalize } = useCoordinates()
  const legs = useSelector(selectLegs({ id }))

  // We get arrivals and departurs and cast them as StepModel

  const memoizedLegs = useMemo(
    () =>
      legs
        .map(({ departure, arrival }) => {
          const { lat: depLat, lng: depLng, type: depType } = departure
          const { lat: arrLat, lng: arrLng, type: arrType } = arrival
          return [
            new StepModel({ lat: depLat!, lng: depLng!, type: depType }),
            new StepModel({ lat: arrLat!, lng: arrLng!, type: arrType }),
          ]
        })
        .flat(),
    [legs]
  )

  const path = useMemo(() => {
    const mutablePath = [...inputPath]
    // we sort the positions to draw the path correctly
    const sortedPath = mutablePath.sort((currentStep, nextStep) => {
      const currentStepDate = currentStep[2]
      const nextStepDate = nextStep[2]
      if (new DateHelper(currentStepDate).isBefore(new Date(nextStepDate))) {
        return -1
      }
      return 1
    })
    return sortedPath.map((coords) => {
      const [lat, lng, timestamp] = coords
      return new PathModel({ lat: String(lat), lng: String(lng), timestamp })
    })
  }, [inputPath])

  // Legacy -> we have to normalize the coordinates
  const { normalized: normalizedPathCoordinates, center: pathCenter } = normalize(
    path.map((p) => p.coordinate)
  )
  const { normalized: normalizedStepsCoordinates } = normalize(
    memoizedLegs.map((step) => step.coordinate),
    pathCenter
  )
  return {
    normalizedPathCoordinates,
    normalizedStepsCoordinates,
    pathAsPathModel: path,
    stepsAsStepsModel: memoizedLegs,
  }
}

export default useNormalizedCoordinates
