import React, { FC } from 'react'

import { StyledTimeline } from 'core/components/timeline/style'

interface TimelineProps {
  stretched?: boolean
}

const Timeline: FC<TimelineProps> = ({ stretched = false, children }) => (
  <StyledTimeline $stretched={stretched}>{children}</StyledTimeline>
)

export default Timeline
