import styled from 'styled-components'

import { tablet } from 'styles/utils/breakpoints'
import { textSmallDescription } from 'styles/utils/texts'

const StyledModalTimelineStepHeader = styled.span`
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription};
  min-width: 130px;
  align-items: center;
  justify-content: center;
  @media ${tablet.down} {
    margin-bottom: 15px;
  }
`

export const StyledModalTimelineStepHeaderType = styled(StyledModalTimelineStepHeader)`
  flex-basis: 40%;
`

export const StyledModalTimelineStepHeaderLocation = styled(StyledModalTimelineStepHeader)`
  flex-basis: 60%;
`
