import { Segment, SegmentTimelines, Step } from 'features/shipments/types/segment_timelines'
import {
  STEP_TYPE_AIR_DESTINATION,
  STEP_TYPE_AIR_ORIGIN,
  STEP_TYPE_AIR_STOPOVER,
  STEP_TYPE_EMPTY_PICKUP,
  STEP_TYPE_PARCEL_DESTINATION,
  STEP_TYPE_PARCEL_ORIGIN,
  STEP_TYPE_PARCEL_STOPOVER,
  STEP_TYPE_RAIL_DESTINATION,
  STEP_TYPE_RAIL_ORIGIN,
  STEP_TYPE_ROAD_DESTINATION,
  STEP_TYPE_ROAD_ORIGIN,
  STEP_TYPE_SEA_DELIVERY,
  STEP_TYPE_SEA_EMPTY_RETURN,
  STEP_TYPE_SEA_PICKUP,
  STEP_TYPE_SEA_POD,
  STEP_TYPE_SEA_POL,
  STEP_TYPE_SEA_TRANSSHIPMENT,
  StepType,
} from 'constants/steps'
import { DATA_SOURCE_FRONT } from 'constants/shipments'

interface CategorizedSteps {
  preStopoversSteps: Step[]
  stopovers: Step[]
  postStopoversSteps: Step[]
}

export interface SegmentWithCategorizedSteps
  extends Pick<
      Segment,
      | 'id'
      | 'currentSegment'
      | 'transportType'
      | 'departure'
      | 'arrival'
      | 'currentSegment'
      | 'milestones'
    >,
    CategorizedSteps {}

const useSegmentTimelinesMapper = (segments: SegmentTimelines): SegmentWithCategorizedSteps[] => {
  const getMatchingStep = (stepType: StepType, steps: Step[]) => {
    const matchingStep = steps.find((s) => s.type === stepType)

    return {
      active: true,
      ...matchingStep,
    }
  }

  const getSortingCenters = (steps: Step[]) =>
    steps.filter((step) => step.type === STEP_TYPE_PARCEL_STOPOVER)

  const getTransshipments = (steps: Step[]) =>
    steps.filter((step) => step.type === STEP_TYPE_SEA_TRANSSHIPMENT)

  const getStopovers = (steps: Step[]) =>
    steps.filter((step) => step.type === STEP_TYPE_AIR_STOPOVER)

  const formatSeaSteps = (steps: Step[]): CategorizedSteps => {
    const transshipments = getTransshipments(steps)
    const emptyPickup = getMatchingStep(STEP_TYPE_EMPTY_PICKUP, steps)
    const pickup = getMatchingStep(STEP_TYPE_SEA_PICKUP, steps)
    const pol = getMatchingStep(STEP_TYPE_SEA_POL, steps)
    const pod = getMatchingStep(STEP_TYPE_SEA_POD, steps)
    const delivery = getMatchingStep(STEP_TYPE_SEA_DELIVERY, steps)
    const emptyReturn = getMatchingStep(STEP_TYPE_SEA_EMPTY_RETURN, steps)

    return {
      preStopoversSteps: [
        {
          ...emptyPickup,
          type: STEP_TYPE_EMPTY_PICKUP,
          source: emptyPickup?.source ?? DATA_SOURCE_FRONT,
        },
        {
          ...pickup,
          type: STEP_TYPE_SEA_PICKUP,
          source: pickup?.source ?? DATA_SOURCE_FRONT,
        },
        {
          ...pol,
          type: STEP_TYPE_SEA_POL,
          source: pol?.source ?? DATA_SOURCE_FRONT,
        },
      ],
      stopovers: transshipments.map(
        (ts): Step => ({
          ...ts,
          type: STEP_TYPE_SEA_TRANSSHIPMENT,
        })
      ),
      postStopoversSteps: [
        {
          ...pod,
          type: STEP_TYPE_SEA_POD,
          source: pod?.source ?? DATA_SOURCE_FRONT,
        },
        {
          ...delivery,
          type: STEP_TYPE_SEA_DELIVERY,
          source: delivery?.source ?? DATA_SOURCE_FRONT,
        },
        {
          ...emptyReturn,
          type: STEP_TYPE_SEA_EMPTY_RETURN,
          source: emptyReturn?.source ?? DATA_SOURCE_FRONT,
        },
      ],
    }
  }

  const formatAirSteps = (steps: Step[]): CategorizedSteps => {
    const stopovers = getStopovers(steps)
    const origin = getMatchingStep(STEP_TYPE_AIR_ORIGIN, steps)
    const destination = getMatchingStep(STEP_TYPE_AIR_DESTINATION, steps)

    return {
      preStopoversSteps: [
        {
          ...origin,
          type: STEP_TYPE_AIR_ORIGIN,
          source: origin?.source ?? DATA_SOURCE_FRONT,
        },
      ],
      stopovers: stopovers.map(
        (stopover): Step => ({
          ...stopover,
          type: STEP_TYPE_AIR_STOPOVER,
        })
      ),
      postStopoversSteps: [
        {
          ...destination,
          type: STEP_TYPE_AIR_DESTINATION,
          source: destination?.source ?? DATA_SOURCE_FRONT,
        },
      ],
    }
  }

  const formatRoadSteps = (steps: Step[]): CategorizedSteps => {
    const origin = getMatchingStep(STEP_TYPE_ROAD_ORIGIN, steps)
    const destination = getMatchingStep(STEP_TYPE_ROAD_DESTINATION, steps)

    return {
      preStopoversSteps: [
        {
          ...origin,
          type: STEP_TYPE_ROAD_ORIGIN,
          source: origin?.source ?? DATA_SOURCE_FRONT,
        },
      ],
      stopovers: [],
      postStopoversSteps: [
        {
          ...destination,
          type: STEP_TYPE_ROAD_DESTINATION,
          source: destination?.source ?? DATA_SOURCE_FRONT,
        },
      ],
    }
  }

  const formatRailSteps = (steps: Step[]): CategorizedSteps => {
    const origin = getMatchingStep(STEP_TYPE_RAIL_ORIGIN, steps)
    const destination = getMatchingStep(STEP_TYPE_RAIL_DESTINATION, steps)

    return {
      preStopoversSteps: [
        {
          ...origin,
          type: STEP_TYPE_RAIL_ORIGIN,
          source: origin?.source ?? DATA_SOURCE_FRONT,
        },
      ],
      stopovers: [],
      postStopoversSteps: [
        {
          ...destination,
          type: STEP_TYPE_RAIL_DESTINATION,
          source: destination?.source ?? DATA_SOURCE_FRONT,
        },
      ],
    }
  }

  const formatParcelSteps = (steps: Step[]): CategorizedSteps => {
    const stopovers = getSortingCenters(steps)
    const origin = getMatchingStep(STEP_TYPE_PARCEL_ORIGIN, steps)
    const destination = getMatchingStep(STEP_TYPE_PARCEL_DESTINATION, steps)
    return {
      preStopoversSteps: [
        {
          ...origin,
          type: STEP_TYPE_PARCEL_ORIGIN,
          source: origin?.source ?? DATA_SOURCE_FRONT,
        },
      ],
      stopovers: stopovers.map(
        (stopover): Step => ({
          ...stopover,
          type: STEP_TYPE_PARCEL_STOPOVER,
        })
      ),
      postStopoversSteps: [
        {
          ...destination,
          type: STEP_TYPE_PARCEL_DESTINATION,
          source: destination?.source ?? DATA_SOURCE_FRONT,
        },
      ],
    }
  }

  const stepTypesMap: Record<string, (steps: Step[]) => CategorizedSteps> = {
    sea: formatSeaSteps,
    air: formatAirSteps,
    road: formatRoadSteps,
    rail: formatRailSteps,
    parcel: formatParcelSteps,
  }

  return segments.map(
    ({ transportType, departure, arrival, currentSegment, steps, milestones, id }) => {
      const { preStopoversSteps, stopovers, postStopoversSteps } =
        stepTypesMap[transportType](steps)

      return {
        id,
        transportType,
        departure,
        arrival,
        currentSegment,
        preStopoversSteps,
        stopovers,
        postStopoversSteps,
        milestones,
      }
    }
  )
}
export default useSegmentTimelinesMapper
