import { useSelector } from 'react-redux'

import { SegmentTimelines } from 'features/shipments/types/segment_timelines'
import {
  selectSegmentTimelines,
  selectSegmentTimelinesStatus,
} from 'features/shipments/store/shipment_slice'
import useStatus, { UseStatus } from 'services/api/hooks/use_status'
import { RootState } from 'services/store/store'

const useSegmentTimelines = (id: string): { segments: SegmentTimelines; status: UseStatus } => {
  const segments = useSelector(selectSegmentTimelines({ id }))
  const status = useStatus(
    useSelector((state: RootState) => selectSegmentTimelinesStatus(state, id))
  )

  return { segments, status }
}

export default useSegmentTimelines
