import styled from 'styled-components'

export const StyledModalTimelineMilestoneTableRowWrapper = styled.div<{ $active: boolean }>`
  ${(props) => !props.$active && 'text-decoration: line-through;'}
`

export const StyledModalTimelineStepLocationWrapper = styled.div`
  flex-basis: 40%;
`

export const StyledModalTimelineStepEditActionsWrapper = styled.div`
  flex-basis: 20%;
  height: 100%;
  min-width: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledModalTimelineStepEditActionButtonWrapper = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledModalTimelineMilestoneEditActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
`

export const StyledModalTimelineCreateMilestoneWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
