import React, { FC, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'

import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'

import { StyledShipmentListItem } from 'features/shipments/components/shipment_list_item/style'
import { routeShipment } from 'services/helpers/routes'
import useModal from 'components/modal/hooks/use_modal'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_context'
import StyledCondensedCard from 'features/shipments/components/shipment_list_item/components/card/style'

interface CardProps {
  testId?: string
}

const Card: FC<CardProps> = ({ children, testId }) => {
  const { setOpen } = useModal('dashboardMap')
  const { shipment } = useContext(ShipmentTokenContext)
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])
  const onClick = () => {
    setOpen(false)
  }
  return isCondensed ? (
    <StyledCondensedCard
      as={Link}
      to={routeShipment(shipment.id)}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </StyledCondensedCard>
  ) : (
    <StyledShipmentListItem
      as={Link}
      to={routeShipment(shipment.id)}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </StyledShipmentListItem>
  )
}

export default Card
