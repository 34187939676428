import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  fetchAlternativeTrustedRoutes,
  selectSubscriptionID,
  selectShowAlternatives,
  selectActiveQueryParams,
  selectAlternatives,
} from 'views/trusted_routes/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTracker from 'services/analytics/hooks/use_tracker'

const useSearchAlternativeTrustedRoutes = () => {
  const dispatch = useAppDispatch()
  const shouldShowAlternatives = useSelector(selectShowAlternatives)
  const subscriptionID = useSelector(selectSubscriptionID)
  const { track } = useTracker()
  const activeQueryParams = useSelector(selectActiveQueryParams)
  const alternatives = useSelector(selectAlternatives)

  useEffect(() => {
    // Do not fetch alternatives if:
    // - alternatives are already loaded (user navigate to another page and come back)
    // - subscription is not completed yet (should not be possible)
    // - the user is already on a page where a suggestion is displayed
    if (!shouldShowAlternatives || !subscriptionID || alternatives) return

    dispatch(fetchAlternativeTrustedRoutes({ subscriptionID }))
      .unwrap()
      .then((data) =>
        track('TrustedRoutes / AlternativeRoutesLoaded', {
          polSource: activeQueryParams.departure?.value,
          podSource: activeQueryParams.arrival?.value,
          bestTransitTimeCount: data.bestHistoricalEstimatedTimeAlternatives?.length ?? 0,
          lowestEmissionsCount: data.bestHistoricalCo2Alternatives?.length ?? 0,
          fewerAlertsCount: data.bestHistoricalDisruptionAlertsAlternatives?.length ?? 0,
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionID, shouldShowAlternatives])
}

export default useSearchAlternativeTrustedRoutes
