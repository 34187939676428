import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useTheme } from 'styled-components'

import {
  StyledFormEditGroupLabel,
  StyledFormEditGroupValue,
  StyledFormEditGroupItem,
  StyledMerchandiseItem,
  StyledMerchandiseProductDetails,
  StyleMerchandiseItemIcon,
  StyledMerchandiseTotalContentLabel,
  StyledFormEditGroup,
  StyledFormEditGroupItems,
  StyledFormEditGroupTitle,
} from 'views/booking/components/show/style'
import { AnyBookingMerchandise, BookingMerchandiseTotal } from 'views/booking/slices/types'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import Table from 'components/table'
import useMerchandiseInformationDisplay from 'views/booking/hooks/use_merchandise_information_display'
import InformationMerchandiseContentHeader from 'views/booking/components/information_merchandise_content_header'

import { TEST_ID_BOOKING_SHOW_SHIPPING_MODE } from 'tests/e2e/test_ids'
import {
  merchandiseHasAnyControlledTemperatures,
  merchandiseHasAnyHazardousGoods,
} from 'features/bookings/helpers/merchandises'

const InformationMerchandiseTotal = ({ merchandise }: { merchandise: AnyBookingMerchandise }) => {
  const { s } = useStaticLocales()
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    currencyDisplay,
    hazardousGoodsDisplay,
    temperatureDisplay,
    commercialValueDisplay,
    emptyDisplay,
  } = useMerchandiseInformationDisplay()

  const { content: merchandiseContent, shippingMode } = merchandise as BookingMerchandiseTotal

  const totalWeight = merchandiseContent?.products?.reduce(
    (total, product) => total + (product.weight?.value ?? 0),
    0
  )

  const totalVolume = merchandiseContent?.products?.reduce(
    (total, product) => total + (product.volume?.value ?? 0),
    0
  )

  const totalPackages = merchandiseContent?.products?.reduce(
    (total, product) => total + (product.packageNumber ?? 0),
    0
  )

  const currency = currencyDisplay(merchandiseContent?.products?.[0]?.commercialValue?.currencyCode)

  const totalAmount = merchandiseContent?.products?.reduce(
    (total, product) => total + (product.commercialValue?.amount ?? 0),
    0
  )

  const [isMerchandiseContentExpanded, setMerchandiseContentExpansion] = useState<boolean>()

  return (
    <>
      <StyledFormEditGroup>
        <StyledFormEditGroupTitle>{t('bookings.merchandise.title')}</StyledFormEditGroupTitle>
        <StyledFormEditGroupItems>
          <StyledFormEditGroupItem>
            <StyledFormEditGroupLabel>
              {t('bookings.merchandise.shippingMode')}
            </StyledFormEditGroupLabel>
            <StyledFormEditGroupValue data-testid={TEST_ID_BOOKING_SHOW_SHIPPING_MODE}>
              <ValueWithFallback value={shippingMode} />
            </StyledFormEditGroupValue>
          </StyledFormEditGroupItem>
          <StyledFormEditGroupItem>
            <StyledFormEditGroupLabel>
              {t('bookings.merchandise.container(s)')}
            </StyledFormEditGroupLabel>
            <div>
              {merchandiseContent?.containers.map((container: any) => (
                <StyledMerchandiseItem>
                  <StyleMerchandiseItemIcon
                    fill={theme.mediumGray}
                    width={24}
                    height={24}
                    name='container'
                  />
                  <StyledFormEditGroupValue>
                    {`${s('containerTypes')[container.containerType]} x ${container.quantity}`}
                  </StyledFormEditGroupValue>
                </StyledMerchandiseItem>
              ))}
            </div>
          </StyledFormEditGroupItem>
          <StyledFormEditGroupItem>
            <StyledMerchandiseTotalContentLabel>
              {t('bookings.merchandise.content')}
            </StyledMerchandiseTotalContentLabel>
            <InformationMerchandiseContentHeader
              index={0}
              weight={{ value: totalWeight }}
              volume={{ value: totalVolume }}
              packageNumber={totalPackages}
              amount={totalAmount}
              currency={currency}
              hasAnyHazardousGoods={
                merchandiseContent ? merchandiseHasAnyHazardousGoods(merchandise) : false
              }
              hasAnyControlledTemperatures={
                merchandiseContent ? merchandiseHasAnyControlledTemperatures(merchandise) : false
              }
              withAction={(merchandiseContent?.products?.length ?? 0) > 0}
              expanded={isMerchandiseContentExpanded}
              onClick={() => setMerchandiseContentExpansion(!isMerchandiseContentExpanded)}
            />
          </StyledFormEditGroupItem>
        </StyledFormEditGroupItems>
      </StyledFormEditGroup>
      {isMerchandiseContentExpanded && (
        <StyledMerchandiseProductDetails>
          <Table>
            <Table.Head>
              <Table.HeadCell>{t('bookings.merchandise.product.description')}</Table.HeadCell>
              <Table.HeadCell>{t('bookings.merchandise.product.weight')} (kg)</Table.HeadCell>
              <Table.HeadCell>{t('bookings.merchandise.product.volume.short')} (m³)</Table.HeadCell>
              <Table.HeadCell>{t('bookings.merchandise.package(s)')}</Table.HeadCell>
              <Table.HeadCell>{t('bookings.merchandise.product.value')}</Table.HeadCell>
              <Table.HeadCell>
                {t('bookings.merchandise.product.temperatures.short')}
              </Table.HeadCell>
              <Table.HeadCell>
                {t('bookings.merchandise.product.hazardousGoods.title')}
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {merchandiseContent?.products?.map((product, index: number) => (
                <Table.Row odd={index % 2 === 1} key={`product-${index + 1}`}>
                  <Table.Cell>{product.productDescription ?? emptyDisplay}</Table.Cell>
                  <Table.Cell>{product.weight?.value ?? emptyDisplay}</Table.Cell>
                  <Table.Cell>{product.volume?.value ?? emptyDisplay}</Table.Cell>
                  <Table.Cell>{product.packageNumber ?? emptyDisplay}</Table.Cell>
                  <Table.Cell>{commercialValueDisplay(product.commercialValue)}</Table.Cell>
                  <Table.Cell>
                    <div>{temperatureDisplay(product.controlledTemperatures?.min, 'Min.')}</div>
                    <div>{temperatureDisplay(product.controlledTemperatures?.max, 'Max.')}</div>
                  </Table.Cell>
                  <Table.Cell>{hazardousGoodsDisplay(product.hazardousGoods)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </StyledMerchandiseProductDetails>
      )}
    </>
  )
}

export default InformationMerchandiseTotal
