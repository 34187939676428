import { t } from 'i18next'

import { TransportType } from 'types/shipments'

import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_PARCEL, TRANSPORT_TYPE_SEA } from 'constants/shipments'
import { isPresent } from 'services/helpers/values'

import { TimelineFormMilestone } from 'features/shipments/types/segment_timelines'

import {
  StopoverType,
  STEP_TYPE_AIR_STOPOVER,
  STEP_TYPE_PARCEL_STOPOVER,
  STEP_TYPE_SEA_TRANSSHIPMENT,
} from 'constants/steps'

import DateHelper from 'services/helpers/date_helper'
import { EMPTY_VALUE } from 'constants/milestones'

export const STOPOVER_STEP_BY_TRANSPORT_TYPE: { [k in TransportType]?: StopoverType } = {
  [TRANSPORT_TYPE_SEA]: STEP_TYPE_SEA_TRANSSHIPMENT,
  [TRANSPORT_TYPE_AIR]: STEP_TYPE_AIR_STOPOVER,
  [TRANSPORT_TYPE_PARCEL]: STEP_TYPE_PARCEL_STOPOVER,
}

export const milestoneHasBeenEditedByAdmin = (milestone: TimelineFormMilestone) =>
  milestone.isMilestoneDataOverridden.actualTime ||
  milestone.isMilestoneDataOverridden.estimatedTime ||
  milestone.isMilestoneDataOverridden.plannedTime ||
  milestone.isMilestoneDataOverridden.metadata ||
  !milestone.initialState.active

export const formatMilestoneDate = (milestone: TimelineFormMilestone) => {
  if (milestoneHasBeenReverted(milestone)) {
    const dateInfo = getNextMilestoneDateInformationAfterReversion(milestone)

    return `${dateInfo.label}: ${
      isPresent(dateInfo.value) ? new DateHelper(dateInfo.value).toLocale({ hours: true }) : 'N/A'
    }`
  }

  if (isPresent(milestone.actualTime) && milestone.actualTime !== EMPTY_VALUE)
    return `${t('shipments.milestones.actualTime')}: ${new DateHelper(
      milestone.actualTime
    ).toLocale({ hours: true })}`

  if (isPresent(milestone.estimatedTime) && milestone.estimatedTime !== EMPTY_VALUE)
    return `${t('shipments.milestones.expectedTime')}: ${new DateHelper(
      milestone.estimatedTime
    ).toLocale({ hours: true })}`

  return `${t('shipments.milestones.plannedTime')}: ${
    isPresent(milestone.plannedTime) && milestone.plannedTime !== EMPTY_VALUE
      ? new DateHelper(milestone.plannedTime).toLocale({ hours: true })
      : 'N/A'
  }`
}

export const getNextMilestoneDateInformationAfterReversion = (
  milestone: TimelineFormMilestone
): {
  label: string
  value?: string | null
} => {
  const timesByPriority = ['actualTime', 'estimatedTime', 'plannedTime'] as const

  const currentTimeNotFromAdminSource = timesByPriority.find(
    (time) => !milestone.isMilestoneDataOverridden[time]
  )

  if (isPresent(currentTimeNotFromAdminSource)) {
    return {
      label: t(`shipments.milestones.${currentTimeNotFromAdminSource}`),
      value: milestone[currentTimeNotFromAdminSource],
    }
  }

  const timeFromNextSource = timesByPriority.find((time) => isPresent(milestone.nextDates[time]))

  if (!isPresent(timeFromNextSource)) {
    return {
      label: t('shipments.milestones.plannedTime'),
      value: null,
    }
  }

  return {
    label: t(`shipments.milestones.${timeFromNextSource}`),
    value: milestone.nextDates[timeFromNextSource],
  }
}

export const milestoneHasBeenReverted = (milestone: TimelineFormMilestone) =>
  !milestone.initialState.active && milestone.activeHasBeenRolledBack
