import React, { FC, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import { ShipmentListSortBy } from 'features/shipments/types/shipment'

import Placeholder from 'components/placeholder'
import NotifMessage from 'components/notif_message'

import ErrorNotification from 'core/components/notifications/error_notification'
import useShipments from 'features/shipments/hooks/use_shipments'
import { getTestIdForShipmentItem } from 'tests/e2e/test_ids'
import { SearchSource } from 'constants/shipment_source'
import {
  StyledShipmentsList,
  StyledShipmentsListContainer,
} from 'features/shipments/components/shipments_list/style'
import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'
import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'
import ShipmentListSkeleton from 'features/shipments/components/shipments_list/skeleton'
import usePagination from 'services/api/hooks/use_pagination'
import Button from 'components/button'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import {
  fetchShipments,
  selectActiveFilters,
  selectDirection,
  selectFilterByFavorites,
  selectSortBy,
} from 'features/shipments/store/shipments_slice'
import {
  SORT_BY_ARRIVED_AT,
  SORT_BY_CREATED_AT,
  SORT_BY_DEPARTURE_AT,
  SORT_BY_ETA_DIFFERENCE,
  SORT_BY_LAST_ETA_CHANGE,
  SortByOption,
} from 'features/shipments/constants/shipment'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { EtaDifference } from 'views/iam/types'
import ShipmentListItem from 'features/shipments/components/shipment_list_item'

const PER = 20

const extractUserVisibilityFilter = (shipmentFilters: Record<string, any>) => {
  if (!shipmentFilters?.userIdVisibility?.value) {
    return { filters: shipmentFilters }
  }

  const { userIdVisibility, ...otherFilters } = shipmentFilters
  return { filters: otherFilters, user_id_visibility: userIdVisibility.value }
}

const mapSortByKey = (
  sortBy: SortByOption,
  etaDiffPreference: EtaDifference
): ShipmentListSortBy => {
  const map = {
    [SORT_BY_CREATED_AT]: SORT_BY_CREATED_AT,
    [SORT_BY_LAST_ETA_CHANGE]: SORT_BY_LAST_ETA_CHANGE,
    [SORT_BY_DEPARTURE_AT]: 'transportation_date_pol_etd',
    [SORT_BY_ARRIVED_AT]: `transportation_date_${etaDiffPreference}_eta`,
    [SORT_BY_ETA_DIFFERENCE]: `eta_diff_${etaDiffPreference}`,
  } as const

  return map[sortBy]
}

interface ShipmentsListProps {
  source: SearchSource
}

const ShipmentsList: FC<ShipmentsListProps> = ({ source }) => {
  const [shipments, status, totalCount] = useShipments(source)
  const { t } = useTranslation()
  const { view } = useContext(ShipmentViewContext)
  const { page, reset, next } = usePagination()
  const dispatch = useAppDispatch()
  const activeFilters = useSelector(selectActiveFilters)
  const sortBy = useSelector(selectSortBy)
  const direction = useSelector(selectDirection)
  const filterByFavorites = useSelector(selectFilterByFavorites)
  const user = useCurrentUser()
  const etaDiff = user.profile.settings.etaDifference

  const queryParamSortBy = mapSortByKey(sortBy, etaDiff)

  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])

  useEffect(() => {
    reset()
  }, [activeFilters, sortBy, etaDiff, direction, reset])

  useEffect(() => {
    const params = extractUserVisibilityFilter(activeFilters)

    dispatch(
      fetchShipments({
        ...params,
        sortBy: queryParamSortBy,
        direction,
        page,
        per: PER,
        source,
        favoriteUserId: filterByFavorites ? user.id : undefined,
      })
    )
  }, [
    activeFilters,
    dispatch,
    queryParamSortBy,
    direction,
    page,
    filterByFavorites,
    user.id,
    source,
  ])

  return (
    <StyledShipmentsListContainer>
      {shipments.length > 0 && (
        <StyledShipmentsList $condensed={isCondensed}>
          {shipments.map((s, index) => (
            <ShipmentListItem
              shipment={s}
              key={`shipment-${s.id}`}
              testId={getTestIdForShipmentItem(index)}
            />
          ))}
        </StyledShipmentsList>
      )}
      <Placeholder ready={status.ready} customPlaceholder={<ShipmentListSkeleton count={8} />}>
        {status.fulfilled && shipments.length === 0 && (
          <NotifMessage
            type='info'
            title={t('shipments.list.noResults.title')}
            text={t('shipments.list.noResults.text')}
          />
        )}
        {status.rejected && <ErrorNotification />}
      </Placeholder>
      {status.fulfilled && shipments.length > 0 && shipments.length < totalCount && (
        <Button text={t('actions.loadMore')} onClick={() => next()} />
      )}
    </StyledShipmentsListContainer>
  )
}

export default ShipmentsList
