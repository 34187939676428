import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useNavigate, useParams } from 'react-router-dom'

import Page from 'components/page'
import PS from 'components/page/style'
import Button from 'components/button'

import S from 'views/audit_trail/style'

import AuditTrailFilters from 'views/audit_trail/components/filters'
import { capitalize, isPresent, toCamelCase } from 'services/helpers/values'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import {
  fetchVersions,
  selectVersions,
  selectVersionsStatus,
  selectVersionsTotalCount,
} from 'views/audit_trail/slice'
import { STATUS_FULFILLED } from 'constants/api'
import DateHelper from 'services/helpers/date_helper'
import VersionState from 'views/audit_trail/components/version_state'
import { fetchShipment } from 'features/shipments/store/shipment_slice'
import useShipment from 'features/shipments/hooks/use_shipment'

import Table from 'components/table'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import { routeShipment } from 'services/helpers/routes'
import IconTooltip from 'components/icon_tooltip'
import AuditTrailFiltersContext from 'views/audit_trail/contexts/audit_trail_filters_context'
import useOnce from 'services/hooks/use_once'

type Params = {
  id: string
}

const ShipmentAuditTrail: FC = () => {
  const { id } = useParams<keyof Params>() as Params
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [shipment] = useShipment({ id })
  const versions = useSelector(selectVersions)
  const status = useSelector(selectVersionsStatus)
  const totalCount = useSelector(selectVersionsTotalCount)
  const { s } = useStaticLocales()

  const [filters, setFilters] = useState<Record<string, unknown>>({})

  const maxVersions = 100

  useOnce(() => {
    dispatch(fetchShipment({ id }))
  })

  useEffect(() => {
    dispatch(fetchVersions({ shipmentToken: id!, filters }))
  }, [dispatch, filters, id])

  return (
    <AuditTrailFiltersContext.Provider value={{ id, setFilters }}>
      <PS.Page as={Page} sidebar={<AuditTrailFilters />}>
        <Button
          text='Back to shipment'
          variant='transparent'
          icon='arrow_left'
          onClick={() => navigate(routeShipment(id))}
        />
        <PS.PageHeader>
          <S.Header>
            <PS.PageTitle>
              {isPresent(shipment) ? `Audit Trail of ${shipment.reference}` : 'Audit Trail'}
            </PS.PageTitle>
            {(versions.length > 0 || status === STATUS_FULFILLED) && (
              <PS.PageSubtitle>
                {versions.length} of {totalCount}
                {totalCount > maxVersions && (
                  <IconTooltip
                    placement='top'
                    variant='warning'
                    size='large'
                    width={25}
                    height={25}
                    content='Only 100 results can be displayed'
                  />
                )}
              </PS.PageSubtitle>
            )}
          </S.Header>
        </PS.PageHeader>
        <Table>
          <Table.Head>
            <Table.HeadCell small>Event type</Table.HeadCell>
            <Table.HeadCell small>Section</Table.HeadCell>
            <Table.HeadCell small>Segment</Table.HeadCell>
            <Table.HeadCell>Previous state</Table.HeadCell>
            <Table.HeadCell>New state</Table.HeadCell>
            <Table.HeadCell small>Actor</Table.HeadCell>
            <Table.HeadCell small>Date</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {versions.map((version, index) => (
              <Table.Row odd={index % 2 === 1} key={`version-${version.id}`}>
                <Table.Cell small>{capitalize(version.eventType)}</Table.Cell>
                <Table.Cell small>
                  {s('shipmentVersionsSections')[toCamelCase(version.section)]}
                </Table.Cell>
                <Table.Cell small>
                  {isPresent(version.segment) ? `Segment ${version.segment}` : ''}
                </Table.Cell>
                <Table.Cell>
                  <VersionState state={version.oldState} />
                </Table.Cell>
                <Table.Cell>
                  <VersionState state={version.newState} />
                </Table.Cell>
                <Table.Cell small>
                  {s('auditActor')[toCamelCase(version.source)] || version.source || 'N/A'}
                </Table.Cell>
                <Table.Cell small>
                  {new DateHelper(version.occurredAt).toLocale({ hours: true })}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </PS.Page>
    </AuditTrailFiltersContext.Provider>
  )
}

export default ShipmentAuditTrail
