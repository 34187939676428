import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import NotifMessage from 'components/notif_message'
import StyledWrap from 'core/components/notifications/error_notification/style'

const ErrorNotification: FC = () => {
  const { t } = useTranslation()
  return (
    <StyledWrap>
      <NotifMessage
        type='alert'
        title={t('errors.notification.title')}
        text={t('errors.notification.content')}
      />
    </StyledWrap>
  )
}

export default ErrorNotification
