import { useTranslation } from 'react-i18next'
import React, { useContext, useState, useEffect, FC } from 'react'
import { useDispatch } from 'react-redux'

import { DatesMilestoneType } from 'types/milestones'

import { StepStatus } from 'types/shipments'

import { Milestone } from 'features/shipments/types/legacy_shipment'

import { MilestoneNextDates } from 'features/shipments/types/segment_timelines'

import Button from 'components/button'
import Timeline from 'core/components/timeline'
import Placeholder from 'components/placeholder'
import ShipmentTimelineSkeleton from 'features/shipments/components/shipment_timeline/skeleton'
import TimelineBookingConfirmationMilestone from 'core/components/timeline/components/timeline_booking_confirmation_milestone'
import TimelineBookingConfirmationStep from 'core/components/timeline/components/timeline_booking_confirmation_step'
import { StyledUpdateTimeButton } from 'core/components/timeline/style'
import {
  StyledMilestonesEditButton,
  StyledShipmentTimelineHeader,
  StyledShipmentTimelineInfo,
} from 'features/shipments/components/shipment_timeline/style'

import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import useBookingConfirmation from 'features/shipments/components/shipment_timeline/hooks/use_booking_confirmation'
import useTimeline from 'features/shipments/hooks/use_timeline'
import {
  fetchBookingConfirmation,
  fetchTimeline,
  fetchAvailableMilestones,
  fetchSegmentTimelines,
} from 'features/shipments/store/shipment_slice'

import ErrorNotification from 'core/components/notifications/error_notification'

import { isAnyObject, compactArray, uniqArray, isPresent } from 'services/helpers/values'

import {
  STEP_STATUS_CURRENT,
  STEP_STATUS_FUTURE,
  STEP_STATUS_PAST,
  TRANSPORT_TYPE_PARCEL,
  TransportType,
} from 'constants/shipments'
import {
  SEGMENT_TIMELINE_MILESTONES_UPDATE,
  EVENT_UPDATE,
  SEGMENT_TIMELINE_UPDATE,
} from 'constants/permissions'

import ShipmentReportedInfos from 'features/shipments/components/shipment_reported_info'
import useShipment from 'features/shipments/hooks/use_shipment'
import useModal from 'components/modal/hooks/use_modal'
import DateDeclarationModal from 'features/shipments/components/date_declaration_modal'
import NewMilestoneModal from 'features/shipments/components/new_milestone_modal'
import useUserCan from 'views/iam/hooks/use_user_can'

import { StyledEditTimelineButtonWrapper } from 'features/shipments/components/shipment_view/style'
import AppContext from 'app/contexts/app_context'

import ModalTimeline from 'features/shipments/components/modal_timeline'
import TimelineStep from 'core/components/timeline/components/timeline_step'
import TimelineMilestone from 'core/components/timeline/components/timeline_milestone'

interface MilestoneObject {
  locationId: number
  locationName: string
  actualTime?: string
  isActualTimeOverridden: boolean
  nextActualTime?: string
  estimatedTime?: string
  isEstimatedTimeOverridden: boolean
  nextEstimatedTime?: string
  segmentInfoId?: number
  type?: DatesMilestoneType
  nextDates?: MilestoneNextDates
  locationTypes?: TransportType[]
}

const ShipmentTimeline: FC = React.memo(() => {
  const dispatch = useDispatch()
  const { id } = useContext(ShipmentTokenContext)
  const { isShared } = useContext(AppContext)
  const { t } = useTranslation()
  const [shipment, , isFlagged] = useShipment({ id })
  const [timeline, status] = useTimeline({ id })
  const [bookingConfirmation, isBookingReady] = useBookingConfirmation({ id })
  const userCan = useUserCan()
  const canEditMilestone = userCan(SEGMENT_TIMELINE_MILESTONES_UPDATE)
  const canAddMilestone = userCan(EVENT_UPDATE)

  // WARNING - Composite Steps will not have an id, hence the selection needs to be reworked (step_type + name ?)
  const currentStepIndex = timeline?.steps?.findIndex(
    (s) => timeline.currentStep.location.id === s.location.id
  )
  const [editMode, setEditMode] = useState(false)

  const [milestoneObject, setMilestoneObject] = useState<MilestoneObject>()

  const { opened: openedEditMilestone, setOpen: setOpenEditMilestone } = useModal(
    'timelineDateDeclarationModal'
  )
  const { opened: openedTimelineModal, setOpen: setOpenEditTimeline } = useModal('timelineModal')
  const { opened: openedNewMilestone, setOpen: setOpenNewMilestone } = useModal('newMilestoneModal')

  const openEditMilestoneModal = ({
    milestone,
    locationId,
    locationName,
  }: {
    milestone: Milestone
    locationId: number
    locationName: string
  }) => {
    setMilestoneObject({
      ...milestone,
      isActualTimeOverridden: milestone.isMilestoneDataOverridden?.actualTime ?? false,
      isEstimatedTimeOverridden: milestone.isMilestoneDataOverridden?.estimatedTime ?? false,
      type: milestone.type as DatesMilestoneType,
      locationId,
      locationName,
    })
    setOpenEditMilestone(true)
  }

  const openNewMilestoneModal = ({
    locationId,
    locationName,
    locationTypes,
  }: {
    locationId: number
    locationName: string
    locationTypes: TransportType[]
  }) => {
    setMilestoneObject({
      locationId,
      locationName,
      locationTypes,
      isActualTimeOverridden: false,
      isEstimatedTimeOverridden: false,
    })
    setOpenNewMilestone(true)
  }

  useEffect(() => {
    if (isShared) return

    if (!status.ready) {
      dispatch(fetchTimeline({ id }))
    }

    dispatch(fetchBookingConfirmation({ id }))
    if (canAddMilestone) dispatch(fetchAvailableMilestones({ id }))
  }, [id, canAddMilestone, dispatch, isShared, status])

  useEffect(() => {
    if (openedTimelineModal) {
      dispatch(fetchSegmentTimelines({ id }))
    }
  }, [id, dispatch, openedTimelineModal])

  const { mainTransportMode } = timeline.summary
  const isParcel = mainTransportMode === TRANSPORT_TYPE_PARCEL

  return (
    <>
      <StyledShipmentTimelineHeader>
        <StyledShipmentTimelineInfo>
          {status.ready &&
            (isParcel ? t('shipments.datesLocalFormatInfo') : t('shipments.datesFormatInfo'))}
        </StyledShipmentTimelineInfo>
        {canEditMilestone && (
          <StyledMilestonesEditButton onClick={() => setEditMode(!editMode)}>
            {!editMode && <span>{t('actions.edit')}</span>}
            {editMode && <span>{t('actions.close')}</span>}
          </StyledMilestonesEditButton>
        )}
      </StyledShipmentTimelineHeader>
      {isFlagged && shipment && <ShipmentReportedInfos reportedReasons={shipment.orderFlags} />}
      <Placeholder ready={status.ready} customPlaceholder={<ShipmentTimelineSkeleton />}>
        {status.rejected && <ErrorNotification />}
        <Timeline>
          {openedEditMilestone && milestoneObject && (
            <DateDeclarationModal
              addressId={milestoneObject.locationId}
              actualTime={milestoneObject.actualTime}
              isActualTimeOverridden={milestoneObject.isActualTimeOverridden}
              nextActualTime={milestoneObject.nextDates?.actualTime}
              estimatedTime={milestoneObject.estimatedTime}
              isEstimatedTimeOverridden={milestoneObject.isEstimatedTimeOverridden}
              nextEstimatedTime={milestoneObject.nextDates?.estimatedTime}
              type={milestoneObject.type!}
              segmentInfoId={milestoneObject.segmentInfoId!}
            />
          )}
          {openedTimelineModal && <ModalTimeline />}
          {openedNewMilestone && milestoneObject && (
            <NewMilestoneModal
              locationId={milestoneObject.locationId}
              locationName={milestoneObject.locationName}
              locationTypes={milestoneObject.locationTypes!}
            />
          )}
          {isBookingReady && isAnyObject(bookingConfirmation) && (
            <TimelineBookingConfirmationStep status={bookingConfirmation.timelineStatus}>
              <TimelineBookingConfirmationMilestone
                type={t('timeline.bookingConfirmation.transportOrderSent')}
                isCheck={bookingConfirmation.isTransportOrderSent}
                estimatedTime={bookingConfirmation.tet ?? undefined}
                actualTime={bookingConfirmation.tat ?? undefined}
                key='timeline-milestone-transport-order-sent'
              />
              <TimelineBookingConfirmationMilestone
                type={t('timeline.bookingConfirmation.bookingConfirmation')}
                isCheck={bookingConfirmation.isBookingConfirmed}
                estimatedTime={bookingConfirmation.bet ?? undefined}
                actualTime={bookingConfirmation.bat ?? undefined}
                key='timeline-milestone-booking-confirmation'
              />
            </TimelineBookingConfirmationStep>
          )}
          {timeline?.steps.map(({ type, facilityType, milestones, location }, i) => {
            const isCurrent = i === currentStepIndex
            let stepStatus: StepStatus = STEP_STATUS_PAST
            const inTransit =
              isCurrent &&
              timeline.currentStep.inTransit &&
              isPresent(timeline.currentStep.vehicleType)
            if (i > currentStepIndex) {
              stepStatus = STEP_STATUS_FUTURE
            }
            if (isCurrent && !inTransit) {
              stepStatus = STEP_STATUS_CURRENT
            }
            return (
              <TimelineStep
                type={facilityType}
                location={location}
                key={`timeline-step-${stepStatus}-${facilityType}-${location.name}`}
                vehicleType={timeline.currentStep.vehicleType}
                status={stepStatus}
                inTransit={inTransit}
              >
                {milestones.map((milestone) => (
                  <TimelineMilestone
                    type={milestone.type}
                    plannedTime={milestone.plannedTime}
                    estimatedTime={milestone.estimatedTime}
                    actualTime={milestone.actualTime}
                    metadata={milestone.metadata}
                    isMilestoneDataOverridden={milestone.isMilestoneDataOverridden}
                    key={`timeline-milestone-${milestone.type}-${milestone.actualTime}-${milestone.estimatedTime}-${milestone.plannedTime}`}
                    editMode={editMode}
                    openModal={() =>
                      openEditMilestoneModal({
                        milestone,
                        locationId: location.id!,
                        locationName: location.name!,
                      })
                    }
                    withDiffTime
                  />
                ))}
                {editMode && canAddMilestone && (
                  <StyledUpdateTimeButton
                    as={Button}
                    variant='outline'
                    icon='plus_outline'
                    text={t('shipments.newMilestoneModal.openButtonText')}
                    onClick={() =>
                      openNewMilestoneModal({
                        locationId: location.id!,
                        locationName: location.name!,
                        locationTypes: uniqArray(compactArray([type, timeline.steps[i - 1]?.type])),
                      })
                    }
                  />
                )}
              </TimelineStep>
            )
          })}
        </Timeline>
        {userCan(SEGMENT_TIMELINE_UPDATE) && (
          <StyledEditTimelineButtonWrapper>
            <Button
              icon='pencil'
              text={t('shipments.actions.editTimeline')}
              variant='outline'
              onClick={() => setOpenEditTimeline(true)}
            />
          </StyledEditTimelineButtonWrapper>
        )}
      </Placeholder>
    </>
  )
})

export default ShipmentTimeline
