import React, { FC } from 'react'

import { ReactComponent as Port } from 'assets/icons/anchor.svg'
import { ReactComponent as Airport } from 'assets/icons/airport.svg'
import { ReactComponent as Inland } from 'assets/icons/inland.svg'
import { ReactComponent as Vessel } from 'assets/icons/vessel.svg'
import { ReactComponent as Truck } from 'assets/icons/truck.svg'
import { ReactComponent as Plane } from 'assets/icons/plane.svg'
import { ReactComponent as Train } from 'assets/icons/train.svg'
import { ReactComponent as Container } from 'assets/icons/container.svg'
import { ReactComponent as Parcel } from 'assets/icons/parcel.svg'

import {
  LOCATION_TYPE_ADDRESS,
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_COMPANY,
  LOCATION_TYPE_INLAND,
  LOCATION_TYPE_PORT,
  LOCATION_TYPE_SYMBOLIC,
  VEHICLE_TYPE_VESSEL,
  VEHICLE_TYPE_TRUCK,
  VEHICLE_TYPE_PLANE,
  VEHICLE_TYPE_TRAIN,
  VEHICLE_TYPE_PARCEL,
} from 'constants/shipments'

import { StyledMarker, StyledMarkerLabel } from 'components/marker/style'
import { isPresent } from 'services/helpers/values'
import HoverTooltip from 'components/hover_tooltip'

export const CONTAINER_MARKER_TYPE = 'container'

const MarkerIconTypes = [
  LOCATION_TYPE_PORT,
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_INLAND,
  LOCATION_TYPE_ADDRESS,
  LOCATION_TYPE_COMPANY,
  LOCATION_TYPE_SYMBOLIC,
  VEHICLE_TYPE_VESSEL,
  VEHICLE_TYPE_TRUCK,
  VEHICLE_TYPE_PLANE,
  VEHICLE_TYPE_TRAIN,
  CONTAINER_MARKER_TYPE,
  VEHICLE_TYPE_PARCEL,
] as const

export type MarkerIconType = typeof MarkerIconTypes[number]

interface MarkerProps {
  icon: MarkerIconType
  label?: string
  clickable?: boolean
  color?: string
  tooltipText?: React.ReactChild
  testId?: string
  size?: 'small' | 'medium' | 'large'
  tooltipColor?: 'white' | 'primary' | 'warn'
}

const Marker: FC<MarkerProps> = ({
  icon,
  label,
  clickable,
  color,
  tooltipText,
  testId,
  size = 'medium',
  tooltipColor = 'primary',
}) => {
  const markIconTypeSvgMap: Record<
    MarkerIconType,
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  > = {
    [LOCATION_TYPE_PORT]: Port,
    [LOCATION_TYPE_AIRPORT]: Airport,
    [LOCATION_TYPE_INLAND]: Inland,
    [LOCATION_TYPE_ADDRESS]: Inland,
    [LOCATION_TYPE_COMPANY]: Inland,
    [LOCATION_TYPE_SYMBOLIC]: Inland,
    [VEHICLE_TYPE_VESSEL]: Vessel,
    [VEHICLE_TYPE_TRUCK]: Truck,
    [VEHICLE_TYPE_PLANE]: Plane,
    [VEHICLE_TYPE_TRAIN]: Train,
    [VEHICLE_TYPE_PARCEL]: Parcel,
    [CONTAINER_MARKER_TYPE]: Container,
  }

  const Icon = markIconTypeSvgMap[icon]

  return (
    <>
      {isPresent(tooltipText) && (
        <HoverTooltip content={tooltipText} color={tooltipColor}>
          <StyledMarker $clickable={clickable} $color={color} data-testid={testId} $size={size}>
            {label && <StyledMarkerLabel>{label}</StyledMarkerLabel>}
            <Icon />
          </StyledMarker>
        </HoverTooltip>
      )}
      {!isPresent(tooltipText) && (
        <StyledMarker $clickable={clickable} $color={color} data-testid={testId} $size={size}>
          {label && <StyledMarkerLabel>{label}</StyledMarkerLabel>}
          <Icon />
        </StyledMarker>
      )}
    </>
  )
}

export default Marker
