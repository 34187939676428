import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

const ShipmentTimelineSkeleton: FC = () => (
  <ContentLoader
    width={350}
    height={600}
    viewBox='0 0 350 600'
    backgroundColor='#f5f5f5'
    foregroundColor='#dbdbdb'
  >
    <circle cx='38' cy='40' r='18' />
    <rect x='80' y='37' rx='3' ry='3' width='180' height='7' />
    <rect x='105' y='58' rx='3' ry='3' width='140' height='6' />
    <rect x='105' y='78' rx='3' ry='3' width='120' height='6' />
    <circle cx='38' cy='140' r='18' />
    <rect x='80' y='137' rx='3' ry='3' width='180' height='7' />
    <rect x='105' y='158' rx='3' ry='3' width='140' height='6' />
    <rect x='105' y='178' rx='3' ry='3' width='120' height='6' />
    <circle cx='38' cy='240' r='18' />
    <rect x='80' y='237' rx='3' ry='3' width='180' height='7' />
    <rect x='105' y='258' rx='3' ry='3' width='140' height='6' />
    <rect x='105' y='278' rx='3' ry='3' width='120' height='6' />
    <circle cx='38' cy='340' r='18' />
    <rect x='80' y='337' rx='3' ry='3' width='180' height='7' />
    <rect x='105' y='358' rx='3' ry='3' width='140' height='6' />
    <rect x='105' y='378' rx='3' ry='3' width='120' height='6' />
    <circle cx='38' cy='440' r='18' />
    <rect x='80' y='437' rx='3' ry='3' width='180' height='7' />
    <rect x='105' y='458' rx='3' ry='3' width='140' height='6' />
    <rect x='105' y='478' rx='3' ry='3' width='120' height='6' />
  </ContentLoader>
)

export default ShipmentTimelineSkeleton
