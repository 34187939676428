import { useSelector } from 'react-redux'

import useCurrentUser from 'views/iam/hooks/use_current_user'
import {
  selectFirstLeg,
  selectLastLeg,
  selectUserReference,
} from 'features/shipments/store/shipment_slice'

export type UseSharedOrderMailInfoResponse = {
  fullName: string
  ref: string | undefined
  origin: string
  destination: string
  eta: string
}

const useSharedOrderMailInfo = ({ id }: { id: string }): UseSharedOrderMailInfoResponse => {
  const firstLeg = useSelector(selectFirstLeg({ id }))
  const lastLeg = useSelector(selectLastLeg({ id }))
  const userReference = useSelector(selectUserReference({ id }))
  const {
    profile: { firstName, lastName },
  } = useCurrentUser()

  return {
    fullName: `${firstName} ${lastName}`,
    ref: userReference,
    origin: `${firstLeg.departure.name}, ${firstLeg.departure.countryCode}`,
    destination: `${lastLeg.arrival.name}, ${lastLeg.arrival.countryCode}`,
    eta: lastLeg.apiAta || lastLeg.apiEta || 'n/a',
  }
}

export default useSharedOrderMailInfo
