import React from 'react'

import { useSelector } from 'react-redux'

import { StyledWrapper, StyledSpan, StyledImage } from 'components/organization_logo/style'
import Logo from 'components/logo'

import { getImagesUrl } from 'features/white_label/store/slice'

export interface OrganizationLogoProps {
  condensed?: boolean
  className?: string
  full?: boolean
}
const OrganizationLogo: React.FC<OrganizationLogoProps> = ({
  condensed = false,
  className,
  full = false,
}) => {
  const imagesUrl = useSelector(getImagesUrl)

  return (
    <StyledWrapper $condensed={condensed} className={className}>
      {imagesUrl?.logoUrl ? (
        <StyledImage
          src={imagesUrl.logoUrl}
          alt='company logo'
          $condensed={condensed}
          $full={full}
        />
      ) : (
        <>
          <Logo name='logo' height={35} width={40} />
          <StyledSpan>wakeo</StyledSpan>
        </>
      )}
    </StyledWrapper>
  )
}

export default OrganizationLogo
