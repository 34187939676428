import useStatus from 'services/api/hooks/use_status'
import { isPresent } from 'services/helpers/values'
import useAppSelector from 'services/hooks/use_app_selector'

import { selectShipment, selectShipmentStatus } from 'features/shipments/store/shipment_slice'

const useShipment = ({ id }: { id: string }) => {
  const shipment = useAppSelector((state) => selectShipment(state, id))
  const status = useStatus(useAppSelector((state) => selectShipmentStatus(state, id)))
  const isFlagged = isPresent(shipment) && shipment.flagged
  return [shipment, status, isFlagged] as const
}

export default useShipment
