import {
  AnyBookingMerchandise,
  BookingMerchandiseContainer,
  BookingMerchandiseContainerContent,
  BookingMerchandisePackage,
  BookingMerchandisePackageContent,
  BookingMerchandiseProduct,
  BookingMerchandiseTotal,
  BookingMerchandiseTotalContent,
  BookingMerchandiseTotalPackage,
} from 'views/booking/slices/types'
import { isPresent, isUndefined } from 'services/helpers/values'

const MERCHANDISE_TYPE_PACKAGE_TOTAL = 'package_total'
const MERCHANDISE_TYPE_PACKAGE = 'package'
const MERCHANDISE_TYPE_CONTAINER = 'container'
const MERCHANDISE_TYPE_TOTAL = 'total'

export const hasAnyControlledTemperatures = (
  product: BookingMerchandiseProduct | BookingMerchandisePackageContent
): boolean =>
  isPresent(product.controlledTemperatures?.min) || isPresent(product.controlledTemperatures?.max)

export const hasAnyHazardousGoods = (
  product: BookingMerchandiseProduct | BookingMerchandisePackageContent
): boolean =>
  isPresent(product.hazardousGoods?.hazardousClass) ||
  isPresent(product.hazardousGoods?.packingGroup) ||
  isPresent(product.hazardousGoods?.unNumber) ||
  isPresent(product.hazardousGoods?.weight?.value)

export const merchandiseContentHasAnyControlledTemperatures = (
  merchandiseContent: BookingMerchandiseTotalContent | BookingMerchandiseContainerContent
): boolean =>
  !isUndefined(
    merchandiseContent?.products?.find((product) => hasAnyControlledTemperatures(product))
  )

export const merchandiseContentHasAnyHazardousGoods = (
  merchandiseContent: BookingMerchandiseTotalContent | BookingMerchandiseContainerContent
): boolean =>
  !isUndefined(merchandiseContent?.products?.find((product) => hasAnyHazardousGoods(product)))

export const merchandiseContainerHasAnyControlledTemperatures = (
  merchandise: BookingMerchandiseContainer
): boolean => {
  if (!merchandise.content) return false
  return merchandise.content.reduce((acc, container: BookingMerchandiseContainerContent) => {
    if (!isPresent(container.products)) {
      return acc && false
    }
    return acc && merchandiseContentHasAnyControlledTemperatures(container)
  }, false)
}

export const merchandiseContainerHasAnyHazardousGoods = (
  merchandise: BookingMerchandiseContainer
): boolean => {
  if (!merchandise.content) return false
  return merchandise.content.reduce((acc, container: BookingMerchandiseContainerContent) => {
    if (!isPresent(container.products)) {
      return acc && false
    }
    return acc && merchandiseContentHasAnyHazardousGoods(container)
  }, false)
}

export const merchandisePackageHasAnyControlledTemperatures = (
  merchandiseContent: BookingMerchandisePackage | BookingMerchandiseTotalPackage
): boolean => {
  if (!merchandiseContent.content) return false
  return (
    merchandiseContent.content?.find((product) => hasAnyControlledTemperatures(product)) !==
    undefined
  )
}

export const merchandisePackageHasAnyHazardousGoods = (
  merchandiseContent: BookingMerchandisePackage | BookingMerchandiseTotalPackage
): boolean => {
  if (!merchandiseContent.content) return false
  return merchandiseContent.content?.find((product) => hasAnyHazardousGoods(product)) !== undefined
}

export const merchandisePackageLegacyHasAnyControlledTemperatures = (
  merchandiseContent: BookingMerchandiseTotal
): boolean => {
  if (!merchandiseContent.content) return false
  return merchandiseContentHasAnyControlledTemperatures(merchandiseContent.content)
}

export const merchandisePackageLegacyHasAnyHazardousGoods = (
  merchandiseContent: BookingMerchandiseTotal
): boolean => {
  if (!merchandiseContent.content) return false
  return merchandiseContentHasAnyHazardousGoods(merchandiseContent.content)
}

export const merchandiseHasAnyControlledTemperatures = (
  merchandise: AnyBookingMerchandise
): boolean => {
  let withControlledTemperatures = false
  switch (merchandise.merchandiseType) {
    case MERCHANDISE_TYPE_PACKAGE_TOTAL:
      withControlledTemperatures = merchandisePackageHasAnyControlledTemperatures(merchandise)
      break
    case MERCHANDISE_TYPE_PACKAGE:
      withControlledTemperatures = merchandisePackageHasAnyControlledTemperatures(merchandise)
      break
    case MERCHANDISE_TYPE_CONTAINER:
      withControlledTemperatures = merchandiseContainerHasAnyControlledTemperatures(merchandise)
      break
    case MERCHANDISE_TYPE_TOTAL:
      withControlledTemperatures = merchandisePackageLegacyHasAnyControlledTemperatures(merchandise)
      break
    default:
      break
  }

  return withControlledTemperatures
}

export const merchandiseHasAnyHazardousGoods = (merchandise: AnyBookingMerchandise): boolean => {
  let withHazardousGoods = false
  switch (merchandise.merchandiseType) {
    case MERCHANDISE_TYPE_PACKAGE_TOTAL:
      withHazardousGoods = merchandisePackageHasAnyHazardousGoods(merchandise)
      break
    case MERCHANDISE_TYPE_PACKAGE:
      withHazardousGoods = merchandisePackageHasAnyHazardousGoods(merchandise)
      break
    case MERCHANDISE_TYPE_CONTAINER:
      withHazardousGoods = merchandiseContainerHasAnyHazardousGoods(merchandise)
      break
    case MERCHANDISE_TYPE_TOTAL:
      withHazardousGoods = merchandisePackageLegacyHasAnyHazardousGoods(merchandise)
      break
    default:
      break
  }

  return withHazardousGoods
}
