import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Timeline from 'core/components/timeline'

import TimelineStep from 'core/components/timeline/components/timeline_step'
import TimelineMilestone from 'core/components/timeline/components/timeline_milestone'

import {
  StyledFormEditGroup,
  StyledFormEditGroupTitle,
  StyledGroupInsertWrapper,
} from 'views/booking/components/show/style'

import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'

import {
  MILESTONES_ORIGIN_LOCATION_TYPE_MAP,
  MILESTONES_DESTINATION_LOCATION_TYPE_MAP,
  MILESTONE_TYPE_ROAD_ARRIVAL,
  MILESTONE_TYPE_ROAD_DEPARTURE,
} from 'constants/milestones'

import { LOCATION_TYPE_COMPANY, FACILITY_TYPE_INLAND } from 'constants/shipments'

import iconsMapping from 'services/helpers/icons_mapping'
import { isPresent } from 'services/helpers/values'
import {
  TEST_ID_BOOKING_SHOW_ON_CARRIAGE_DATE,
  TEST_ID_BOOKING_SHOW_DESTINATION_PROPOSAL_DATE,
  TEST_ID_BOOKING_SHOW_ORIGIN_PROPOSAL_DATE,
  TEST_ID_BOOKING_SHOW_PRE_CARRIAGE_DATE,
  TEST_ID_BOOKING_SHOW_ORIGIN_STEP,
  TEST_ID_BOOKING_SHOW_TRANSSHIPMENT_STEP,
  TEST_ID_BOOKING_SHOW_DESTINATION_STEP,
  getTestIdShowTransshipmentPta,
  getTestIdShowTransshipmentPtd,
  TEST_ID_BOOKING_SHOW_POD_STEP,
  TEST_ID_BOOKING_SHOW_POL_STEP,
  TEST_ID_BOOKING_SHOW_POL_PTD,
  TEST_ID_BOOKING_SHOW_POD_PTA,
  TEST_ID_BOOKING_SHOW_POL_PTA,
  TEST_ID_BOOKING_SHOW_POD_PTD,
} from 'tests/e2e/test_ids'

const InformationRouting = () => {
  const { token } = useContext(BookingContext)
  const { t } = useTranslation()
  const [booking] = useBooking(token)

  const { legacyBooking } = booking

  const orgaWithLegacyWorkflow = legacyBooking

  const shouldDisplayProposalAtPreCarriage =
    orgaWithLegacyWorkflow &&
    booking.originAddress.id === booking.preCarriageAddress?.id &&
    isPresent(booking.proposal?.ptd) &&
    booking.proposal?.ptd !== booking.shipperPtd
  const shouldDisplayProposalAtPol =
    orgaWithLegacyWorkflow &&
    booking.originAddress.id === booking.pol?.id &&
    isPresent(booking.proposal?.ptd) &&
    booking.proposal?.ptd !== booking.shipperPtd
  const originPtdBeforeProposal = booking.shipperPtd
  const originPtdProposal = booking.proposal?.ptd

  const shouldDisplayProposalAtOnCarriage =
    orgaWithLegacyWorkflow &&
    booking.destinationAddress.id === booking.onCarriageAddress?.id &&
    isPresent(booking.proposal?.pta) &&
    booking.proposal?.pta !== booking.shipperPta
  const shouldDisplayProposalAtPod =
    orgaWithLegacyWorkflow &&
    booking.destinationAddress.id === booking.pod?.id &&
    isPresent(booking.proposal?.pta) &&
    booking.proposal?.pta !== booking.shipperPta
  const destinationPtaBeforeProposal = booking.shipperPta
  const destinationPtaProposal = booking.proposal?.pta

  return (
    <StyledFormEditGroup>
      <StyledFormEditGroupTitle>{t('bookings.routing.title')}</StyledFormEditGroupTitle>
      <StyledGroupInsertWrapper>
        <div>
          <Timeline stretched>
            {isPresent(booking.preCarriageAddress) && (
              <TimelineStep
                type={
                  booking.preCarriageAddress.type === LOCATION_TYPE_COMPANY
                    ? FACILITY_TYPE_INLAND
                    : booking.preCarriageAddress.type
                }
                location={booking.preCarriageAddress}
                key='timeline-step-origin'
                status='past'
                inTransit={false}
                testId={TEST_ID_BOOKING_SHOW_ORIGIN_STEP}
              >
                <TimelineMilestone
                  type={MILESTONES_ORIGIN_LOCATION_TYPE_MAP[booking.preCarriageAddress.type]}
                  estimatedTime={
                    shouldDisplayProposalAtPreCarriage
                      ? originPtdBeforeProposal
                      : booking.revisedPtd
                  }
                  key='timeline-milestone-origin'
                  newTime={shouldDisplayProposalAtPreCarriage ? originPtdProposal : null}
                  dateTestId={TEST_ID_BOOKING_SHOW_PRE_CARRIAGE_DATE}
                  proposalDateTestId={TEST_ID_BOOKING_SHOW_ORIGIN_PROPOSAL_DATE}
                  plannedTime={booking.initialPtd}
                />
              </TimelineStep>
            )}
            {isPresent(booking.pol) && (
              <TimelineStep
                type={iconsMapping(booking.pol.type, 'address')}
                location={booking.pol}
                status='past'
                inTransit={false}
                testId={TEST_ID_BOOKING_SHOW_POL_STEP}
              >
                {isPresent(booking.preCarriageAddress) && (
                  <TimelineMilestone
                    type={MILESTONE_TYPE_ROAD_ARRIVAL}
                    estimatedTime={booking.polPta}
                    key='timeline-milestone-origin'
                    dateTestId={TEST_ID_BOOKING_SHOW_POL_PTA}
                  />
                )}
                <TimelineMilestone
                  type={MILESTONES_ORIGIN_LOCATION_TYPE_MAP[booking.pol.type]}
                  dateTestId={TEST_ID_BOOKING_SHOW_POL_PTD}
                  estimatedTime={
                    shouldDisplayProposalAtPol ? originPtdBeforeProposal : booking.polPtd
                  }
                  proposalDateTestId={TEST_ID_BOOKING_SHOW_ORIGIN_PROPOSAL_DATE}
                  newTime={shouldDisplayProposalAtPol ? originPtdProposal : null}
                />
              </TimelineStep>
            )}
            {booking.transshipments.map((ts, idx) => (
              <TimelineStep
                type={iconsMapping(ts.address.type, 'address')}
                location={ts.address}
                status='past'
                inTransit={false}
                testId={`${TEST_ID_BOOKING_SHOW_TRANSSHIPMENT_STEP}-${idx}`}
              >
                <TimelineMilestone
                  type={MILESTONES_DESTINATION_LOCATION_TYPE_MAP[ts.address.type]}
                  estimatedTime={ts.pta}
                  dateTestId={getTestIdShowTransshipmentPta(idx)}
                />
                <TimelineMilestone
                  type={MILESTONES_ORIGIN_LOCATION_TYPE_MAP[ts.address.type]}
                  estimatedTime={ts.ptd}
                  dateTestId={getTestIdShowTransshipmentPtd(idx)}
                />
              </TimelineStep>
            ))}
            {isPresent(booking.pod) && (
              <TimelineStep
                type={iconsMapping(booking.pod.type, 'address')}
                location={booking.pod}
                status='past'
                inTransit={false}
                testId={TEST_ID_BOOKING_SHOW_POD_STEP}
              >
                <TimelineMilestone
                  type={MILESTONES_DESTINATION_LOCATION_TYPE_MAP[booking.pod.type]}
                  estimatedTime={
                    shouldDisplayProposalAtPod ? destinationPtaBeforeProposal : booking.podPta
                  }
                  dateTestId={TEST_ID_BOOKING_SHOW_POD_PTA}
                  proposalDateTestId={TEST_ID_BOOKING_SHOW_DESTINATION_PROPOSAL_DATE}
                  newTime={shouldDisplayProposalAtPod ? destinationPtaProposal : null}
                />
                {isPresent(booking.onCarriageAddress) && (
                  <TimelineMilestone
                    type={MILESTONE_TYPE_ROAD_DEPARTURE}
                    estimatedTime={booking.podPtd}
                    dateTestId={TEST_ID_BOOKING_SHOW_POD_PTD}
                  />
                )}
              </TimelineStep>
            )}
            {isPresent(booking.onCarriageAddress) && (
              <TimelineStep
                type={
                  booking.onCarriageAddress.type === LOCATION_TYPE_COMPANY
                    ? FACILITY_TYPE_INLAND
                    : booking.onCarriageAddress.type
                }
                location={booking.onCarriageAddress}
                status='past'
                inTransit={false}
                testId={TEST_ID_BOOKING_SHOW_DESTINATION_STEP}
              >
                <TimelineMilestone
                  type={MILESTONES_DESTINATION_LOCATION_TYPE_MAP[booking.onCarriageAddress.type]}
                  estimatedTime={
                    shouldDisplayProposalAtOnCarriage
                      ? destinationPtaBeforeProposal
                      : booking.revisedPta
                  }
                  newTime={shouldDisplayProposalAtOnCarriage ? destinationPtaProposal : null}
                  plannedTime={booking.initialPta}
                  dateTestId={TEST_ID_BOOKING_SHOW_ON_CARRIAGE_DATE}
                  proposalDateTestId={TEST_ID_BOOKING_SHOW_DESTINATION_PROPOSAL_DATE}
                />
              </TimelineStep>
            )}
          </Timeline>
        </div>
      </StyledGroupInsertWrapper>
    </StyledFormEditGroup>
  )
}

export default InformationRouting
