import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ImagesUrl, Theme } from 'features/white_label/types/theme'

import type { RootState } from 'services/store/store'

interface LoginConfig {
  shouldDisplaySsoLogin: boolean
  shouldDisplayPasswordLogin: boolean
  ssoLoginUrl?: string
}

interface WhiteLabelState {
  theme: Theme | null
  imagesUrl: ImagesUrl | null
  loginConfig: LoginConfig
}

const initialState: WhiteLabelState = {
  theme: null,
  imagesUrl: null,
  loginConfig: {
    shouldDisplaySsoLogin: false,
    shouldDisplayPasswordLogin: true,
  },
}

const whiteLabelSlice = createSlice({
  name: 'whiteLabel',
  initialState,
  reducers: {
    updateTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload
    },
    updateImagesUrl: (state, action: PayloadAction<ImagesUrl>) => {
      state.imagesUrl = action.payload
    },
    updateWhiteLabel: (state, action: PayloadAction<WhiteLabelState>) => {
      state.theme = action.payload.theme
      state.imagesUrl = action.payload.imagesUrl
      state.loginConfig = action.payload.loginConfig
    },
  },
})

export const getTheme = (state: RootState) => state.whiteLabel.theme

export const getImagesUrl = (state: RootState) => state.whiteLabel.imagesUrl

export const getLoginConfig = (state: RootState) => state.whiteLabel.loginConfig

export const { updateTheme, updateImagesUrl, updateWhiteLabel } = whiteLabelSlice.actions

export default whiteLabelSlice.reducer
