import styled from 'styled-components'

import NotifMessage from 'components/notif_message'

const UserActivationWarning = styled.div`
  margin-bottom: 20px;
`

const UserSsoNoCredentialsWarning = styled(NotifMessage)`
  margin: 20px 0;
`

export const StyledRadioInputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  * {
    cursor: pointer;
  }
`

const S = {
  UserActivationWarning,
  UserSsoNoCredentialsWarning,
}

export default S
