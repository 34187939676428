/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  sendNewCredentials,
  sendSsoWelcomeEmail,
  toggleActivation,
} from 'features/users/services/user_service'

import { selectToggleActivationStatus } from 'features/users/store/slice'

import Modal from 'components/modal'
import Button from 'components/button'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import S, { StyledRadioInputWrapper } from 'features/users/components/user_activation/style'
import useStatus from 'services/api/hooks/use_status'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import useModal from 'components/modal/hooks/use_modal'

import {
  TEST_ID_USER_ACTIVATION_WARNING,
  TEST_ID_USER_ACTIVATION_CONFIRM,
  TEST_ID_USER_ACTIVATION_SSO_WARNING,
  TEST_ID_USER_ACTIVATION_MODAL,
} from 'tests/e2e/test_ids'

interface ModalUserActivationProps {
  id: number
  email: string
  isSso: boolean
  active: boolean
}

type ActivationMailType = 'none' | 'credentials' | 'welcome'

const UserActivation: React.FC<ModalUserActivationProps> = ({
  id,
  email,
  isSso = false,
  active = false,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { setOpen } = useModal('userActivation')
  const activeKey = active ? 'deactivation' : 'activation'
  const [mailType, setMailType] = useState<ActivationMailType>('none')
  const toggleActivationStatus = useStatus(useShallowSelector(selectToggleActivationStatus))

  const deactivateUser = () => {
    dispatch(toggleActivation({ id }))
      .unwrap()
      .then(() => {
        setOpen(false)
        dispatch(
          addNotification({
            type: 'success',
            title: t('users.deactivation.title'),
            text: t('users.deactivation.message.success'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('users.deactivation.title'),
            text: t('users.deactivation.message.failure'),
          })
        )
      })
  }
  const activateUser = () => {
    dispatch(toggleActivation({ id }))
      .unwrap()
      .then(() => {
        if (mailType === 'credentials') {
          dispatch(sendNewCredentials({ id }))
        }

        if (mailType === 'welcome') {
          dispatch(sendSsoWelcomeEmail({ id }))
        }

        close()

        dispatch(
          addNotification({
            type: 'success',
            title: t('users.activation.title'),
            text: t('users.activation.message.success'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('users.activation.title'),
            text: t('users.activation.message.failure'),
          })
        )
      })
  }

  const close = () => {
    setMailType('none') // Modals state are not reset on opening so we need to make sure the default value is set on close
    setOpen(false)
  }

  return (
    <Modal modalName='userActivation' position='center'>
      <Modal.Header>{t(`users.${activeKey}.title`)}</Modal.Header>
      <Modal.Content>
        <S.UserActivationWarning data-testid={TEST_ID_USER_ACTIVATION_WARNING}>
          <p>{t(`users.${activeKey}.warning`)}</p>
          <p>{email} ?</p>
        </S.UserActivationWarning>
        {!active && (
          <div data-testid={TEST_ID_USER_ACTIVATION_MODAL}>
            <StyledRadioInputWrapper onClick={() => setMailType('none')}>
              <input type='radio' checked={mailType === 'none'} />
              <label>{t('users.activation.ignoreMail')}</label>
            </StyledRadioInputWrapper>
            <StyledRadioInputWrapper onClick={() => setMailType('credentials')}>
              <input type='radio' checked={mailType === 'credentials'} />
              <label>{t('users.activation.newCredentialsMail')}</label>
            </StyledRadioInputWrapper>
            {isSso && (
              <>
                <StyledRadioInputWrapper onClick={() => setMailType('welcome')}>
                  <input type='radio' checked={mailType === 'welcome'} />
                  <label>{t('users.activation.welcomeMail')}</label>
                </StyledRadioInputWrapper>
                <S.UserSsoNoCredentialsWarning
                  testId={TEST_ID_USER_ACTIVATION_SSO_WARNING}
                  type='alert'
                  text={t('users.activation.ssoNoCredentials')}
                />
              </>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button onClick={() => setOpen(false)} variant='clear' text={t('users.confirmation.no')} />
        <Button
          variant='highlight'
          text={t('users.confirmation.yes')}
          onClick={active ? deactivateUser : activateUser}
          disabled={toggleActivationStatus.pending}
          testId={TEST_ID_USER_ACTIVATION_CONFIRM}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default UserActivation
