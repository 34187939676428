import { FieldNamesMarkedBoolean } from 'react-hook-form'

import { Parties } from 'features/shipments/components/shipment_references/components/parties_block/types'

import { UpdatePartiesPayload } from 'features/shipments/store/shipment_slice'

const generatePartiesPayload = (
  parties: Parties,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<Parties>>>
) => {
  const payload: UpdatePartiesPayload = {}

  if (dirtyFields.consignee) payload.consigneeId = parties.consignee?.id ?? null
  if (dirtyFields.consignor) payload.consignorId = parties.consignor?.id ?? null
  if (dirtyFields.shipper) payload.shipperId = parties.shipper?.id ?? null
  if (dirtyFields.forwarder) payload.forwarderId = parties.forwarder?.id ?? null

  return payload
}

export default generatePartiesPayload
