import React, { FC, useContext, useEffect } from 'react'

import Co2 from 'components/co2'
import useEmissions from 'features/shipments/components/shipment_emissions/hooks/use_emissions'
import { fetchEmissions } from 'features/shipments/store/shipment_slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'

const ShipmentEmissions: FC = () => {
  const { id } = useContext(ShipmentTokenContext)
  const dispatch = useAppDispatch()
  const [emissions, status] = useEmissions({ id })

  useEffect(() => {
    if (!status.ready) {
      dispatch(fetchEmissions({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status.ready])
  return emissions.co2E ? <Co2 value={emissions.co2E} /> : <></>
}

export default ShipmentEmissions
