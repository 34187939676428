import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { routeSignIn } from 'services/helpers/routes'
import SharedShipment from 'pages/shared_shipment/shared_shipment'
import RedirectWithParams from 'app/redirect_with_params'

const SharedApp = () => (
  <Routes>
    <Route path='/shipment/:token' element={<SharedShipment />} />

    <Route path='/order/:token' element={<SharedShipment />} />
    <Route path='*' element={<RedirectWithParams replace to={routeSignIn()} />} />
  </Routes>
)

export default SharedApp
