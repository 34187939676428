import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'
import useShipment from 'features/shipments/hooks/use_shipment'
import ShipmentEmissions from 'features/shipments/components/shipment_emissions'
import ErrorNotification from 'core/components/notifications/error_notification'
import {
  StyledShipmentContentHead,
  StyledShipmentContentHeadTitle,
} from 'features/shipments/components/style'

const SharedHeader: FC = React.memo(() => {
  const { t } = useTranslation()
  const { id } = useContext(ShipmentTokenContext)
  const [shipment, status] = useShipment({ id })

  let title = `${t('states.loading')}...`
  if (status.rejected) {
    title = t('states.error')
  } else if (status.fulfilled && shipment) {
    title = shipment.reference || t('shipments.shipmentTitle')
  }

  return (
    <StyledShipmentContentHead>
      <StyledShipmentContentHeadTitle>
        {title}
        {status.rejected && <ErrorNotification />}
        <ShipmentEmissions />
      </StyledShipmentContentHeadTitle>
    </StyledShipmentContentHead>
  )
})

export default SharedHeader
