import styled from 'styled-components'

import { StyledButton } from 'components/button/style'
import StyledIcon from 'components/icon/style'

import { buttonSmall } from 'styles/utils/texts'
import { desktopLarge } from 'styles/utils/breakpoints'

export const StyledShipmentNavigation = styled.div<{ $withBackToList: boolean }>`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  ${({ $withBackToList }) => !$withBackToList && 'padding-top: 25px;'}
`

export const StyledShipmentNavigationButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${StyledButton} {
    width: 24px;
    height: 24px;
    padding: 0;
    flex-shrink: 0;

    ${StyledIcon} {
      fill: ${(props) => props.theme.mediumGray};
    }
  }

  @media ${desktopLarge.up} {
    margin-left: 25px;
  }
`

export const StyledShipmentNavigationButtonText = styled.span`
  ${buttonSmall};
  color: ${(props) => props.theme.textDark};
`

export const StyledModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const StyledEditTimelineButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  margin-bottom: 15px;
`
