import React, { useState, useMemo, FC } from 'react'
import { useTranslation } from 'react-i18next'

import useMilestoneTimes from 'services/hooks/use_milestone_times'
import useTracker from 'services/analytics/hooks/use_tracker'
import { isPresent, readonlyArrayIncludes } from 'services/helpers/values'

import { Metadata } from 'features/shipments/helpers/models'
import buildMilestoneParams from 'features/shipments/helpers/milestone'
import useUserCan from 'views/iam/hooks/use_user_can'

import { TIMELINE_MAIN_MILESTONES } from 'constants/milestones'
import { EVENT_UPDATE, SEGMENT_TIMELINE_MILESTONES_UPDATE } from 'constants/permissions'

import {
  StyledMilestone,
  StyledMilestoneHead,
  StyledMilestoneHiddenDetails,
  StyledMilestoneDetails,
  StyledMilestoneMetadata,
  StyledMilestoneDetailsHead,
  StyledTagDiffTime,
  StyledMilestoneHeadArrow,
  StyledMilestoneHeadIcon,
  StyledUpdateTimeButton,
} from 'core/components/timeline/style'
import Button from 'components/button'
import TagDiffTime from 'components/tag_diff_time'
import Icon from 'components/icon'

import TimelineMilestoneTime from 'core/components/timeline/components/timeline_milestone/components/timeline_milestone_time'

import type { MilestoneType } from 'types/milestones'
import type { Milestone, MilestoneMetadata } from 'features/shipments/types/legacy_shipment'

export interface TimelineMilestoneProps {
  milestoneName?: string
  type: MilestoneType
  plannedTime?: string
  estimatedTime?: string
  actualTime?: string
  newTime?: string
  metadata?: MilestoneMetadata
  isMilestoneDataOverridden?: Milestone['isMilestoneDataOverridden']
  openModal?: () => void
  editMode?: boolean
  dateTestId?: string
  proposalDateTestId?: string
  testId?: string
  withDiffTime?: boolean
  defaultMetadataVisible?: boolean
}
const TimelineMilestone: FC<TimelineMilestoneProps> = ({
  type,
  plannedTime,
  estimatedTime,
  actualTime,
  newTime,
  metadata,
  isMilestoneDataOverridden,
  openModal,
  editMode,
  dateTestId,
  proposalDateTestId,
  testId,
  withDiffTime,
  milestoneName,
  defaultMetadataVisible = false,
}) => {
  const userCan = useUserCan()
  const canEditDepartureArrivalMilestone = userCan(SEGMENT_TIMELINE_MILESTONES_UPDATE)
  const canEditMilestone = userCan(EVENT_UPDATE)

  const { isDepartureOrArrival } = buildMilestoneParams(type)

  const shouldDisplayUpdateTimeButton = useMemo(() => {
    if (!editMode) return false
    if (canEditDepartureArrivalMilestone && isDepartureOrArrival) return true
    return canEditMilestone
  }, [editMode, canEditDepartureArrivalMilestone, canEditMilestone, isDepartureOrArrival])

  const hasHiddenDetails =
    isPresent(plannedTime) || isPresent(metadata) || shouldDisplayUpdateTimeButton

  const [metadataVisible, setMetadataVisible] = useState(defaultMetadataVisible)

  const { track } = useTracker()
  const isCheck = isPresent(actualTime)
  const { t } = useTranslation()
  const { time, diffTime } = useMilestoneTimes({
    actual: actualTime,
    estimated: estimatedTime,
    planned: plannedTime,
  })

  const times = ['actualTime', 'estimatedTime'] as const

  const dateEditedByAdmin = times.some(
    (dateType) => isMilestoneDataOverridden && isMilestoneDataOverridden[dateType]
  )

  return (
    <StyledMilestone>
      <div>
        <StyledMilestoneHead
          $checked={isCheck}
          onClick={() => {
            if (hasHiddenDetails) {
              setMetadataVisible(!metadataVisible)
              if (!metadataVisible) {
                track('Shipment / milestone', {
                  type: milestoneName || t(`static.milestones.${type}`),
                })
              }
            }
          }}
          $hasHiddenDetails={hasHiddenDetails}
          $open={metadataVisible}
        >
          <StyledMilestoneHeadIcon
            as={Icon}
            width={11}
            height={11}
            name={isCheck ? 'checkmark' : 'arrow'}
            testId={`${testId}-head-icon`}
          />
          {milestoneName || t(`static.milestones.${type}`)}
          {hasHiddenDetails && <StyledMilestoneHeadArrow as={Icon} name='arrow_bottom_outline' />}
        </StyledMilestoneHead>
        <div>
          <StyledMilestoneDetails>
            <StyledMilestoneDetailsHead>
              <TimelineMilestoneTime
                time={actualTime || estimatedTime}
                editedByAdmin={dateEditedByAdmin}
                newTime={newTime}
                canEditMilestone={canEditMilestone}
                proposalDateTestId={proposalDateTestId}
                dateTestId={dateTestId}
              />
            </StyledMilestoneDetailsHead>

            <StyledMilestoneHiddenDetails $visible={metadataVisible}>
              {(metadata || plannedTime) &&
                new Metadata({ planned: plannedTime, ...metadata }).translation.map(
                  ({ attribute, text, icon }) => (
                    <StyledMilestoneMetadata key={`milestone-detail-${attribute}`}>
                      <Icon width={16} height={16} name={icon} />
                      {text}
                    </StyledMilestoneMetadata>
                  )
                )}
              {shouldDisplayUpdateTimeButton && (
                <StyledUpdateTimeButton
                  as={Button}
                  variant='outline'
                  icon='calendar'
                  text={t('shipments.dateDeclarationModal.openButtonText')}
                  onClick={openModal}
                />
              )}
            </StyledMilestoneHiddenDetails>
          </StyledMilestoneDetails>
        </div>
      </div>
      {withDiffTime &&
        readonlyArrayIncludes(TIMELINE_MAIN_MILESTONES, type) &&
        isPresent(diffTime) && (
          <StyledTagDiffTime
            as={TagDiffTime}
            time={time}
            timeTo={diffTime}
            testId={`${testId}-diff-time`}
          />
        )}
    </StyledMilestone>
  )
}

export default TimelineMilestone
