import styled from 'styled-components'

import {
  shipmentContentPaddingDesktop,
  shipmentContentPaddingTablet,
} from 'features/shipments/components/style'

import { desktop, desktopLarge } from 'styles/utils/breakpoints'
import { text14Regular } from 'styles/utils/texts'

export const StyledShipmentContentHeadLeft = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 12px;

  @media ${desktop.up} {
    margin-left: ${shipmentContentPaddingTablet * -1}px;
  }
  @media ${desktopLarge.up} {
    margin-left: ${shipmentContentPaddingDesktop * -1}px;
  }
`

export const StyledShipmentContentHeadLeftWrap = styled.div`
  display: flex;
  min-width: 0;
`

export const StyledShipmentFavorite = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  @media ${desktop.up} {
    width: ${shipmentContentPaddingTablet}px;
  }

  @media ${desktopLarge.up} {
    width: ${shipmentContentPaddingDesktop}px;
  }
`

export const StyledShipmentContentHeadRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
`

export const StyledShipmentContentCreationDate = styled.div`
  flex-shrink: 0;
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
`
