import React, { FC, useState } from 'react'

import { useForm, useFieldArray, FormProvider } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import useModal from 'components/modal/hooks/use_modal'

import Modal from 'components/modal'

import Segment from 'features/shipments/components/modal_timeline/components/segment'
import iconsMapping from 'services/helpers/icons_mapping'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import { editTimeline, fetchTimeline } from 'features/shipments/store/shipment_slice'
import { TimelineFormProps } from 'features/shipments/types/segment_timelines'
import Button from 'components/button'
import {
  StyleModalTimelineLegsWrapper,
  StyledModalFooterButtonsWrapper,
  StyledModalFooterContentWrapper,
} from 'features/shipments/components/modal_timeline/components/timeline_form/style'
import NotifMessage from 'components/notif_message'
import EditTimelineModalContext, {
  DecomposedStep,
} from 'features/shipments/components/modal_timeline/contexts/edit_timeline_modal_context'
import { SegmentWithCategorizedSteps } from 'features/shipments/components/modal_timeline/hooks/use_segment_timelines_mapper'
import generatePayload from 'features/shipments/components/modal_timeline/components/timeline_form/helpers/form_to_payload'

const TimelineForm: FC<{ id: string; segments: SegmentWithCategorizedSteps[] }> = ({
  id,
  segments,
}) => {
  const { setOpen } = useModal('timelineModal')
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isSavingTimeline, setIsSavingTimeline] = useState(false)

  const [currentEditedSegmentIndex, setCurrentEditedSegmentIndex] = useState(-1)
  const [currentEditedStepIndex, setCurrentEditedStepIndex] = useState(-1)
  const [currentEditedMilestoneIndex, setCurrentEditedMilestoneIndex] = useState(-1)
  const [currentDecomposedStep, setCurrentDecomposedStep] = useState<DecomposedStep>()
  const editTimelineModalContextValues = {
    currentEditedSegmentIndex,
    currentEditedStepIndex,
    currentEditedMilestoneIndex,
    setCurrentEditedSegmentIndex,
    setCurrentEditedStepIndex,
    setCurrentEditedMilestoneIndex,
    currentDecomposedStep,
    setCurrentDecomposedStep,
  }

  const form = useForm<TimelineFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      segments: segments.map((segment) => ({
        ...segment,
        stopovers: {
          touched: false,
          values: segment.stopovers,
        },
        stepsToRemove: [],
        milestones: segment.milestones.map((milestone) => ({
          ...milestone,
          initialState: {
            active: milestone.active,
          },
        })),
      })),
      alerts: [],
    },
  })

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid, dirtyFields },
    reset,
    watch,
  } = form

  const { fields: legs } = useFieldArray({
    control,
    name: 'segments',
  })

  const close = () => {
    reset()
    setOpen(false)
  }

  const onSubmit = ({ segments: timelineFormSegments }: TimelineFormProps): void => {
    const payload = generatePayload(timelineFormSegments, dirtyFields)

    setIsSavingTimeline(true)

    dispatch(editTimeline({ id, payload }))
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.notifications.timelineUpdated.title'),
            text: t('shipments.notifications.timelineUpdated.content'),
          })
        )
        close()
      })
      .then(() => dispatch(fetchTimeline({ id })))
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
      .finally(() => setIsSavingTimeline(false))
  }

  return (
    <FormProvider {...form}>
      <EditTimelineModalContext.Provider value={editTimelineModalContextValues}>
        <Modal.Content>
          <StyleModalTimelineLegsWrapper>
            {legs.map(
              (
                { transportType, departure, arrival, currentSegment, id: segmentId },
                segmentIndex
              ) => (
                <Segment
                  key={segmentId}
                  index={segmentIndex}
                  transportType={transportType}
                  type={iconsMapping(transportType, 'transportation')}
                  from={`${departure.name}, ${departure.countryCode}`}
                  to={`${arrival.name}, ${arrival.countryCode}`}
                  defaultOpened={currentSegment}
                />
              )
            )}
          </StyleModalTimelineLegsWrapper>
        </Modal.Content>
        <Modal.Footer>
          <StyledModalFooterContentWrapper>
            {watch('alerts').map((alert) => (
              <NotifMessage type='tip' text={alert.value} />
            ))}
            <StyledModalFooterButtonsWrapper>
              <Button text={t('actions.cancel')} variant='clear' onClick={() => close()} />
              <Button
                isLoading={isSavingTimeline}
                text={t('actions.save')}
                variant='highlight'
                disabled={!isDirty || !isValid}
                onClick={handleSubmit(onSubmit)}
              />
            </StyledModalFooterButtonsWrapper>
          </StyledModalFooterContentWrapper>
        </Modal.Footer>
      </EditTimelineModalContext.Provider>
    </FormProvider>
  )
}

export default TimelineForm
