import styled from 'styled-components'

import { text14Regular } from 'styles/utils/texts'
import { tablet } from 'styles/utils/breakpoints'

export const StyledModalTimelineStepWrapper = styled.div`
  min-height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0;
  gap: 2px;
`

export const StyledModalTimelineStepTypeWrapper = styled.div`
  flex-basis: 40%;
  display: flex;
  height: 100%;
  min-width: 130px;
  align-items: center;
  justify-content: flex-start;
  @media ${tablet.down} {
    margin-bottom: 15px;
  }
`

export const StyledModalTimelineStepType = styled.span`
  color: ${({ theme }) => theme.textDark};
  ${text14Regular};
`

export const StyledEditedByAdminTextInfo = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.primary};
`
