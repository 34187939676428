import React, { FC } from 'react'

import { StyledModalTimelineStepWrapper } from 'features/shipments/components/modal_timeline/style'
import {
  StyledModalTimelineStepHeaderLocation,
  StyledModalTimelineStepHeaderType,
} from 'features/shipments/components/modal_timeline/components/step_header/style'

const StepHeader: FC<{ typeHeader: string; locationHeader: string }> = ({
  typeHeader,
  locationHeader,
}) => (
  <StyledModalTimelineStepWrapper>
    <StyledModalTimelineStepHeaderType>{`${typeHeader}`}</StyledModalTimelineStepHeaderType>
    <StyledModalTimelineStepHeaderLocation>{`${locationHeader}`}</StyledModalTimelineStepHeaderLocation>
  </StyledModalTimelineStepWrapper>
)

export default StepHeader
