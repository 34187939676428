import styled from 'styled-components'

const SwitchInput = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 48px;
`
const S = {
  SwitchInput,
}

export default S
