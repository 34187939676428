import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import LoginForm from 'components/login_form'

import IAm from 'views/iam'
import { getLoginConfig } from 'features/white_label/store/slice'
import Button from 'components/button'
import {
  StyledLoginFormFormTitle,
  StyledLoginFormText,
  StyledLoginFormTextDot,
  StyledLoginFormTextLink,
  StyledLoginFormTextWrapper,
} from 'components/login_form/style'

import { StyledButtonWrapper, StyledSignInLogo } from 'views/iam/style'
import OrganizationLogo from 'components/organization_logo'
import { routePasswordRecovery } from 'services/helpers/routes'
import { TEST_ID_FORGOTTEN_PASSWORD } from 'tests/e2e/test_ids'
import { isCustomDomain } from 'features/white_label/helpers/custom_domain'

const SignIn: React.FC = () => {
  const loginConfig = useSelector(getLoginConfig)
  const { t } = useTranslation()

  return (
    <IAm>
      <StyledSignInLogo>
        <OrganizationLogo full />
      </StyledSignInLogo>
      <StyledLoginFormFormTitle>{t('iam.signIn.title')}</StyledLoginFormFormTitle>
      {loginConfig?.shouldDisplayPasswordLogin && <LoginForm />}
      {loginConfig?.shouldDisplaySsoLogin && (
        <StyledButtonWrapper>
          <Button
            text='Sign in with sso'
            to={loginConfig?.ssoLoginUrl}
            isLink
            variant='outline'
            target='_self'
          />
        </StyledButtonWrapper>
      )}
      {isCustomDomain() ? null : (
        <StyledLoginFormTextWrapper>
          <StyledLoginFormText>
            {t('iam.signIn.form.footer.needSomething')}{' '}
            <StyledLoginFormTextLink
              as={Link}
              to={{ pathname: 'https://wakeo.co/contact' }}
              target='_blank'
            >
              {t('iam.signIn.form.footer.contactUsLink')}
            </StyledLoginFormTextLink>
          </StyledLoginFormText>
          <StyledLoginFormTextDot />
          <StyledLoginFormText>
            <StyledLoginFormTextLink
              as={Link}
              to={routePasswordRecovery()}
              data-testid={TEST_ID_FORGOTTEN_PASSWORD}
            >
              {t('iam.signIn.form.footer.forgotPasswordLink')}
            </StyledLoginFormTextLink>
          </StyledLoginFormText>
        </StyledLoginFormTextWrapper>
      )}
    </IAm>
  )
}

export default SignIn
