import React, { FC } from 'react'

import Page from 'components/page'

import ShipmentView from 'features/shipments/components/shipment_view'

const ShipmentPage: FC = () => (
  <Page plain>
    <ShipmentView />
  </Page>
)

export default ShipmentPage
