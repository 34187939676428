import React from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import S from 'views/trusted_routes/components/search_bar/style'
import {
  fetchPorts,
  saveShowAlternatives,
  selectActiveQueryParams,
} from 'views/trusted_routes/slice'
import Select from 'components/select'
import Button from 'components/button'
import DateHelper from 'services/helpers/date_helper'
import InputDatepicker from 'components/input_datepicker'
import useQueryParams from 'services/hooks/use_query_params'
import useOnce from 'services/hooks/use_once'
import { isEmptyObject } from 'services/helpers/values'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import InputCheckbox from 'components/input_checkbox'
import {
  SearchBarQueryParams,
  SubscribeTrustedRoutesData,
  SubscribeTrustedRoutesDataBeforeValidation,
  subscribeTrustedRoutesSchema,
} from 'views/trusted_routes/components/search_bar/types'
import { SUBSCRIPTION_PENDING, UNSTARTED } from 'views/trusted_routes/types/status'
import useSearchTrustedRoutes from 'views/trusted_routes/hooks/use_search_trusted_routes'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTracker from 'services/analytics/hooks/use_tracker'

const FROM_DATE_MAX_RANGE = 3

const fromDateRange = (value: string | Date): boolean =>
  new DateHelper(value).isBetweenDates(
    new Date(),
    new DateHelper(new Date()).addMonths(FROM_DATE_MAX_RANGE)
  )

const extractNameFromSelectLabel = (value: string | undefined): string =>
  value?.match(/^(.*?) \([a-zA-Z0-9]+\)$/)?.[1] || ''

const SearchBar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { track } = useTracker()
  const { subscribe, status } = useSearchTrustedRoutes()

  const storedQueryParams = useSelector(selectActiveQueryParams)
  const urlQueryParams = useQueryParams<SearchBarQueryParams>()

  const user = useCurrentUser()
  const withRatesSetting = !!user.trustedRouteSettings?.features.withRates

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<SubscribeTrustedRoutesDataBeforeValidation, undefined, SubscribeTrustedRoutesData>({
    resolver: yupResolver(subscribeTrustedRoutesSchema),
    // It prioritizes urlQueryParams from the URL query parameters, and if those are not available,
    // it falls back to storedQueryParams from the Redux store.
    defaultValues: {
      departure:
        urlQueryParams.departureLocode && urlQueryParams.departure
          ? {
              value: urlQueryParams.departureLocode,
              label: `${urlQueryParams.departure} (${urlQueryParams.departureLocode})`,
            }
          : storedQueryParams.departure,
      arrival:
        urlQueryParams.arrivalLocode && urlQueryParams.arrival
          ? {
              value: urlQueryParams.arrivalLocode,
              label: `${urlQueryParams.arrival} (${urlQueryParams.arrivalLocode})`,
            }
          : storedQueryParams.arrival,
      fromDate:
        urlQueryParams.fromDate && fromDateRange(urlQueryParams.fromDate)
          ? urlQueryParams.fromDate
          : storedQueryParams.fromDate,
      withRates: false,
    },
  })

  const submit = (data: SubscribeTrustedRoutesData) => {
    navigate(
      {
        pathname: location.pathname,
        search: Object.entries({
          departureLocode: data.departure.value,
          departure: extractNameFromSelectLabel(data.departure.label),
          arrivalLocode: data.arrival.value,
          arrival: extractNameFromSelectLabel(data.arrival.label),
          fromDate: data.fromDate,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join('&'),
      },
      { replace: true }
    )
    subscribe(data)
      .then(() => reset(data, { keepValues: true }))
      .then(() => {
        track('TrustedRoutes / Subscribe', {
          pol: data.departure.value,
          pod: data.arrival.value,
          fromDate: data.fromDate,
          withRates: data.withRates,
        })
      })
  }

  // Inject query params in the url if they are present in redux store
  // This occurs when the user navigates back to trusted routes through the menu
  useOnce(() => {
    if (
      storedQueryParams.departure &&
      storedQueryParams.arrival &&
      storedQueryParams.fromDate &&
      isEmptyObject(urlQueryParams)
    ) {
      navigate(
        {
          pathname: location.pathname,
          search: Object.entries({
            departureLocode: storedQueryParams.departure.value,
            departure: extractNameFromSelectLabel(storedQueryParams.departure.label),
            arrivalLocode: storedQueryParams.arrival.value,
            arrival: extractNameFromSelectLabel(storedQueryParams.arrival.label),
            fromDate: storedQueryParams.fromDate,
          })
            .map(([k, v]) => `${k}=${v}`)
            .join('&'),
        },
        { replace: true }
      )
    }
    if (
      urlQueryParams.fromAlternatives &&
      urlQueryParams.departureLocode &&
      urlQueryParams.departure &&
      urlQueryParams.arrivalLocode &&
      urlQueryParams.arrival &&
      urlQueryParams.fromDate
    ) {
      const newSearch = new URLSearchParams(location.search)
      newSearch.delete('fromAlternatives')
      navigate(
        {
          pathname: location.pathname,
          search: newSearch.toString(),
        },
        { replace: true }
      )
      subscribe({
        departure: {
          label: urlQueryParams.departure,
          value: urlQueryParams.departureLocode,
        },
        arrival: {
          label: urlQueryParams.arrival,
          value: urlQueryParams.arrivalLocode,
        },
        fromDate: urlQueryParams.fromDate,
        withRates: false,
      }).then(() => dispatch(saveShowAlternatives(false)))
    }
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <S.Searchbar>
        <S.Container>
          <S.FormFields>
            <S.FormField>
              <Controller
                control={control}
                name='departure'
                render={({ field }) => (
                  <Select
                    onChange={({ value }) => field.onChange(value)}
                    isClearable
                    isSearchable
                    async
                    label={t('trustedRoutes.departure.label')}
                    value={field.value}
                    placeholder={t('trustedRoutes.departure.placeholder')}
                    name={field.name}
                    fetch={({ value }) => fetchPorts({ value })}
                    fetchOnFocus={() => fetchPorts({ value: null })}
                    fetchedOptionsFormat={(options) =>
                      options.map(({ name, locode }: { name: string; locode: string }) => ({
                        value: locode,
                        label: `${name} (${locode})`,
                      }))
                    }
                  />
                )}
              />
            </S.FormField>
            <S.FormField>
              <Controller
                control={control}
                name='arrival'
                render={({ field }) => (
                  <Select
                    onChange={({ value }) => field.onChange(value)}
                    isClearable
                    isSearchable
                    async
                    label={t('trustedRoutes.arrival.label')}
                    value={field.value}
                    placeholder={t('trustedRoutes.arrival.placeholder')}
                    name={field.name}
                    fetch={({ value }) => fetchPorts({ value })}
                    fetchOnFocus={() => fetchPorts({ value: null })}
                    fetchedOptionsFormat={(options) =>
                      options.map(({ name, locode }: { name: string; locode: string }) => ({
                        value: locode,
                        label: `${name} (${locode})`,
                      }))
                    }
                  />
                )}
              />
            </S.FormField>
            <S.FormField>
              <Controller
                control={control}
                name='fromDate'
                render={({ field }) => (
                  <InputDatepicker
                    withPortal
                    label={t('trustedRoutes.fromDate.label')}
                    placeholder={t('trustedRoutes.fromDate.placeholder')}
                    name={field.name}
                    onChange={({ value }) => {
                      const newValue = value?.[0] ? value?.[0].toISOString() : null
                      field.onChange(newValue)
                    }}
                    startDate={field.value}
                    filterDate={fromDateRange}
                  />
                )}
              />
            </S.FormField>
            {withRatesSetting && (
              <S.RateFormField>
                <Controller
                  name='withRates'
                  control={control}
                  render={({ field }) => (
                    <InputCheckbox
                      id={field.name}
                      name={field.name}
                      text={t('trustedRoutes.includeRates.label')}
                      onChange={({ target: { checked } }) => field.onChange(checked)}
                      checked={field.value}
                    />
                  )}
                />
              </S.RateFormField>
            )}
          </S.FormFields>
          <Button
            text={t('actions.search')}
            type='submit'
            variant='highlight'
            padded
            disabled={
              !isValid || status === SUBSCRIPTION_PENDING || (!isDirty && status !== UNSTARTED)
            }
          />
        </S.Container>
      </S.Searchbar>
    </form>
  )
}

export default SearchBar
