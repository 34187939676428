import 'utils/profilers/wdyr'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import browsersList from 'utils/supported_browsers'

import App from 'app'

import ErrorLogger from 'services/error/logger'
import store, { StoreType } from 'services/store/store'

import { TrackerProvider } from 'services/analytics/tracker_provider'

import 'views/locales/i18n'
import LegacyBrowsers from 'views/errors/legacy_browsers'
import {
  fetchWhiteLabelResources,
  applyWhiteLabelResources,
} from 'features/white_label/helpers/white_label_resources'
import { isCustomDomain } from 'features/white_label/helpers/custom_domain'

import { updateWhiteLabel } from 'features/white_label/store/slice'

const isBrowserSupported = browsersList.test(navigator.userAgent)

ErrorLogger.initialize()
const ErrorBoundary = ErrorLogger.createErrorBoundary()

const AppInitializer = () => {
  const [isThemeLoaded, setIsThemeLoaded] = useState(false)

  useEffect(() => {
    const initialize = async () => {
      if (isCustomDomain()) {
        const {
          theme,
          imagesUrl,
          pageTitle = '',
          loginConfig,
        } = await fetchWhiteLabelResources(window.location.origin)
        applyWhiteLabelResources({ imagesUrl: imagesUrl ?? undefined, pageTitle })

        store.dispatch(
          updateWhiteLabel({
            theme: theme ?? null,
            imagesUrl: imagesUrl ?? null,
            loginConfig: loginConfig ?? {
              shouldDisplayPasswordLogin: true,
              shouldDisplaySsoLogin: false,
            },
          })
        )
      }
      setIsThemeLoaded(true)
    }

    initialize()
  }, [])

  if (!isThemeLoaded || !store) {
    return null
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <TrackerProvider>
          <App />
        </TrackerProvider>
      </BrowserRouter>
    </Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>{isBrowserSupported ? <AppInitializer /> : <LegacyBrowsers />}</ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

interface CypressWithStore extends Cypress.Cypress {
  store?: StoreType
}

declare global {
  interface Window {
    Cypress?: CypressWithStore
  }
}

if (typeof window !== 'undefined' && window.Cypress) {
  window.Cypress.store = store
}
