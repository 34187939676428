import styled, { css } from 'styled-components'

import { StyledCo2 } from 'components/co2/style'
import { h1 } from 'styles/utils/texts'
import { desktop, desktopLarge } from 'styles/utils/breakpoints'
import { StyledQuickInfoIcon } from 'components/quick_info/style'
import { StyledTabNavigation } from 'components/tabs/tab_navigation/style'
import Tabs from 'components/tabs'
import StyledTabPanel from 'components/tabs/tab_panel/style'
import { PageContentMaxWidth } from 'components/page/style'
import { StyledBlockList } from 'components/block_list/style'
import { StyledBlockTransportation } from 'components/block_transportation/style'
import { gradientLightOpacityReverse } from 'styles/utils/gradients'
import { zIndexMobileSwitch } from 'styles/utils/z_index'
import { StyledButtonIcon } from 'components/button/style'

import FadeMask from 'assets/masks/fade.svg'
import { StyledShipmentsMainHeader } from 'pages/shipments/style'

export const shipmentLeftColWidth = '390px'

export const shipmentContentPaddingDesktop = 60

export const shipmentContentPaddingTablet = 30

export const StyledShipmentLeftCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 ${shipmentLeftColWidth};
  max-width: ${shipmentLeftColWidth};
  background-color: white;
  overflow: auto;
`

export const StyledShipmentMain = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const StyledShipmentContentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

export const StyledShipmentContentHeadTitle = styled.h1`
  ${h1};
  overflow: hidden;
  text-overflow: ellipsis;

  ${StyledCo2} {
    margin-left: 12px;
  }
`

export type ViewState = 'content' | 'timeline'

export const StyledShipment = styled.div<{ $viewState: ViewState }>`
  position: relative;
  display: flex;
  height: 100%;

  @media ${desktop.down} {
    ${StyledQuickInfoIcon} {
      opacity: 0;
    }

    ${(props) => {
      if (props.$viewState === 'timeline') {
        return css`
          ${StyledShipmentsMainHeader} {
            display: none;
          }
          ${StyledShipmentLeftCol} {
            flex: 0 0 100%;
          }
        `
      }

      return css`
        ${StyledShipmentLeftCol} {
          display: none;
        }
      `
    }}
  }
`

export const StyledShipmentContent = styled.div`
  position: relative;
  padding: 0 15px;
  margin-top: 10px;

  ${StyledTabNavigation} {
    margin-bottom: 35px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    bottom: 0;
    z-index: -1;
  }

  @media ${desktop.up} {
    padding: 0 ${shipmentContentPaddingTablet}px;
  }

  @media ${desktopLarge.up} {
    padding: 0 ${shipmentContentPaddingDesktop}px ${shipmentContentPaddingDesktop}px;
  }
`

export const StyledShipmentContentTabs = styled(Tabs)`
  ${StyledTabPanel} {
    @media ${desktopLarge.up} {
      max-width: ${PageContentMaxWidth};
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const StyledShipmentInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  gap: 14px;

  @media ${desktopLarge.up} {
    flex-direction: row;
    align-items: flex-start;
  }

  ${StyledBlockList} {
    margin-top: 28px;

    @media ${desktopLarge.up} {
      margin-top: 0;
      margin-left: 20px;
      flex: 0 0 calc(33.33% - 10px);
    }
  }

  ${StyledBlockTransportation} {
    @media ${desktopLarge.up} {
      flex: 0 0 calc(66.66% - 10px);
    }
  }
`

export const StyledShipmentWrap = styled.div`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  margin-top: -30px;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    ${gradientLightOpacityReverse};
    mask: url(${FadeMask});
    mask-size: cover;
  }
`

export const StyledShipmentStateSwitchButton = styled.button`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: ${zIndexMobileSwitch};

  ${StyledButtonIcon} {
    fill: ${(props) => props.theme.textLight};
  }

  @media ${desktop.up} {
    display: none;
  }
`
