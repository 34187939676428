// SharedShipment is a minimalist view of Shipment
// It therefore shares a lot of elements, such as components, styles, services

import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import Page from 'components/page'

import ShipmentTokenContext from 'features/shipments/contexts/shipment_token_context'

import SharedShipmentView from 'features/shipments/components/shared_shipment_view'

type Params = {
  token: string
}

const SharedShipment: FC = React.memo(() => {
  const { token } = useParams<keyof Params>() as Params

  return (
    <Page plain>
      <ShipmentTokenContext.Provider value={{ id: token }}>
        <SharedShipmentView />
      </ShipmentTokenContext.Provider>
    </Page>
  )
})

export default SharedShipment
