import { EMPTY_VALUE } from 'constants/milestones'
import DateHelper from 'services/helpers/date_helper'
import { isPresent } from 'services/helpers/values'
import useCategory from 'services/hooks/use_category'
import useFilter, { IFilter } from 'services/hooks/use_filter'
import useForm from 'services/hooks/use_form'

export interface DateDeclarationHook {
  estimatedTimeFilter: IFilter<'date'>
  actualTimeFilter: IFilter<'date'>
  estimatedTimeEmptiedFilter: IFilter<'checkbox'>
  actualTimeEmptiedFilter: IFilter<'checkbox'>
  estimatedTimeRolledBackFilter: IFilter<'checkbox'>
  actualTimeRolledBackFilter: IFilter<'checkbox'>
  isEdited: boolean
  queryParams: Record<string, string | null>
  editedQueryParams: Record<string, string | null>
}

const useDateDeclarationForm = (
  estimatedTime?: string,
  actualTime?: string
): DateDeclarationHook => {
  const estimatedTimeFilter = useFilter({
    name: 'estimatedTime',
    type: 'date',
    required: true,
    defaultValue: isPresent(estimatedTime) ? new DateHelper(estimatedTime).toISOString() : null,
  })
  const actualTimeFilter = useFilter({
    name: 'actualTime',
    type: 'date',
    required: true,
    defaultValue: isPresent(actualTime) ? new DateHelper(actualTime).toISOString() : null,
  })
  const estimatedTimeEmptiedFilter = useFilter({
    name: 'estimatedTimeEmptied',
    type: 'checkbox',
    defaultValue: false,
  })
  const actualTimeEmptiedFilter = useFilter({
    name: 'actualTimeEmptied',
    type: 'checkbox',
    defaultValue: false,
  })
  const estimatedTimeRolledBackFilter = useFilter({
    name: 'estimatedTimeRolledBack',
    type: 'checkbox',
    defaultValue: false,
  })
  const actualTimeRolledBackFilter = useFilter({
    name: 'actualTimeRolledBack',
    type: 'checkbox',
    defaultValue: false,
  })

  const milestoneDatesCategory = useCategory({
    name: 'milestoneDates',
    filters: [estimatedTimeFilter, actualTimeFilter],
    toQueryParams: (filters) => {
      const formatPayloadMapping: any = {
        estimatedTime: () => {
          let { value } = estimatedTimeFilter

          if (estimatedTimeEmptiedFilter.value) {
            value = EMPTY_VALUE
          } else if (estimatedTimeRolledBackFilter.value) {
            value = null
          }

          return { estimatedTime: value }
        },
        actualTime: () => {
          let { value } = actualTimeFilter

          if (actualTimeEmptiedFilter.value) {
            value = EMPTY_VALUE
          } else if (actualTimeRolledBackFilter.value) {
            value = null
          }

          return { actualTime: value }
        },
      }

      return filters.reduce(
        (acc, filter) => ({ ...acc, ...formatPayloadMapping[filter.name]() }),
        {}
      )
    },
  })

  const categories = [milestoneDatesCategory]

  const { queryParams, editedQueryParams, isEdited } = useForm({ categories })

  return {
    estimatedTimeFilter,
    actualTimeFilter,
    estimatedTimeEmptiedFilter,
    actualTimeEmptiedFilter,
    estimatedTimeRolledBackFilter,
    actualTimeRolledBackFilter,
    editedQueryParams,
    queryParams,
    isEdited,
  }
}

export default useDateDeclarationForm
