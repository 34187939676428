import React, { FC, useContext, useMemo } from 'react'

import { SHIPMENT_VIEW_LARGE } from 'constants/shipments'

import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'

import { StyledTimeline } from 'features/shipments/components/shipment_timeline/style'

export { default as ShipmentTimelineStep } from 'components/shipment_timeline/shipment_timeline_step'

const ShipmentTimeline: FC = ({ children }) => {
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view !== SHIPMENT_VIEW_LARGE, [view])

  return <StyledTimeline $condensed={isCondensed}>{children}</StyledTimeline>
}

export default ShipmentTimeline
