import { ADMIN_SOURCES } from 'constants/shipments'
import { StepType } from 'constants/steps'
import { milestoneHasBeenEditedByAdmin } from 'features/shipments/components/modal_timeline/helpers/milestones'
import { TimelineFormMilestone, TimelineFormStep } from 'features/shipments/types/segment_timelines'

export const getNextStopoverIndex = ({
  steps,
  stopoverType,
  polType,
}: {
  steps: TimelineFormStep[]
  stopoverType: StepType
  polType: StepType
}) => {
  let nextStopoverIndex = -1

  const lastStopOverIndex = steps
    .reverse()
    .findIndex((step: TimelineFormStep) => step.type === stopoverType)

  if (lastStopOverIndex === -1) {
    nextStopoverIndex = steps.findIndex((step: TimelineFormStep) => step.type === polType)
  } else {
    nextStopoverIndex = steps.length - lastStopOverIndex
  }

  return nextStopoverIndex
}

export const stepHasBeenEditedByAdmin = (
  step: TimelineFormStep,
  milestones: TimelineFormMilestone[]
) =>
  (step.sourceMetadata?.location && ADMIN_SOURCES.includes(step.sourceMetadata.location)) ||
  milestones.some((milestone) => milestoneHasBeenEditedByAdmin(milestone))
