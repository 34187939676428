import React, { useCallback } from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { Marker as MapboxMarker } from 'mapbox-gl'

import store from 'services/store/store'

import Marker from 'components/marker'

import ThemeProvider from 'styles/theme_provider'

// HACK: in order to render a React component as a mapbox element,
// we create an empty div and render the component inside.
// Because of this, we need to add back a store and a theme provider.
const makeMarker = (element) => {
  const wrap = document.createElement('div')
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider>{element}</ThemeProvider>
    </Provider>,
    wrap
  )

  return wrap
}
const makeMapboxMarker = (marker, coordinate) =>
  new MapboxMarker(makeMarker(marker)).setLngLat(coordinate)

const useMarkers = ({ map }) => {
  const buildMarker = useCallback(
    ({ type, label, coordinate, color }) =>
      makeMapboxMarker(<Marker clickable icon={type} label={label} color={color} />, coordinate),
    []
  )
  const addMarker = useCallback(
    ({ type, label, coordinate, color, tooltipText, tooltipColor, testId, size }) => {
      const marker = makeMapboxMarker(
        <Marker
          clickable
          icon={type}
          label={label}
          color={color}
          tooltipText={tooltipText}
          tooltipColor={tooltipColor}
          testId={testId}
          size={size}
        />,
        coordinate
      )
      marker.addTo(map)
    },
    [map]
  )

  return {
    buildMarker,
    addMarker,
    makeMapboxMarker,
  }
}

export default useMarkers
