import React from 'react'

import AvatarList from 'components/avatar_list'
import Avatar from 'components/avatar'
import { ShipmentUser } from 'features/shipments/types/legacy_shipment'

const ShipmentUsersList = ({ users }: { users: ShipmentUser[] }) => (
  <AvatarList>
    {users.map(({ id: uid, firstName, lastName, color }) => (
      <Avatar firstName={firstName} lastName={lastName} color={color} key={`user-${uid}`} />
    ))}
  </AvatarList>
)

export default ShipmentUsersList
